<div class="row" >
    <ng-container *ngFor="let role of dataFieldsInfo?.roles; let i = index">
        <div class="col-md-6" style="position: relative;" [ngClass]="{'mt-1': i>= 2}">
            <p-card>
                <button type="button"
                (click)="deleteRole(role)"
                    class="btn btn-danger-ct rounded-circle btn-close"><span
                        class="fa fa-remove" title="Xóa role" style="cursor: pointer;font-size: 13px;" ></span></button>
                <p-header>
                    <div class="row">
                        <div class="col-md-6 d-flex justify-content-start align-items-center pl-2">
                            <strong >{{role?.roleCd}}</strong>
                        </div>
                        <div class="col-md-6 text-right">
                            <p-splitButton [style]="{height: '2.5rem'}" label="Thêm" icon="fa fa-plus" (onClick)="addMenuToRole(role)" (onDropdownClick)="onDropdownClick(role)" [model]="items"></p-splitButton>
                            <!-- <p-button title="thêm menu vào role" icon="fa fa-plus" styleClass="mr-1" (onClick)="addMenuToRole(role)"></p-button>
                            <p-button title="Sửa" icon="fa fa-edit" (onClick)="editRole(role)"></p-button> -->
                        </div>
                    </div>
                </p-header>
                <ag-grid-angular
                #agGrid
                style="width: 100%;height:440px;"
                id="myGrid"
                floatingFilter
                class="ag-theme-balham"
                [columnDefs]="columnDefs1"
                enableCellTextSelection=true
                [rowData]="role?.rolemenu"
                (gridReady)="onGridReady1($event)"
                [getRowHeight]="getRowHeight1"
                [frameworkComponents]="frameworkComponents"
              ></ag-grid-angular>
            </p-card>
        </div>
    </ng-container>
</div>

<p-dialog [(visible)]="display" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header >
     Thêm menu vào Role
  </p-header>
  <p-button label="Chọn all" (click)="saveAll()"></p-button>
  <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid"
                                        class="ag-theme-balham" [rowData]="dataFieldsInfo?.menus" [columnDefs]="columnDefs"
                                        [masterDetail]="true" floatingFilter
                                        enableCellTextSelection=true
                                        [detailCellRendererParams]="detailCellRendererParams"
                                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                                        [animateRows]="true" [groupMultiAutoColumn]="true"
                                        [detailRowHeight]="detailRowHeight"
                                        [getRowHeight]="getRowHeight">
                                    </ag-grid-angular>
    <p-footer >
        <div class="col-md-12 d-flex justify-content-end mt-1">
         <p-button label="Thêm vào" (click)="saveMenu()"></p-button>
         <p-button label="Đóng"  styleClass="ui-button-secondary" (click)="display = !display"></p-button>
        </div>
     </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="displayRole" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        Sửa quyền role
    </p-header>
    <form #createMenu="ngForm" *ngIf="modelRole" class="form-horizontal ng-pristine ng-valid ng-scope">
        <div class="col-md-12">
            <label>Mã nhóm quyền <span class="text-danger">(*)</span></label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelRole.roleCd" name="roleCd" #roleCd="ngModel" required >
        </div>
        <div class="col-md-12">
            <label>Tên nhóm quyền<span class="text-danger">(*)</span></label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelRole.roleName" name="roleName" #roleName="ngModel" required >
            </div>
        <p-footer>
            <div class="col-md-12 d-flex justify-content-end mt-1">
                <p-button label="Lưu lại" styleClass="mr-1" (click)="saveRole()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayRole = !displayRole">
                </p-button>
            </div>
            </p-footer>
      </form>
</p-dialog>
  <p-dialog [(visible)]="displayAction" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        Thêm action vào menu
    </p-header>
    <p-pickList [source]="sourceActions" [target]="targetActions" [showSourceControls]="false" [showTargetControls]="false" sourceHeader="Danh sách menu được chọn" targetHeader="Danh sách menu" [responsive]="true" filterBy="actionCd"
    dragdrop="true" sourceFilterPlaceholder="Search by brand" targetFilterPlaceholder="Search by brand" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}">
<ng-template let-car pTemplate="item">
    <div class="ui-helper-clearfix">
        <p style="display:inline-block;margin:2px 0 2px 2px">{{car.actionCd}}</p>
        <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
        <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{car.actionName}}</div>
    </div>
</ng-template>
</p-pickList>
<p-footer>
    <div class="col-md-12 d-flex justify-content-end mt-1">
        <p-button label="Lưu lại" styleClass="mr-1" (click)="saveActionToMenu()"></p-button>
      
    </div>
    </p-footer>
</p-dialog>
