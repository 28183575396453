
    <form  #createRoleGroup="ngForm">
      <div class="form-group">
        <label>Tên nhân viên<span class="text-danger">(*)</span></label>
        <span class="custom-control custom-checkbox float-right">
          <input type="checkbox" id="customCheck" (change)="onCheck()" class="custom-control-input">
          <label for="customCheck" class="custom-control-label"></label>
        </span>
        <label for="customCheck" class="float-right mr-1">Chọn tất cả</label>
        <input type="text" class="form-control mr-sm-2" [(ngModel)]="modalRoleUser.fullName" #fullName="ngModel" name="fullName" required>
      </div>
      <div class="form-group" >
        <div id="accordionExample" class="accordion">
          <div class="card" *ngFor="let web of listGroupRole; let i=index">
            <div id="headingOne" class="card-header">
              <h5 class="mb-0 d-flex align-items-center justify-content-between"><button type="button"
                (click)="toggleMenu($event, i)" class="btn">
                  {{web?.webName}} 
                  <!-- (<span class="">0</span>/4) -->
                </button>
                <div class="custom-control custom-checkbox"><input type="checkbox" name="suppliers_manager_{{i}}" [checked]="web.checked_all" (change)="onCheckWebRole(web, i)" id="suppliers_manager_{{i}}"
                    class="custom-control-input"> <label for="suppliers_manager_{{i}}"
                    class="custom-control-label"></label></div>
              </h5>
            </div>
            <div  class="collapse_{{i + 1}}" style="display: none;">
                <div class="card-body">
                  <div class="row">
                    <div class="col-3" *ngFor="let item of web?.clientWebRoles; let j=index">
                      <div class="custom-control custom-checkbox"><input type="checkbox" [checked]="item.isCheck" (change)="onCheckIsGroupRole(i,j)" name="{{item?.roleName}}_{{j + 1}}" id="{{item?.roleName}}_{{j + 1}}"
                          class="custom-control-input"> <label for="{{item?.roleName}}_{{j + 1}}"
                          class="custom-control-label">{{item?.roleName}}</label></div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="saveRoleUser()" [disabled]="!createRoleGroup.form.valid">Cập nhật</button>
        <button type="button" class="btn btn-outline-dark" (click)="huy()">Close</button>
      </div>
    </form>