<link href="//cdn.syncfusion.com/ej2/ej2-popups/styles/material.css" rel="stylesheet" />
<div class="content-header row" style="margin: -10px;">
    <div class="content-header-left col-md-6 col-12">
        <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/home">Trang chủ</a>
                </li>
                <li class="breadcrumb-item">
                    <a>Cài đặt quản trị Website</a>
                </li>
                <li class="breadcrumb-item active" (click)="backpage()">
                    Quay lại danh sách
                </li>
            </ol>
        </div>
    </div>
</div>



<div class="content-body">
    <section id="configuration">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content collapse show"  >
              <div class="card-body card-dashboard">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                    <form action="" #createCustomer="ngForm" *ngIf="dataFieldsInfo && dataFieldsInfo?.group_fields">
                        <div class="in" *ngIf="dataFieldsInfo && dataFieldsInfo?.group_fields"  [ngClass]="{'custom_form': dataFieldsInfo.formUser}">
                            <ng-container *ngFor="let fielsParent of dataFieldsInfo?.group_fields ; let idx = index">
                                <div class="">
                                    <div class="col-md-12" style="border-bottom: 1px solid #98a4b8">
                                        <label style="font-weight: bold;">{{fielsParent.group_name}}<span
                                                class="noti-number noti-number-on ml5">Edit</span></label>
                                    </div>
                                    <div class="row">
                                        <ng-container *ngFor="let element of fielsParent?.fields;let i=index">

                                            <div [ngClass]="{'custom_image': dataFieldsInfo.formUser}" [hidden]="!element.isVisiable" *ngIf="element.columnType === 'image'"  class="{{element.columnClass}} custom_image">
                                                <div class="form-group">
                                                    <label style="font-weight: bold;">Ảnh đại diện</label>
                                                    <div class="d-flex">
                                                        <div class="upload-area d-inline-flex align-items-center" *ngIf="!element[element.field_name]">
                                                            <div class="text-center p-3 ml-auto mr-auto" style="color: #007ad9;">
                                                                <span class="fa fa-plus"></span><br>
                                                                Thêm mới
                                                            </div>
                                                            <input (change)="onUploadOutput($event, fielsParent)" accept="image/jpeg,image/png,image/jpg,image/gif"
                                                                type="file" name="myfile1" class="input-file">
                                                        </div>
                                                        <div *ngIf="element[element.field_name]" class="upload-area uploaded d-inline-flex align-items-center" style="justify-content:center"
                                                            >
                                                                <ng-container>
                                                                    <button type="button" (click)="deleteAvatar(fielsParent, element.field_name)"
                                                                        class="btn btn-danger-ct rounded-circle btn-close"><span
                                                                            class="fa fa-remove"></span></button>
                                                                    <img id="img" src={{element[element.field_name]}} alt="" class="preview">
                                                                </ng-container>
                                                        </div>
                                                    </div>
                                                    <ng-container *ngIf="anotherType == true">
                                                        <br>
                                                        <span class="btn bg-gray-light text-primary">{{fileName}} &nbsp;<span (click)="closeFile()"
                                                                class="oi oi-x text-secondary"></span></span>
                                                    </ng-container>
            
                                                </div>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'input' && element.data_type === 'nvarchar'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                                                        style="color:red" *ngIf="element.isRequire">*</span></label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable">
                                                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                                                    class="alert-validation alert-danger">
                                                    <div [hidden]="element[element.field_name]">
                                                        Chọn dự án bắt buộc nhập!
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'input' && element.data_type === 'int'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                                                        style="color:red" *ngIf="element.isRequire">*</span></label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable">
                                                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                                                    class="alert-validation alert-danger">
                                                    <div [hidden]="element[element.field_name]">
                                                        Chọn dự án bắt buộc nhập!
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!element.isVisiable" *ngIf="element.data_type === 'datetime'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                                                        *ngIf="element.isRequire">*</span></label>
                                                <input [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} class="form-control"
                                                    #datepickerYMD="bsDatepicker" bsDatepicker
                                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                                    [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable">
                                                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                                                    class="alert-validation alert-danger">
                                                    <div [hidden]="element[element.field_name]">
                                                        Chọn dự án bắt buộc nhập!
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'checkbox'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="text-nowrap"
                                                    class="control-label" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                                                        *ngIf="element.isRequire">*</span></label>
                                                <span class="custom-control custom-checkbox float-left"
                                                    style="margin-top: 7%;">
                                                    <input type="checkbox" [(ngModel)]="element[element.field_name]"
                                                        id={{element.field_name}} name={{element.field_name}}
                                                        class="custom-control-input" [disabled]="element.isDisable"
                                                        [required]="element.isRequire && element.isVisiable">
                                                    <label for={{element.field_name}}
                                                        class="custom-control-label"></label>
                                                </span>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                                                        style="color:red" *ngIf="element.isRequire">*</span></label>
                                                <p-button label="Click" icon="ft-scissors" title="Chi tiết địa chỉ"
                                                    iconPos="left"
                                                    (click)="sliptAddress(element[element.field_name],element.field_name)">
                                                </p-button><textarea type="text" class="form-control"
                                                    (change)="saveAddress($event.target.value)"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable"></textarea>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                                                        style="color:red" *ngIf="element.isRequire">*</span></label>
                                                <p-button label="Click" icon="ft-repeat"
                                                    title="Coppy địa chỉ thường chú" iconPos="left"
                                                    (click)="coppyAddress1(element[element.field_name],element.field_name)">
                                                </p-button><textarea type="text" class="form-control"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable"></textarea>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'textarea' && element.field_name === 'note'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                                                        *ngIf="element.isRequire">*</span></label>
                                                <textarea type="text" class="form-control"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable"></textarea>
                                            </div>
                                            <div [hidden]="!element.isVisiable"
                                                *ngIf="element.columnType === 'textarea' && element.field_name === 'comment'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                                                        *ngIf="element.isRequire">*</span></label>
                                                <textarea type="text" class="form-control"
                                                    [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable"></textarea>
                                            </div>

                                            <div [hidden]="!element.isVisiable" *ngIf="element.columnType === 'select'"
                                                class="{{element.columnClass}}" style="margin-top: 10px;">
                                                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                                                        style="color:red" *ngIf="element.isRequire">*</span></label>
                                                <select class="form-control" [(ngModel)]="element[element.field_name]"
                                                    name={{element.field_name}} [disabled]="element.isDisable"
                                                    [required]="element.isRequire && element.isVisiable">
                                                    <option *ngFor="let option of element.options ; let i=index"
                                                        value={{option.objValue}}> {{option.objName}}</option>
                                                </select>
                                                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                                                    class="alert-validation alert-danger">
                                                    <div [hidden]="element[element.field_name]">
                                                        Chọn dự án bắt buộc nhập!
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8 ;clear: right;padding-top: 19px;">
                            <label style="font-weight: bold;">Phân quyền<span
                                    class="noti-number noti-number-on ml5">Edit</span></label>
                        </div>
                        <div class="row">
                            <p-tabView (onChange)="handleChange($event)" [activeIndex]="activeIndex">
                                <p-tabPanel header="Thêm tab">
                                    <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid"
                                        class="ag-theme-balham" [rowData]="dataFieldsInfo?.tabs" [columnDefs]="columnDefs"
                                        [masterDetail]="true" floatingFilter enableCellTextSelection=true
                                        [detailCellRendererParams]="detailCellRendererParams"
                                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                                        [animateRows]="true" [groupMultiAutoColumn]="true" enableCellTextSelection=true
                                        [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                                        [getRowHeight]="getRowHeight">
                                    </ag-grid-angular>
                                    <p-button label="Thêm mới" styleClass="mr-1 mt-1" (click)="addTab()"></p-button>
                                </p-tabPanel>
                                <!-- <p-tabPanel header="Phân quyền nhóm chát">
                                  <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                                  [rowData]="items" [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                                  (firstDataRendered)="onFirstDataRendered($event)"
                                  [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                                  [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                                  [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                                  [getRowHeight]="getRowHeight">
                                </ag-grid-angular>
                                </p-tabPanel> -->
                                <p-tabPanel header="Thêm menu">
                                    <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid"
                                        class="ag-theme-balham" [rowData]="dataFieldsInfo?.menus" [columnDefs]="columnDefs"
                                        [masterDetail]="true" floatingFilter enableCellTextSelection=true
                                        [detailCellRendererParams]="detailCellRendererParams"
                                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                                        [animateRows]="true" [groupMultiAutoColumn]="true"  (firstDataRendered)="onFirstDataRendered($event)"
                                        [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                                        [getRowHeight]="getRowHeight">
                                    </ag-grid-angular>
                                    <p-button label="Thêm mới" styleClass="mr-1 mt-1" (click)="addMenu()"></p-button>
                                </p-tabPanel>
                                <p-tabPanel header="Thêm Action">
                                    <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid"
                                        class="ag-theme-balham" [rowData]="dataFieldsInfo?.clientaction" [columnDefs]="columnDefs"
                                        [masterDetail]="true" floatingFilter enableCellTextSelection=true
                                        [detailCellRendererParams]="detailCellRendererParams"
                                        (firstDataRendered)="onFirstDataRendered($event)"
                                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                                        [animateRows]="true" [groupMultiAutoColumn]="true"
                                        [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                                        [getRowHeight]="getRowHeight">
                                    </ag-grid-angular>
                                    <p-button label="Thêm mới" styleClass="mr-1 mt-1" (click)="addAction()"></p-button>
                                </p-tabPanel>
                                <p-tabPanel header="Thêm role">
                                    <app-listrolepage [dataFieldsInfo]="dataFieldsInfo"></app-listrolepage>
                                    <!-- <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid"
                                        class="ag-theme-balham" [rowData]="dataFieldsInfo?.roles" [columnDefs]="columnDefs"
                                        [masterDetail]="true" floatingFilter
                                        [detailCellRendererParams]="detailCellRendererParams"
                                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                                        [animateRows]="true" [groupMultiAutoColumn]="true"
                                        [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                                        [getRowHeight]="getRowHeight">
                                    </ag-grid-angular> -->
                                    <p-button label="Thêm mới" styleClass="mr-1 mt-1" (click)="addRole()"></p-button>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="col-md-12 d-flex justify-content-end">
                                <!-- <p-button label="Thê" styleClass="mr-1"  [disabled]="!createCustomer.form.valid" (click)="saveCustomer()"></p-button> -->
                                <p-button label="Lưu lại" styleClass="mr-1" [disabled]="!createCustomer.form.valid"
                                    (click)="saveWebsite()"></p-button>
                                <p-button label="Quay lại" styleClass="ui-button-secondary mr-1" (click)="backpage()">
                                </p-button>
                            </div>
                        </div>
                    </form>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

<p-dialog [(visible)]="displayTab" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        {{isEditTab ? 'Sửa tab' :'Thêm tab vào web'}}
    </p-header>
    <form action="" #tabForm="ngForm">
        <div class="col-md-12">
            <label for="">Mã tab</label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelTab.tabCd" name="tabCd" #tabCd="ngModel"
                required>
        </div>
        <div class="col-md-12">
            <label>Tên tab<span class="text-danger">(*)</span></label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelTab.tabName" name="tabName"
                #tabName="ngModel" required>
        </div>
        <p-footer>
            <div class="col-md-12 d-flex justify-content-end mt-1">
                <p-button label="Lưu lại" styleClass="mr-1" (click)="saveTab()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayTab = !displayTab">
                </p-button>
            </div>
        </p-footer>
    </form>
</p-dialog>

<p-dialog [(visible)]="displayAction" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        {{isEditAction ? 'Sửa action' :'Thêm mới action'}}
    </p-header>
    <form action="" #tabForm="ngForm">
        <ng-container *ngFor="let actionModel of actions; let i=index">
            <div class="row" style="margin:10px 0px;" id="actionModel.actionCd_{{i}}">
                <div class="col-md-5">
                    <label class="control-label">Mã action</label>
                    <input type="text" class="form-control" name="actionCd_{{actionModel.actionCd}}_{{i}}" [(ngModel)]="actionModel.actionCd"  required placeholder="Viết thường không dấu (delete)">
                </div>
                <div class="col-md-5">
                    <label class="control-label">Tên action</label>
                    <input type="text" class="form-control" name="actionName{{actionModel.actionName}}_{{i}}" [(ngModel)]="actionModel.actionName" required placeholder="Xóa">
                </div>
                <div class="col-md-2">
                    <label class="control-label" style="visibility: hidden;">Tên action</label> <br>
                    <p-button label="Delete" (onClick)="deleteAction(i)" icon="fa fa-trash" styleClass="ui-button-danger"></p-button>
                </div>
              </div>
        </ng-container>
        <div class="row d-flex justify-content-center">
            <p-button label="Thêm" (click)="addActions()"></p-button>
        </div>

        <p-footer>
            <div class="col-md-12 d-flex justify-content-end mt-1">
                <p-button label="Lưu lại" styleClass="mr-1" (click)="onSaveAction()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayAction = !displayAction">
                </p-button>
            </div>
        </p-footer>
    </form>
</p-dialog>
<p-dialog [(visible)]="displayMenu" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        {{isEditMenu ? 'Sửa menu' : 'Thêm menu vào website'}}
    </p-header>
    <form #createMenu="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <!-- <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Ứng dụng
              <span style="color:red">*</span>
            </label>
            <div class="col-sm-9">
              <select disabled class="form-control" name="webId"
                [(ngModel)]="modelMenu.webId" id="webId" ddlvalidator #webId="ngModel">
                <option *ngFor="let item of listPages" value={{item.webId}}>{{item.webName}}({{item.description}})</option>
              </select>
            </div>
          </div> -->
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Tab menu</label>
            <div class="col-sm-9">
              <select  class="form-control" name="tabCd"
                [(ngModel)]="modelMenu.tabCd" id="tabCd"  #tabCd="ngModel">
                <option *ngFor="let item of this.dataFieldsInfo?.tabs" value={{item.tabCd}}>{{item.tabName}}</option>
              </select>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Mã menu <span style="color:red">*</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" required name="menuCd" readonly [(ngModel)]="modelMenu.menuCd"
                id="menuCd" #menuCd="ngModel"  placeholder="menuCd">
                <div *ngIf="menuCd.errors && (menuCd.dirty || menuCd.touched)"
                      class="alert-validation alert-danger">
                      <div [hidden]="!menuCd.errors.required">
                        Trường bắt buộc nhập!
                      </div>
                    </div>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Title
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="title" (change)="randomMenuCd($event)" required [(ngModel)]="modelMenu.title"
                id="title" #title="ngModel"  placeholder="Title">
                <div *ngIf="title.errors && (title.dirty || title.touched)"
                      class="alert-validation alert-danger">
                      <div [hidden]="!title.errors.required">
                        Trường bắt buộc nhập!
                      </div>
                    </div>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">path
              <span style="color:red">*</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="path" [(ngModel)]="modelMenu.path"
                id="path" #path="ngModel"  placeholder="path">
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">icon
              <span style="color:red">*</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="icon" [(ngModel)]="modelMenu.icon"
                id="icon" #icon="ngModel"  placeholder="icon">
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Class
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="class" [(ngModel)]="modelMenu.class"
                id="class" #class="ngModel" placeholder="Class">
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Badge
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="badge" [(ngModel)]="modelMenu.badge"
                id="badge" #badge="ngModel" placeholder="Badge">
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Badge class
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="badgeClass" [(ngModel)]="modelMenu.badgeClass"
                id="badgeClass" #badgeClass="ngModel" placeholder="Badge class">
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">isExternalLink
            </label>
            <div class="col-sm-9">
               <div class="custom-control custom-checkbox"><input type="checkbox" name="isExternalLink" [(ngModel)]="modelMenu.isExternalLink" #isExternalLink="ngModel" id="isExternalLink"
                   class="custom-control-input"> <label for="isExternalLink"
                   class="custom-control-label"></label></div>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">isNavHeader
            </label>
            <div class="col-sm-9">
               <div class="custom-control custom-checkbox"><input type="checkbox" name="isNavHeader" [(ngModel)]="modelMenu.isNavHeader" #isNavHeader="ngModel" id="isNavHeader"
                   class="custom-control-input"> <label for="isNavHeader"
                   class="custom-control-label"></label></div>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">parentId
            </label>
            <div class="col-sm-9">
               <select class="form-control" name="parentId"
               [(ngModel)]="modelMenu.parentId" id="parentId" #parentId="ngModel">
               <option [ngValue]="null">---Chọn menu cha---</option>
               <ng-container *ngFor="let item of dataFieldsInfo?.menus; let i=index">
                <option *ngIf="item?.menuId" value={{item?.menuId}}>{{item?.title}}</option>
               </ng-container>
             </select>
            </div>
          </div>
          <div class="form-row" style="margin:10px 0px;">
            <label class="col-sm-3 control-label">Vị trí
            </label>
            <div class="col-sm-9">
              <input type="number" class="form-control" name="intPos" [(ngModel)]="modelMenu.intPos" id="intPos"
                #intPos="ngModel"  placeholder="Vị trí">
            </div>
          </div>
        <p-footer>
            <div class="col-md-12 d-flex justify-content-end mt-1"> 
                <p-button label="Lưu lại" styleClass="mr-1" [disabled]="!createMenu.form.valid"  (onClick)="saveMenu()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayMenu = !displayMenu">
                </p-button>
            </div>
            </p-footer>
      </form>
</p-dialog>
<p-dialog [(visible)]="displayRole" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        Thêm role vào web
    </p-header>
    <form #createRole="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
        <div class="col-md-12">
            <label>Mã nhóm quyền <span class="text-danger">(*)</span></label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelRole.roleCd" name="roleCd" #roleCd="ngModel" required >
        </div>
        <div class="col-md-12">
            <label>Tên nhóm quyền<span class="text-danger">(*)</span></label>
            <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelRole.roleName" name="roleName" #roleName="ngModel" required >
            </div>
        <p-footer>
            <div class="col-md-12 d-flex justify-content-end mt-1">
                <p-button label="Lưu lại" styleClass="mr-1" (click)="saveRole()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayRole = !displayRole">
                </p-button>
            </div>
            </p-footer>
      </form>
</p-dialog>


<p-dialog [(visible)]="displayActionMenu" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header>
        Thêm action vào menu
    </p-header>
    <p-pickList [source]="sourceActions" [target]="targetActions" [showSourceControls]="false" [showTargetControls]="false" sourceHeader="Danh sách menu được chọn" targetHeader="Danh sách menu" [responsive]="true" filterBy="actionCd"
    dragdrop="true" sourceFilterPlaceholder="Search by brand" targetFilterPlaceholder="Search by brand" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}">
<ng-template let-car pTemplate="item">
    <div class="ui-helper-clearfix">
        <p style="display:inline-block;margin:2px 0 2px 2px">{{car.actionCd}}</p>
        <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
        <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{car.actionName}}</div>
    </div>
</ng-template>
</p-pickList>
<p-footer>
    <div class="col-md-12 d-flex justify-content-end mt-1">
        <p-button label="Lưu lại" styleClass="mr-1" (click)="saveActionToMenu()"></p-button>
        <p-button label="Đóng" styleClass="ui-button-secondary" (click)="displayActionMenu = !displayActionMenu">
        </p-button>
    </div>
    </p-footer>
</p-dialog>