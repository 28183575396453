import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;

@Injectable()
export class FeedBaseService {
    constructor(
    ) {
    }
    /**
     * Method used to used get all document in collection
     * @param collectionName: Name of collection want to get data
     * @return Promise<SnapshotChange>: List of document in collection
     */
    getAllDocument(collectionName) {
        return firebase.firestore().collection(collectionName).get();
    }

    getDocumentByRef(path) {
        return firebase.firestore().doc(path).get();
    }

    getCollectionByPath(path) {
        return firebase.firestore().collection(path).get();
    }

    createDocumentWithName(collectionName, documentName, data) {
        return firebase.firestore().collection(collectionName).doc(documentName).set(data);
    }

    getAllDocumentWhereFields(collectionName, arrayProjectcode) {
        return firebase.firestore().collection(collectionName).where('service', '==', '0503').where('project_code', 'in', arrayProjectcode).get();
    }
    
    getUserInThreadsByUserId(collectionName, docid, userId) {
        return firebase.firestore().collection(collectionName).doc(docid).collection('users').doc(userId).get();
    }
    getThreadsIdInUsers(collectionName, docid) {
        return firebase.firestore().collection(collectionName).doc(docid).collection('threads').get();
    }

    deleteUserId(collectionName, docid) {
        return firebase.firestore().collection(collectionName).doc(docid).delete();
    }

    removeUserInThreads(collectionName, docid, userId) {
        return firebase.firestore().collection(collectionName).doc(docid).collection('users').doc(userId).delete();
    }
}