
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var jQuery: any;
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AgGridFn } from 'src/app/utils/common/function-common';
import { ButtonRendererComponent } from 'src/app/viewcommon/renderer/button-renderer.component';
import { ButtonRendererComponent1 } from 'src/app/viewcommon/renderer/button-renderer.component-1';
import { AvatarComponent } from 'src/app/viewcommon/avatar/avatar.component';
import { Action } from 'src/app/types/action.type';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-websiterinfo',
  templateUrl: './websiterinfo.component.html',
  styleUrls: ['./websiterinfo.component.css']
})
export class WebsiterinfoComponent implements OnInit, OnChanges {
  public agGridFn = AgGridFn;
  public modules: Module[] = AllModules;
  public gridApi;
  public detailRowHeight;
  public detailCellRendererParams;
  public gridColumnApi;
  public frameworkComponents: any;
  public columnDefs;
  public defaultColDef;
  public getRowHeight;
  public colResizeDefault;
  public rowData: any[] = [];
  public gridflexdetails: any;
  public gridflexs: any;
  public metas = [];
  public submit: boolean = false;
  public isEditMenu = false;
  public isEditTab = false;
  public detailMenu;
  public detailTab;
  public sourceActions: Action[] = [];
  public targetActions: Action[] = [];
  public detailAction
  public isEditAction
  public filter = {
    mod_cd: '',
    table_name: '',
    column_name: ''
  };
  activeIndex: number = 0;
  @Input() dataFieldsInfo: any;
  @Output() back = new EventEmitter<any>();
  public loading = false;
  public tableGrids: any
  public items: any[] = [];
  public columnFieldList: any
  public objectAction: any
  public modelTab = {
    tabCd: '',
    tabName: '',
    webId: ''
  }
  public modelMenu = {
    menuId: 0,
    webId: '',
    clientId: '',
    menuRole: '',
    title: '',
    path: '',
    icon: '',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: true,
    isNavHeader: true,
    parentId: 0,
    action: '',
    tabId: 0,
    intPos: 0,
    tabCd: '',
    menuCd: '',
    createDt: new Date()
  }
  public modelRole = {
    isAdmin: false,
    roleCd: '',
    roleName: '',
    webId: '',
    webKey: '',
    webName: '',
    roleId: null,
    rolemenu: [],
  }
  actions: any[] = [];
  public modelAction = {
    actionId: 0,
    actionCd: '',
    menuId: 0,
    actionName: '',
    webId: ''
  }
  public displayTab = false;
  public displayMenu = false;
  public displayRole = false;
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      buttonRenderer1: ButtonRendererComponent1,
      avatarRenderer: AvatarComponent,
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 50;
      } else {
        return 350;
      }
    },
      this.defaultColDef = {
        resizable: true,
      };
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  ngOnInit() { }

  ngOnChanges() {
    this.activeIndex = 0;
    if (this.dataFieldsInfo && this.dataFieldsInfo.group_fields && this.dataFieldsInfo.group_fields.length > 0) {
      this.dataFieldsInfo.group_fields.forEach(groupChildren => {
        groupChildren.fields.forEach(data => {
          data[`${data.field_name}`] = data.columnValue;
          if (data.data_type === 'datetime') {
            data[`${data.field_name}`] = data.columnValue ? new Date(this.convestDate(data.columnValue)) : '';
          }

          if (data.columnType === 'image' && (data.field_name === 'iconUrl')) {
            this.dataFieldsInfo.formUser = true;
          }
          if (data.columnType === 'select') {
            this.apiService.getCustObjectList(data.columnObject).subscribe(results => {
              data.options = results.data;
            })
          }
        });
      })
    }
    if (this.dataFieldsInfo && this.dataFieldsInfo.view_grids_tabs && this.dataFieldsInfo.view_grids_tabs.length > 0) {
      this.gridflexs = this.dataFieldsInfo.view_grids_tabs;
      this.items = this.dataFieldsInfo.tabs;
      this.gridflexs.forEach(column => {
        if (column.columnField === 'actions') {
          this.objectAction = column;
        }
      });
      if (this.gridApi) {
        this.gridApi.setRowData(this.items);
      }
      this.onInit();
    }
  }

  onInit() {
    this.columnDefs = [
      ...this.agGridFn(this.gridflexs),
      {
        headerName: 'Thao tác', field: 'button', filter: '', cellRenderer: 'buttonRenderer1',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: this.objectAction ? this.objectAction.columnWidth : 100,
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handlEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-primary mr-1',
                show: true
              },
              {
                onClick: this.handleaddaction.bind(this),
                label: 'Thêm action',
                icon: 'fa fa-plus-circle',
                class: 'btn-primary',
                show: this.baseZIndex == 1 && params.data.menuId > 0
              },

            ]
          };
        },
      },
    ];
  }

  handlEdit(e) {
    console.log(e)
    if (this.baseZIndex === 1) {
      this.detailMenu = e.rowData;
      this.modelMenu = { ...e.rowData };
      this.isEditMenu = true;
      this.displayMenu = true;
    } else if(this.baseZIndex === 2) {
      this.isEditAction = true
      this.detailAction = e.rowData;
      this.modelAction = { ...e.rowData };
      this.actions.push(this.modelAction);
      this.displayAction = true;
    }else {
      this.isEditTab = true
      this.detailTab = e.rowData;
      this.modelTab = { ...e.rowData };
      this.displayTab = true;
    }

    // if (this.detailrole.webRoleId) {
    //   let index = this.dataFieldsInfo.roles.findIndex(res => res.webRoleId === this.detailrole.webRoleId);
    //   this.dataFieldsInfo.roles[index] = { ...this.dataFieldsInfo.roles[index], ...this.modelRole }
    // } else {
    //   let index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === this.detailrole.roleCd);
    //   this.dataFieldsInfo.roles[index] = { ...this.dataFieldsInfo.roles[index], ...this.modelRole }
    // }
    // this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles]
    // this.displayRole = false;
  }

  convestDate(data) {
    console.log(data)
    if (data) {
      let date1s = data.split('/');
      return `${date1s[2]}-${date1s[1]}-${date1s[0]}`
    }
  }

  backpage() {
    this.back.emit("back")
  }

  deleteAvatar(dataParent, field_name) {
    dataParent.fields.forEach(data => {
      if (data.field_name === field_name) {
        data[data.field_name] = '';
      }
    });
  }

  onUploadOutput(event, dataParent) {
    if (event.target.files[0] && event.target.files[0].size > 0) {
      const getDAte = new Date();
      const getTime = getDAte.getTime();
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`housing/avatars/${getTime}-${event.target.files[0].name}`).put(event.target.files[0]);
      uploadTask.on('state_changed', (snapshot) => {
      }, (error) => {
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (downloadURL) {
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Upload ảnh thành công'});
            dataParent.fields.forEach(data => {
              if (data.columnType === 'image') {
                data[data.field_name] = downloadURL;
              }
            })
          }
        });
      });
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  baseZIndex: number
  handleChange(e) {
    this.baseZIndex = e.index;
    if (e.index === 2) {
      this.modelAction.webId = this.dataFieldsInfo.webId;
      this.targetActions = _.cloneDeep(this.dataFieldsInfo.clientaction);
      this.gridflexs = this.dataFieldsInfo.view_grids_action;
      this.dataFieldsInfo.clientaction = [...this.dataFieldsInfo.clientaction];
      this.gridflexs.forEach(column => {
        if (column.columnField === 'actions') {
          this.objectAction = column;
        }
      });
      this.onInit();
    } else if (e.index === 3) {
      this.gridflexs = this.dataFieldsInfo.view_grids_roles;
      this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
      this.gridflexs.forEach(column => {
        if (column.columnField === 'actions') {
          this.objectAction = column;
        }
      });
      this.onInit();
    } else if (e.index === 1) {
      this.gridflexs = this.dataFieldsInfo.view_grids_menu;
      this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus]
      this.gridflexs.forEach(column => {
        if (column.columnField === 'actions') {
          this.objectAction = column;
        }
      });
      this.onInit();
    } else if (e.index === 0) {
      this.gridflexs = this.dataFieldsInfo.view_grids_tabs;
      this.dataFieldsInfo.tabs = [...this.dataFieldsInfo.tabs]
      this.gridflexs.forEach(column => {
        if (column.columnField === 'actions') {
          this.objectAction = column;
        }
      });
      this.onInit();
    }
  }

  addTab() {
    this.displayTab = true;
    this.detailTab = null;
    this.isEditMenu = false;
    this.modelTab = {
      tabCd: '',
      tabName: '',
      webId: ''
    }
  }

  addMenu() {
    this.displayMenu = true;
    this.isEditMenu = false;
    this.detailMenu = null;
    this.modelMenu = {
      menuId: 0,
      webId: '',
      clientId: '',
      menuRole: '',
      title: '',
      path: '',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: true,
      isNavHeader: true,
      parentId: 0,
      action: '',
      tabId: 0,
      intPos: 0,
      tabCd: '',
      menuCd: '',
      createDt: new Date()
    }
  }



  addRole() {
    this.displayRole = true;
    this.modelRole = {
      isAdmin: false,
      roleCd: '',
      roleName: '',
      webId: '',
      webKey: '',
      webName: '',
      roleId: null,
      rolemenu: []
    }
  }

  saveTab() {
    if (this.isEditTab) {
      if (this.modelMenu.tabId > 0) {
        const index = this.dataFieldsInfo.tabs.findIndex(res => res.tabId === this.modelMenu.tabId);
        this.dataFieldsInfo.tabs[index] = { ... this.dataFieldsInfo.tabs[index], ...this.modelTab };
        this.dataFieldsInfo.tabs = [...this.dataFieldsInfo.tabs];
        this.displayTab = false;
      } else {
        const index = this.dataFieldsInfo.tabs.findIndex(res => res.tabCd === this.detailTab.tabCd);
        this.dataFieldsInfo.tabs[index] = { ... this.dataFieldsInfo.tabs[index], ...this.modelTab };
        this.dataFieldsInfo.tabs = [...this.dataFieldsInfo.tabs];
        this.displayTab = false;
      }

    } else {
      this.dataFieldsInfo.tabs.push(this.modelTab);
      this.dataFieldsInfo.tabs = [...this.dataFieldsInfo.tabs];
      this.gridApi.setRowData(this.dataFieldsInfo.tabs);
      this.displayTab = false;
    }
  }

  saveRole() {
    this.dataFieldsInfo.roles.push(this.modelRole);
    this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
    this.gridApi.setRowData(this.dataFieldsInfo.roles);
    this.displayRole = false
  }

  randomMenuCd(event) {
    if (this.modelMenu.menuId === 0) {
      this.modelMenu.menuCd = `${event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[_\s]/g, '_')}_${new Date().getTime()}`;
    }
  }

  saveMenu() {
    if (this.isEditMenu) {
      if (this.modelMenu.menuId > 0) {
        const index = this.dataFieldsInfo.menus.findIndex(res => res.menuId === this.modelMenu.menuId);
        this.dataFieldsInfo.menus[index] = { ... this.dataFieldsInfo.menus[index], ...this.modelMenu };
        this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus];
        this.displayMenu = false;
      } else {
        const index = this.dataFieldsInfo.menus.findIndex(res => res.menuCd === this.detailMenu.menuCd);
        this.dataFieldsInfo.menus[index] = { ... this.dataFieldsInfo.menus[index], ...this.modelMenu };
        this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus];
        this.displayMenu = false;
      }

    } else {
      this.dataFieldsInfo.menus.push(this.modelMenu);
      this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus];
      this.gridApi.setRowData(this.dataFieldsInfo.menus);
      this.displayMenu = false;
    }
  }

  saveWebsite() {
    this.loading = true;
    this.submit = true;
    this.dataFieldsInfo.group_fields.forEach(results => {
      results.fields.forEach(data => {
        if (data.data_type === 'datetime') {
          data.columnValue = data[data.field_name] ? moment(data[data.field_name]).format("DD/MM/YYYY") : null;
          delete data[data.field_name];
        } else {
          data.columnValue = data[data.field_name];
          delete data[data.field_name];
        }
        if ((data.columnType === 'select') && data.options) {
          delete data.options;
        }
      })
    });
    const params = {
      webId: this.dataFieldsInfo.webId,
      view_grids_roles: this.dataFieldsInfo.view_grids_roles,
      roles: this.dataFieldsInfo.roles,
      view_grids_tabs: this.dataFieldsInfo.view_grids_tabs,
      tabs: this.dataFieldsInfo.tabs,
      view_grids_menu: this.dataFieldsInfo.view_grids_menu,
      menus: this.dataFieldsInfo.menus,
      group_fields: this.dataFieldsInfo.group_fields,
    };
    this.apiService.setClientWebInfo(params)
      .subscribe(result => {
        if (result.status === 'success') {
          this.loading = false;
          this.back.emit("success");
          this.activeIndex = null;
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Thêm mới website'});
        }
      });
  }

  displayAction
  addAction() {
    this.displayAction = true;
    this.modelAction = {
      actionId: 0,
      actionCd: '',
      menuId: 0,
      actionName: '',
      webId: this.dataFieldsInfo.webId
    }
    if (this.actions && this.actions.length === 0) {
      this.actions.push(this.modelAction);
    }
  }

  addActions() {
    let params = {
      actionId: 0,
      actionCd: '',
      menuId: 0,
      actionName: '',
      webId: this.dataFieldsInfo.webId
    }
    this.actions.push(params);
  }

  deleteAction(index) {
    if (this.actions[index].actionId > 0) {
      if (confirm("Bạn có chắc chắn muốn xóa action này")) {

      }
    } else {
      this.actions.splice(index, 1);
    }
  }

  onSaveAction() {
    const length = this.actions.length;
    for (let i = 0; i < length; i++) {
      this.apiService.setClientAction(this.actions[i]).subscribe((results: any) => {
        if (results.status === 'success') {
          console.log(results);
          this.displayAction = false;
          this.back.emit('success');
          // this.load();
          // jQuery("#createAction").modal("hide");
        }
      }, error => { })
    }
    this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Thêm mới action thành công'});
  }

  displayActionMenu = false;
  handleaddaction(e) {
    if (e.rowData.action) {
      const actions = e.rowData.action.split(',');
      this.sourceActions = this.dataFieldsInfo.clientaction.filter(word => new RegExp(word.actionCd).test(e.rowData.action))
      this.targetActions = this.dataFieldsInfo.clientaction.filter(word => !new RegExp(word.actionCd).test(e.rowData.action))
      this.targetActions.forEach(res => res.menuId = e.rowData.menuId);
      this.sourceActions.forEach(res => res.menuId = e.rowData.menuId);
    } else {
      this.targetActions = _.cloneDeep(this.dataFieldsInfo.clientaction);
      this.sourceActions = [];
      this.targetActions.forEach(res => res.menuId = e.rowData.menuId);
    }
    this.displayActionMenu = true;
  }

  saveActionToMenu() {
    this.targetActions.forEach(res => res.menuId = 0);
    if (this.sourceActions && this.sourceActions.length > 0) {
      let index = this.dataFieldsInfo.menus.findIndex(res => res.menuId === this.sourceActions[0].menuId);
      this.dataFieldsInfo.menus[index].action = this.sourceActions.map(res => res.actionCd).toString();
      this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus]
    }
    this.displayActionMenu = false;
  }
}





















