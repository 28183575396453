export class CatalogModel {
  constructor(
    public  categoryCd: string,
    public  categoryLevel: string,
    public  categoryName: string,
    public  parentCd: string,
    public  categoryMail: string,
    public  categoryShow: string
    ) { }

  // tslint:disable-next-line:member-ordering
  static createDefault() {
      return new CatalogModel('', '', '', '', '', '');
  }
}

export class RoleModel {
  constructor(
    public  id: string,
    public  name: string,
    public  normalizedName: string,
    public  concurrencyStamp: string
    ) { }

  // tslint:disable-next-line:member-ordering
  static createDefault() {
      return new RoleModel('', '', '', '');
  }
}

export class Role {
  constructor(
  public userId: string,
  public roles: any[] ) {}
  static createDefault() {
      return new Role('', []);
  }
}

export class Department {
  constructor(
      public parentName: string,
      public departmentCd: string,
      public departmentName: string,
      public note: string,
      public emailOwn: string,
      public emailList: string,
      public parentCd: string,
      public intOrder: number) { }

  static createDefault() {
      return new Department('', '', '', '', '', '', '', 0);
  }
}

export class UserMd {
  constructor(

      public isLock: true,
      public lockName: string,
      public startDate: string,
      public userId: string,
      public custId: string,
      public userLogin: string,
      public userPassword: string,
      public avatarUrl: string,
      public isAdmin: true,
      public isChat: true
  ) { }
  // static createDefault() {
  //     return new UserMd();
  // }
}






