import { Input, Output, EventEmitter, Component, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-base-paging',
  templateUrl: './base-paging.component.html',
  styleUrls: ['./base-paging.component.css']
})
export class BasePagingComponent implements OnInit {

  constructor() { }

  pageIndex = 1;
  pageSize = 20;
  pages = [];

  pageCount = 1;
  model: any = {};
  pageName = '';

  private _total = 0;

  @Input() set total(total: number) {
    this._total = total;
    this.repaging();
  }
  get total(): number { return this._total; }

  @Output() pageChange = new EventEmitter<boolean>();

  getOffset() {
    return (this.pageIndex - 1) * this.pageSize;
  }

  repaging() {
    this.pageCount = Math.ceil(this.total / this.pageSize);
    let end_page = this.pageCount;
    let start_page = 1;
    if (this.pageCount > 5) {
      // more than 10 total pages so calculate start and end pages
      if (this.pageIndex <= 3) {
        start_page = 1;
        end_page = 5;
      } else if (this.pageIndex + 2 >= this.pageCount) {
        start_page = this.pageCount - 4;
        end_page = this.pageCount;
      } else {
        start_page = this.pageIndex - 3;
        end_page = this.pageIndex + 2;
      }
    }
    this.pages = [];
    for (let i = start_page; i <= end_page; i++) {
      this.pages.push(i);
    }
  }
  page(index) {
    this.pageIndex = index;
    this.pageChange.emit(index);
    if (this.pageName) {
      localStorage.setItem(this.pageName, this.pageIndex.toString());
    }
  }

  ngOnInit() {
    console.log(this.total);
    if (localStorage.getItem('post_back') && localStorage.getItem(this.pageName)) {
      this.pageIndex = Number(localStorage.getItem(this.pageName));
      // localStorage.setItem(this.pageName, '');
    }
    this.repaging();
    this.page(this.pageIndex);
  }

}
