import { ApiCoreService } from 'src/app/services/api-core/api-core-service/api-core.service';
import { AvatarComponent } from 'src/app/viewcommon/avatar/avatar.component';
import { AgGridFn } from 'src/app/utils/common/function-common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonRendererComponent } from 'src/app/viewcommon/renderer/button-renderer.component';
import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import 'rxjs/add/operator/filter';
import { ApiService } from 'src/app/services/api.service';
import * as queryString from 'querystring';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-detail-role',
  templateUrl: './detail-role.component.html',
  styleUrls: ['./detail-role.component.css']
})
export class DetailRoleComponent implements OnInit {
  dataMenuActionRole: any;
  cols: any[];
  loading = false;
  public agGridFn = AgGridFn;
  public modules: Module[] = AllModules;
  columnDefs;
  detailRowHeight;
  defaultColDef;
  frameworkComponents;
  groupDefaultExpanded;
  detailCellRendererParams;
  gridApi: any;
  clientWidth: any;
  gridColumnApi: any;
  objectAction: any;
  gridflexs: any;
  displayaddFunctions: boolean = false;
  getRowHeight;
  detailWebSmart: any = {};
  detailRole: any;
  isEdit = false;
  constructor(private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinner : NgxSpinnerService,
    private route: ActivatedRoute,
    private apiService: ApiService) {
    this.defaultColDef = {
      tooltipComponent: 'customTooltip',
      resizable: true,
    };
    this.getRowHeight1 = (params) => {
      if (params.node.master) {
        return 40;
      } else {
        return 300;
      }
    };
    this.frameworkComponents = {
      // customTooltip: CustomTooltipComponent,
      buttonRenderer1: ButtonRendererComponent,
      avatarRenderer: AvatarComponent,
    };

  }
  indexTab = 0;
  lisstMenus = [];
  lisstTabs = [];
  lisstActions = [];
  lisstRoles = [];
  displayAddMenu = false;
  displayAddAction = false;
  displayAddRole = false;
  titleForm = {
    label: 'Thêm mới menu',
    value: 'Add'
  }
  detailMenuInfo: any = null;
  sourceActions = []
  targetActions = []
  query = {
    webId: '',
    filter: '',
    gridWith: 0,
    offSet: 0,
    pageSize: 10000000
  }

  ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.query.webId = params.id;
        this.callApiGetInfo(0)
        this.indexTab = 0
      }
      );
  }
  dataFieldsInfo = null;
  callApiGetInfo(index = 0) {
    this.apiService.getClientWebInfoManager(this.query.webId).subscribe(results => {
      if (results.status === 'success') {
        this.dataFieldsInfo = results.data;
        this.dataFieldsInfo.clientaction = [...this.dataFieldsInfo.clientaction];

        if (index == 3) {
          this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
          this.handleChange({ index: 3 })
        } else if (index == 1) {
          this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus];
          this.dataFieldsInfo.menutree = [...this.dataFieldsInfo.menutree];
          this.handleChange({ index: 1 });
        } else if (index == 0) {
          this.dataFieldsInfo.tabs = [...this.dataFieldsInfo.tabs];
          this.handleChange({ index: 0 })
        }
      }
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  gridApiMenu
  gridColumnApiMenu
  onGridReadyMenu(params) {
    this.gridApiMenu = params.api;
    this.gridColumnApiMenu = params.columnApi;
  }

  expanded = null
  handleChange(event) {
    this.indexTab = event.index;
    if (this.indexTab === 1) {
      this.cols = [];
      // this.getClientMenuPageByWebId();
      this.lisstMenus = cloneDeep(this.dataFieldsInfo.menutree);
      this.cols = cloneDeep(this.dataFieldsInfo.view_grids_menu);
      this.init1();
      if(this.expanded) {
        const index = this.lisstMenus.findIndex(d => d.menuId == this.expanded);
        this.setExpanded(index);
      }
    } else if (this.indexTab === 2) {
      this.cols = [];
      this.cols = cloneDeep(this.dataFieldsInfo.view_grids_action);
      this.lisstActions = cloneDeep(this.dataFieldsInfo.clientaction);
      this.init();

    } else if (this.indexTab === 3) {
      this.cols = [];
      this.lisstRoles = this.dataFieldsInfo.roles;
      this.cols = cloneDeep(this.dataFieldsInfo.view_grids_roles);
      this.init();
    } else {
      this.cols = [];
      this.lisstTabs = cloneDeep(this.dataFieldsInfo.tabs);
      this.cols = cloneDeep(this.dataFieldsInfo.view_grids_tabs);
      this.init();
    }
  }

  modelAction = {
    id: 0,
    actionId: 0,
    actionCd: '',
    menuId: 0,
    actionName: '',
    webId: this.query.webId
  }

  init() {
    this.columnDefs = [
      ...this.agGridFn(this.cols),
      {
        headerName: '',
        field: 'button',
        filter: '',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: 90,
        cellRenderer: 'buttonRenderer1',
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.btnEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-primary mr5',
                show: true
              },
              {
                onClick: this.btnDelete.bind(this),
                label: 'Xóa',
                icon: 'fa fa-trash',
                class: 'btn-danger',
                show: true
              },
            ]
          };
        },
      },
    ];
    this.getRowHeight = (params) => {
      return 40;
    };
  }

  init1() {
    this.columnDefs = [
      ...this.agGridFn(this.cols),
      {
        headerName: 'Thứ tự',
        pinned: 'right',
        field: 'intPosEdit',
        onCellValueChanged: params => {
          this.onCellValueChanged(params)
        },
        cellRenderer: params => {
          return params.data.intPos
        },
        width: 90,
        editable: true,
        hide: this.indexTab !== 1,
        cellClass: ['border-right', 'bg-f7ff7']
      },
      {
        headerName: '',
        field: 'button',
        filter: '',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: 90,
        cellRenderer: 'buttonRenderer1',
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.btnEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-primary mr5',
                show: true
              },
              {
                onClick: this.btnDelete.bind(this),
                label: 'Xóa',
                icon: 'fa fa-trash',
                class: 'btn-danger',
                show: true
              },
            ]
          };
        },
      },
    ];
    this.groupDefaultExpanded = 0;
    this.detailCellRendererParams = {
      detailGridOptions: {
        // domLayout : "autoHeight",
        frameworkComponents: {
          buttonRenderer1: ButtonRendererComponent,
        },
        getRowHeight: (params) => {
          return 40;
        },
        columnDefs: [
          ...this.agGridFn(this.cols),
          {
            headerName: 'Thứ tự',
            pinned: 'right',
            field: 'intPosEditParent',
            onCellValueChanged: params => {
              this.onCellValueChanged(params)
            },
            cellRenderer: params => {
              return params.data.intPos
            },
            width: 90,
            editable: true,
            hide: this.indexTab !== 1,
            cellClass: ['border-right', 'bg-f7ff7']
          },
          {
            headerName: 'Chức năng',
            field: 'button',
            filter: '',
            pinned: this.objectAction ? this.objectAction.pinned : 'right',
            width: 90,
            cellRenderer: 'buttonRenderer1',
            cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
            cellRendererParams: params => {
              return {
                buttons: [
                  {
                    onClick: this.btnEdit.bind(this),
                    label: 'Sửa',
                    icon: 'fa fa-edit',
                    class: 'btn-primary mr5',
                    show: true
                  },
                  {
                    onClick: this.btnDelete.bind(this),
                    label: 'Xóa',
                    icon: 'fa fa-trash',
                    class: 'btn-danger',
                    show: true
                  },
                ]
              };
            },
          },
        ],
      },
      getDetailRowData: function (params) {
        params.successCallback(params.data.submenus);
      }
    };
    
  }
  getRowHeight1;
  setExpanded(id) {
    console.log(this.gridApi)
    setTimeout(() => {
      this.gridApiMenu.forEachNode((node) => {
        if (node.id == id) {
          node.setExpanded(true);
        }
      });
    }, 500);
  }

  onCellValueChanged(event) {
    if (event.colDef.field === 'intPosEdit' ||event.colDef.field === 'intPosEditParent') {
      const queryParams = queryString.stringify({ menuId: event.data.menuId, webId: this.query.webId });
      this.apiService.getClientMenuInfo(queryParams).subscribe(results => {
        if (results.status === 'success') {
          this.detailMenuInfo = results.data;
          this.detailMenuInfo.group_fields.forEach(element => {
            element.fields.forEach(element1 => {
                if(element1.field_name === 'intPos') {
                  element1.columnValue = event.newValue
                }else if(element1.field_name === 'parentId' && event.colDef.field === 'intPosEdit') {
                  this.expanded =element1.columnValue ?  element1.columnValue: null;
                }
            });
          });
          this.saveMenuInfo(this.detailMenuInfo);
        }
      })
    }

  }

  getClientMenuPageByWebId() {
    const queryParams = queryString.stringify(this.query);
    this.apiService.getClientMenuPageByWebId(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.lisstMenus = cloneDeep(results.data.dataList.data);
        this.cols = cloneDeep(results.data.gridflexs);
        this.init1();
      }
    })
  }

  getClientRolePageByWebId() {
    const queryParams = queryString.stringify(this.query);
    this.apiService.getClientRolePageByWebId(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.lisstRoles = cloneDeep(results.data.dataList.data);
        this.cols = cloneDeep(results.data.gridflexs);
        this.init();
      }
    })
  }

  backpage() {
    this.displayAddMenu = false;
    this.displayAddRole = false;
  }

  displayUserRoleInfo = false
  btnEdit(e) {
    if (this.indexTab == 1) {
      this.titleForm = {
        label: 'Chỉnh sửa menu',
        value: 'Edit'
      }
      this.getConfigMenu(e.rowData.menuId);
    } else if (this.indexTab == 2) {
      this.displayAddAction = true;
      this.modelAction = {
        id: 0,
        actionId: e.rowData.actionId,
        actionCd: e.rowData.actionCd,
        menuId: 0,
        actionName: e.rowData.actionName,
        webId: this.query.webId
      }
    } else if (this.indexTab == 3) {
      this.getRoleInfo(e.rowData.roleId);
      this.titleForm = {
        label: 'Chỉnh sửa quyền',
        value: 'Edit'
      }
      this.displayUserRoleInfo = true;
    } else {
      this.displayAddTab = true
      this.modelTab = {
        tabId: e.rowData.tabId,
        webId: this.query.webId,
        tabCd: e.rowData.tabCd,
        tabName: e.rowData.tabName
      }
    }

  }

  changeActionChirlden(idxParent, index, actionIdx) {
    this.menus[idxParent].submenus[index].actions[actionIdx].isCheck = !this.menus[idxParent].submenus[index].actions[actionIdx].isCheck;
  }

  changeActionAdministration(index, actionIdx) {
    this.menus[index].actions[actionIdx].isCheck = ! this.menus[index].actions[actionIdx].isCheck ;
  }
  listActions = []
  getConfigMenu(id) {
    const queryParams = queryString.stringify({ menuId: id, webId: this.query.webId });
    this.apiService.getClientMenuInfo(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.detailMenuInfo = results.data;
        this.displayAddMenu = true;
        this.sourceActions = this.detailMenuInfo.actions;
        const targetActions = [];
        this.dataFieldsInfo.clientaction.forEach(element => {
          if (this.sourceActions.map(res => res.actionId).indexOf(element.actionId) < 0) {
            targetActions.push(element);
          }
        });
        this.listActions = targetActions;
        this.targetActions = cloneDeep(targetActions);
      }
    })
  }

  onTargetFilter(event) {
    console.log(event)
    if(event.query === "") {
      this.targetActions = cloneDeep(this.listActions)
    }else {
      this.targetActions = cloneDeep(event.value)
    }
  }
  detailRoleInfo = null
  getRoleInfo(id) {
    const queryParams = queryString.stringify({ roleId: id, webId: this.query.webId });
    this.apiService.getClientRoleInfo(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.detailRoleInfo = results.data;
        this.displayAddRole = true;
      }
    })
  }

  saveRoleInfo(data) {
    const params = {
      roleId: data.roleId,
      rolemenu: data.rolemenu || [],
      group_fields: data.group_fields,
      webId: this.query.webId
    };
    this.apiService.setClientRoleInfo(params).subscribe(results => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.data });
        this.displayAddRole = false;
        this.callApiGetInfo(3);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message });
      }
    })
  }


  btnDelete(e) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện hành động này?',
      accept: () => {
        if (this.indexTab === 1) {
          const queryParams = queryString.stringify({ menuId: e.rowData.menuId });
          this.apiService.delClientMenu(queryParams).subscribe(results => {
            if (results.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa menu thành công' });
              this.callApiGetInfo(1);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : 'Lỗi hệ thống' });
            }
          });
        } else if (this.indexTab === 2) {
          const queryParams = queryString.stringify({ actionId: e.rowData.actionId });
          this.apiService.delClientAction(queryParams).subscribe(results => {
            if (results.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa action thành công' });
              this.callApiGetInfo(2);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : 'Lỗi hệ thống' });
            }
          });
        } else if (this.indexTab === 3) {
          const queryParams = queryString.stringify({ roleId: e.rowData.roleId });
          this.apiService.delClientRole(queryParams).subscribe(results => {
            if (results.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa role thành công' });
              this.callApiGetInfo(3);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : 'Lỗi hệ thống' });
            }
          });
        } else {
          const queryParams = queryString.stringify({ tabId: e.rowData.tabId });
          this.apiService.delClientWebTab(queryParams).subscribe(results => {
            if (results.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa role thành công' });
              this.callApiGetInfo(0);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : 'Lỗi hệ thống' });
            }
          });
        }
      }
    });

  }



  addMenu() {
    this.getConfigMenu(0);
    this.titleForm = {
      label: 'Thêm mới menu',
      value: 'Add'
    }
  }

  addRole() {
    this.getRoleInfo(0);
    this.titleForm = {
      label: 'Thêm mới quyền',
      value: 'Add'
    }
  }


  saveMenuInfo(data) {
    this.spinner.show();
    const params = {
      menuId: data.menuId,
      group_fields: data.group_fields,
      actions: data.actions,
      webId: data.webId
    };
    this.apiService.setClientMenuInfo(params).subscribe((results: any) => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.data });
        this.displayAddMenu = false;
        this.spinner.hide();
        this.callApiGetInfo(1)
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message });
        this.spinner.hide();
      }
    }, error => {
    });

  }

  saveWebInfo(data) {
    const params = {
      webId: data.webId,
      group_fields: data.group_fields,
      tabs: data.tabs,
      view_grids_menu: data.view_grids_menu,
      menus: data.menus,
      view_grids_roles: data.view_grids_roles,
      roles: data.roles,
      view_grids_role_menu: data.view_grids_role_menu,
      rolemenus: data.rolemenus,
      view_grids_action: data.view_grids_action,
      clientaction: data.clientaction,
      menutree: data.menutree
    };
    this.apiService.setClientWebInfoManager(params).subscribe((results: any) => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.data });
        this.callApiGetInfo()
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message });
      }
    }, error => {
    });
  }

  displayMenuRole = false;
  menus = [];
  clickRowRoleGetMenu(event) {
    console.log(event)
    this.menus = cloneDeep(this.dataFieldsInfo.menutree);
    this.menus.forEach(m => {
      m.isCheck = false;
      m.isCheckAll = false;
      m.submenus.forEach(d => {
        d.isCheck = false
      })
    });
    this.detailRole = event.data
    this.getRoleInfoSidebar(event.data.roleId);
    this.displayMenuRole = true;
  }
  listMenuRoles = []
  getRoleInfoSidebar(id) {
    const queryParams = queryString.stringify({ roleId: id, webId: this.query.webId });
    this.apiService.getClientRoleInfo(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.detailRoleInfo = results.data;
        this.listMenuRoles = cloneDeep(this.detailRoleInfo.rolemenu);
        this.menus.forEach(m => {
          if (this.listMenuRoles.map(q => q.menuId).indexOf(m.menuId) > -1) {
            m.isCheck = true;
            this.listMenuRoles.forEach(a => {
              if (m.menuId === a.menuId) {
                if (a.actions && a.actions.length > 0) {
                  m.actions.forEach(action => {
                    a.actions.forEach(action1 => {
                      if (action.actionId === action1.actionId) {
                        action.isCheck = true;
                      }
                    })
                  });
                  console.log(m)
                } else {
                 m.actions.forEach(action => action.isCheck = false)
                }
      
              }
            })
          } else {
            m.isCheck = false;
          }
          m.submenus.forEach(d => {
            if (this.listMenuRoles.map(q => q.menuId).indexOf(d.menuId) > -1) {
              d.isCheck = true;
              //load is check action
        
              this.listMenuRoles.forEach(a => {
                if (d.menuId === a.menuId) {
                  if (a.actions && a.actions.length > 0) {
                    d.actions.forEach(action => {
                      a.actions.forEach(action1 => {
                        if (action.actionId === action1.actionId) {
                          action.isCheck = true;
                        }
                      })
                    });
                  } else {
                    d.actions.forEach(action => action.isCheck = false)
                  }
        
                }
              })
            } else {
              d.isCheck = false;
            }
            this.listMenuRoles.forEach(g => {
              if (g.menuId === d.menuId) {
                d.intPos = g.intPos;
              }
            })
          });
          m.submenus.sort(compare_qty)
          this.listMenuRoles.forEach(g => {
            if (g.menuId === m.menuId) {
              m.intPos = g.intPos;
            }
          })
        });
        this.menus = [...this.menus].sort(compare_qty);
        console.log(this.menus )
      }
    })

  }

  fnIsCheckAction(d) {
    
  }

  listTypeRoles = [];
  // getRoleTypes() {
  //   this.apiService.getRoleTypes().subscribe(results => {
  //     if (results.status === 'success') {
  //       this.listTypeRoles = results.data.map(res => {
  //         return {
  //           label: res.name,
  //           value: res.id
  //         }
  //       });
  //     }
  //   })
  // }
  rowDoubleClicked(e) {
    console.log(e)
  }

  onHideMenuRole(e) {
    this.confirmationService.confirm({
      message: 'Bạn có muốn lưu lại danh sách menu không?',
      accept: () => {
        const arrayMenus = []
        this.menus.forEach(res => {
          if (res.isCheck) {
            arrayMenus.push({
              menuRoleId: res.menuRoleId,
              menuId: res.menuId,
              menuCd: res.menuCd,
              webId: res.webId,
              webRoleId: res.webRoleId,
              userId: res.userId,
              roleCd: res.roleCd,
              title: res.title,
              tabId: res.tabId,
              tabCd: res.tabCd,
              intPos: res.intPos,
              actions: res.actions.filter(action => action.isCheck)
            })
          }
          res.submenus.forEach(element => {
            if (element.isCheck) {
              arrayMenus.push({
                menuRoleId: element.menuRoleId,
                menuId: element.menuId,
                menuCd: element.menuCd,
                webId: element.webId,
                webRoleId: element.webRoleId,
                userId: element.userId,
                roleCd: element.roleCd,
                title: element.title,
                tabId: element.tabId,
                tabCd: element.tabCd,
                intPos: element.intPos,
                actions: element.actions.filter(action => action.isCheck)
              })
            }
          });
        })
        const params = {
          listMenu: arrayMenus,
          dataInfo: this.dataFieldsInfo,
        }
        this.saveEditMenuRole(params, 'notEdit');
      },
      reject: () => {
        this.displayMenuRole = false;
      }
    })
  }

  changeMenuChirlden(idxParent, index) {
    this.menus[idxParent].submenus[index].isCheck = !this.menus[idxParent].submenus[index].isCheck;
    if (this.menus[idxParent].submenus[index].isCheck) {
      this.menus[idxParent].isCheck = true
    } else {
      let arrChecks = this.menus[idxParent].submenus.map(res => res.isCheck);
      if (arrChecks.includes(true)) {
        this.menus[idxParent].isCheck = true;
      } else {
        this.menus[idxParent].isCheck = false;
      }
    }
    this.menus[idxParent].listmenu[index] = [...this.menus[idxParent].listmenu[index]];
  }



  saveEditMenuRole(data, type) {
    let params = {
      rolemenu: data.listMenu || [],
      roleId: this.detailRoleInfo.roleId,
      group_fields: this.detailRoleInfo.group_fields,
      webId: this.query.webId
    }
    this.apiService.setClientRoleInfo(params).subscribe(results => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.data });
        this.callApiGetInfo(3);
        this.displayMenuRole = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message });
      }
    })
  };

  saveAction() {
    this.apiService.setClientAction(this.modelAction).subscribe(results => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Thêm mới action thành công' });
        this.displayAddAction = false;
        this.callApiGetInfo(2);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Thêm action không thành công' });
        this.displayAddAction = false;
      }
    })
  }

  addAction() {
    this.displayAddAction = true;
    this.modelAction = {
      id: 0,
      actionId: 0,
      actionCd: '',
      menuId: 0,
      actionName: '',
      webId: this.query.webId
    }
  }

  changeMenuParent(menu, index) {
    this.menus[index].isCheck = !this.menus[index].isCheck;
    if (this.menus[index].isCheck) {
      this.menus[index].submenus = this.menus[index].submenus.map(result => {
        return { ...result, isCheck: true };
      })
    } else {
      this.menus[index].submenus = this.menus[index].submenus.map(result => {
        return { ...result, isCheck: false };
      })
    }

    this.menus = [...this.menus];
  }

  changeModelintPosParent(event, index) {
    this.menus[index].intPos = event.target.value;
    this.menus.sort(compare_qty)
  }


  changeModelintPosChilrden(event, idxParent, index) {
    this.menus[idxParent].submenus[index].intPos = event.target.value;
    this.menus[idxParent].submenus.sort(compare_qty)
  }

  modelTab = {
    tabId: 0,
    webId: this.query.webId,
    tabCd: '',
    tabName: ''
  }
  displayAddTab = false;

  addTab() {
    this.displayAddTab = true
    this.modelTab = {
      tabId: 0,
      webId: this.query.webId,
      tabCd: '',
      tabName: ''
    }
  }

  saveTab() {
    this.apiService.setClientWebTab(this.modelTab).subscribe(results => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Thêm mới Tab thành công' });
        this.displayAddTab = false;
        this.callApiGetInfo(0);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Thêm Tab không thành công' });
        this.displayAddTab = false;
      }
    })
  }

}



function compare_qty(a, b) {
  // a should come before b in the sorted order
  if (a.intPos < b.intPos) {
    return -1;
    // a should come after b in the sorted order
  } else if (a.intPos > b.intPos) {
    return 1;
    // a and b are the same
  } else {
    return 0;
  }
}
