import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Department, UserMd, Role, RoleModel, CatalogModel } from '../models/customer.model';
import * as queryString from 'querystring';
const apiBaseUrl = environment.apiBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiSreBaseUrl = environment.apiSreBase;
const apiHrmBaseUrl = environment.apiHrmBase;
const apiHrmBaseChat = environment.socketServer;
// const apiHrmBaseUrl = 'https://apicore.sunshinegroup.vn';
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/ClientMenuGetListByUserId?` +
        `userId=${userId}&webId=${webId}`, this.options)
  }

  getAppRoleChatList(prod_mod_cd): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetAppRoleChatList?` +
        `prod_mod_cd=${prod_mod_cd}` , this.options)
  }

  getManagerPage(): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerPage?filter=&offSet=null&pageSize=null`, this.options)
  }

  getEmployeeList(filter, departmentCd, isUser, isApprove, intVehicle,organizationCd, offset, pagesize): Observable<Department[]> {
    return this.http
      .get<Department[]>(`${apiBaseUrl}/api/v2/employee/GetEmployeeList?` +
        `ProjectCd=01&` +
        `departmentCd=${departmentCd}&` +
        `filter=${filter}&` +
        `isUser=${isUser}&` +
        `isApprove=${isApprove}&` +
        `intVehicle=${intVehicle}&` +
        `offSet=${offset}&` +
        `organizationCd=${organizationCd}&` +
        `pageSize=${pagesize}`, this.options)
  }

  updateChatGroup<T>(userName, chatGroups): Observable<T> {
    return this.http.post<T>(`${apiHrmBaseChat}/api/v1/grouptype/${userName}`, chatGroups, this.options)
  }

  setUserAdmin<T>(userId, isAdmin):Observable<T> {
    return this.http.post<T>(`${apiBaseUrl}/api/v1/user/SetUserAdmin`, { userId: userId, isAdmin: isAdmin }, this.options)
  }

  getChatGroup<T>(userName): Observable<T> {
    return this.http.get<T>(`${apiBaseUrl}/api/v1/grouptype/${userName}`, this.options);
  }

  deleteUserCust<T>(userId) :Observable<T> {
    return this.http.delete<T>(`${apiBaseUrl}/api/v1/user/DeleteUserCust?userId=${userId}`, this.options)
  }

  unLockUser<T>(userId):Observable<T> {
    return this.http.post<T>(`${apiBaseUrl}/api/v1/user/UnLockUser`, { userId: userId }, this.options)
  }

  lockUser<T>(userId):Observable<T> {
    return this.http.post<T>(`${apiBaseUrl}/api/v1/user/LockUser`, { userId: userId }, this.options)
  }

  getUsersByCust(custId): Observable<UserMd[]> {
    return this.http
      .get<UserMd[]>(`${apiBaseUrl}/api/v1/user/GetUsersByCust?custId=${custId}`, this.options)
  }

  getUserSearchPage(filter): Observable<UserMd[]> {
    return this.http
      .get<UserMd[]>(`${apiCoreBaseUrl}/api/v1/coreuser/GetUserSearchPage?filter=${filter}`, this.options)
  }

  getGroupRole(userId): Observable<Department[]> {
    return this.http
      .get<Department[]>(`${apiBaseUrl}/api/v1/user/ClientMenuRoleGroupGet?` +
        `userId=${userId}`, this.options)
  }

  addUserToCategories(mdroles: Role): Observable<string> {
    return this.http.post<string>(`${apiBaseUrl}/api/v1/user/AddUserToCategories`, mdroles, this.options)
  }

  getUserCategories(userId): Observable<any[]> {
    return this.http
      .get<any[]>(`${apiBaseUrl}/api/v1/user/GetUserCategories?userId=` + userId, this.options)
  }

  getUserRoles(userId): Observable<any[]> {
    return this.http
      .get<any[]>(`${apiBaseUrl}/api/v1/user/GetUserRoles?userId=` + userId, this.options)
  }

  getRoles(): Observable<RoleModel[]> {
    return this.http
      .get<RoleModel[]>(`${apiBaseUrl}/api/v1/user/GetRoles`, this.options)
  }

  getRoleCategories(): Observable<CatalogModel[]> {
    return this.http
      .get<CatalogModel[]>(`${apiBaseUrl}/api/v1/user/GetRoleCategories`, this.options)
  }

  setUser<T>(custId, userLogin, userPassword):  Observable<T> {
    const user = {
      userId: '',
      custId: custId,
      userLogin: userLogin,
      userPassword: userPassword,
      avatarUrl: '',
      isAdmin: false,
    };
    return this.http
      .post<T>(`${apiBaseUrl}/api/v1/user/SetUser`, user, this.options)
  }

  changePass<T>(changePassModel):Observable<T> {
    return this.http.put<T>(`${apiBaseUrl}/api/v1/user/ResetPassword`, changePassModel, this.options)
  }

  addUserToRoles(mdroles: Role): Observable<string> {
    return this.http
      .post<string>(`${apiBaseUrl}/api/v1/user/AddUserToRoles`, mdroles, this.options)
  }

  addUserToWeb(mdroles: Role): Observable<string> {
    return this.http.post<string>(`${apiBaseUrl}/api/v1/user/AddUserToWebs`, mdroles, this.options)
  }

  getOrganizeList(webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetOrganizeList?webId=${webId}`, this.options)
  }

  getOrganizeListHRM(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiHrmBaseUrl}/api/v1/organize/GetOrganizations?` + queryParams, this.options)
  }

  getOrganizeTree(queryParams): Observable<any> {
    return this.http.get<any>(`${apiHrmBaseUrl}/api/v1/organize/GetOrganizeTree?` + queryParams, this.options);
  }

  getEmployeeSearch(queryParams): Observable<any> {
    return this.http.get<any>(`${apiHrmBaseUrl}/api/v2/employee/GetEmployeeSearch?` + queryParams, this.options);
  }


  getOrganizationList(): Observable<any> {
    return this.http
      .get<any>(`${apiBaseUrl}/api/v2/employee/GetOrganizationList`, this.options)
  }

  getClientWebList(filter): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetClientWebList?filter=${filter}`, this.options)
  }

  getManagerListpage(filter, webId,userRole,webRole ,gridWidth, offSet, pageSize): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerPage?filter=${filter}&webId=${webId}&userRole=${userRole}&webRole=${webRole}&gridWidth=${gridWidth}&offSet=${offSet}&pageSize=${pageSize}`, this.options)
  }

  setUsers<T>(params):  Observable<T> {
    return this.http
      .post<T>(`${apiBaseUrl}/api/v1/user/SetUser`, params, this.options)
  }

  setCreateUser<T>(params):  Observable<T> {
    return this.http
      .put<T>(`${apiCoreBaseUrl}/api/v1/coreuser/SetCreateUser`, params, this.options)
  }

  getCategoryList(webId, by_userId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetCategoryList?webId=${webId}&by_userId=${by_userId}` , this.options);
  }

  getClientWebRoleList(webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientWebRoleList?webId=${webId}` , this.options);
  }

  getManagerInfo(userId, webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerInfo?userId=${userId}&webId=${webId}` , this.options);
  }

  getCustObjectList(fieldObject): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustObjectList?fieldObject=${fieldObject}`, this.options);
  }

  getCustObjectList1(fieldObject): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustObjectList?` + fieldObject, this.options);
  }

  setManagerInfo(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetManagerInfo` , params, this.options);
  }

  getIdentityRole(): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetIdentityRole` , this.options);
  }

  getClientWebPage(filter, gridWidth, offSet, pageSize): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientWebPage?filter=${filter}&gridWidth=${gridWidth}
      &offSet=${offSet}&pageSize=${pageSize}`, this.options)
  }

  getClientWebInfo(webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientWebInfo?webId=${webId}` , this.options);
  }

  getClientWebInfoManager(webId): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientWebInfo?webId=${webId}` , this.options);
  }

  setClientWebInfo(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetClientWebInfo` , params, this.options);
  }

  setClientWebInfoManager(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/SetClientWebInfo` , params, this.options);
  }

  getManagerList(userRole): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerList?userRole=${userRole}`, this.options);
  }

  setCustProfileMeta(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/SetCustProfileMeta`, params, this.options);
  }

  delProfileMeta(metaId): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/coreuser/DelProfileMeta?metaId=${metaId}`, this.options)
  }

  delManagerInfo(userId, webId): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/coresystem/DelManagerInfo?userId=${userId}&webId=${webId}`, this.options)
  }

  getConfigTableViewPage(filter, offSet, pageSize, gridWidth, table_name): Observable<any> {
    return this.http
        .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetConfigTableViewPage?` +
          `offSet=${offSet}&` +
          `pageSize=${pageSize}&` +
          `filter=${filter}&` +
          `table_name=${table_name}&` +
          `gridWidth=${gridWidth}`, this.options);
  }

  setCustProfileFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/customer/SetCustProfileFields`, params, this.options);
  }

  getConfigFieldView(fieldId, table_name, field_name: any = ''): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetConfigFieldView?fieldId=${fieldId}&table_name=${table_name}&field_name=${field_name}` , this.options);
  }

  delClientRoleMenu(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/DelClientRoleMenu`, params , this.options);
  }

  getCategoryPage(params): Observable<any> {
    const query = queryString.stringify(params);
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetCategoryPage?${query}` , this.options);
  }

  setCategory(category): Observable<any> {
    return this.http
    .post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetCategory`, category , this.options);
  }

  setResetPassword(password): Observable<any> {
    return this.http
    .put<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetResetPassword`, password , this.options);
  }

  getBaseTypeList(): Observable<any> {
    return this.http
    .get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetBaseTypeList` , this.options);
  }

  // setClientAction(params): Observable<any> {
  //   return this.http
  //   .post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetClientAction`, params , this.options);
  // }

  getSaleGroups(webId = ''): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetAppRoleChatList?webId=${webId}` , this.options);
  }

  getClientMenuPageByWebId(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientMenuPageByWebId?` + queryParams, this.options)
  }

  getClientMenuInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientMenuInfo?` + queryParams, this.options)
  }

  delClientMenu(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/DelClientMenu?` + queryParams, this.options)
  }

  delClientAction(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/DelClientAction?` + queryParams, this.options)
  }

  setClientMenuInfo(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/SetClientMenuInfo`, params, this.options)
  }

  getClientRolePageByWebId(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientRolePageByWebId?` + queryParams, this.options)
  }

  getClientRoleInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/GetClientRoleInfo?` + queryParams, this.options)
  }

  getTableGridList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetTableGridList?` + queryParams, this.options)
  }

  getConfigColumnView(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetConfigColumnView?` + queryParams, this.options)
  }

  getConfigGridViewPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetConfigGridViewPage?` + queryParams, this.options)
  }

  getFieldColumnList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetFieldColumnList?` + queryParams, this.options)
  }

  getDepartments(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetDepartments`, this.options)
  }

  delClientRole(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/DelClientRole?` + queryParams, this.options)
  }

  delClientWebTab(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/DelClientWebTab?` + queryParams, this.options)
  }

  setClientRoleInfo(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/SetClientRoleInfo`, params, this.options)
  }

  setConfigColumnView(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetConfigColumnView`, params, this.options)
  }

  setClientAction(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/SetClientAction`, params, this.options)
  }

  setClientWebTab(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/SetClientWebTab`, params, this.options)
  }

  setConfigFieldView(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetConfigFieldView`, params, this.options)
  }

  getProjectByCd(params): Observable<any> {
    return this.http.get<any>(`${apiSreBaseUrl}/api/v1/shousing/GetProjectByCd?${params}` , this.options)
  }

}
