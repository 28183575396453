import { ApiCoreUserService } from './services/api-core/api-core-user/api-core-user.service';
import { ApiCoreCustomerService } from './services/api-core/api-core-customer/api-core-customer.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ExportFileService } from './services/export-file.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import * as $ from 'jquery';
import { HomeComponent } from './home/home.component';
import { SunshineComponent } from './sunshine/sunshine.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { FeedBaseService } from './services/firebase.service';
import { NotificationService } from './services/notification.service';
import { BasePagingComponent } from './viewcommon/base-paging/base-paging.component';
import { ButtonRendererComponent } from './viewcommon/renderer/button-renderer.component';
import { CustomDateComponent } from './viewcommon/renderer/custom-date.component';
import { BrowserModule } from '@angular/platform-browser';
import {ButtonModule} from 'primeng/button';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import {NormalButtonRendererComponent} from './viewcommon/renderer/normal-button-renderer.component';
import { ButtonRendererComponent1 } from './viewcommon/renderer/button-renderer.component-1';
import { AuthInterceptor } from './services/auth-interceptor';
import {DialogModule} from 'primeng/dialog';
import { CurrencyDirectiveModule } from './utils/common/currency.module';
import { ManagerpermissionuserComponent } from './managerpermissionuser/managerpermissionuser.component';
import { AddusertogrouproleComponent } from './managerpermissionuser/addusertogrouprole/addusertogrouprole.component';
import { ManagerpagesComponent } from './managerpages/managerpages.component';
import { ManagerinfoComponent } from './managerpages/managerinfo/managerinfo.component';
import {TabViewModule} from 'primeng/tabview';
import { WebsitepagesComponent } from './websitepages/websitepages.component';
import { WebsiterinfoComponent } from './websitepages/websiterinfo/websiterinfo.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { CommonPagingModule } from './viewcommon/renderer/common-paging.module';
import { AvatarComponent } from './viewcommon/avatar/avatar.component';
// import { AvatarComponent } from './common/avatar/avatar.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { ListfieldsinformComponent } from './listfieldsinform/listfieldsinform.component';
import { AddfieldsformComponent } from './listfieldsinform/addfieldsform/addfieldsform.component';
import {TableModule} from 'primeng/table';
import { ListrolepageComponent } from './websitepages/listrolepage/listrolepage.component';
import {CardModule} from 'primeng/card';
import {SplitButtonModule} from 'primeng/splitbutton';
import { CustomerCategoryComponent } from './customer-category/customer-category.component';
import {PickListModule} from 'primeng/picklist';
import { ButtonRendererMutiComponent } from './viewcommon/renderer/button-renderermutibuttons.component';
import { ApiCoreService } from './services/api-core/api-core-service/api-core.service';
import { ApiCoreBaseService } from './services/api-core/api-core-service/api-core-base.service';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import { SettingRoleComponent } from './setting-role/setting-role.component';
import { DetailRoleComponent } from './setting-role/detail-role/detail-role.component';
import { CommonFormModule } from './components/common-form/common-form.module';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import { AgGridModule } from '@ag-grid-community/angular';
// import {TreeSelectModule} from 'primeng/treeselect';
@NgModule({
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        HomeComponent,
        SunshineComponent,
        BasePagingComponent,
        ButtonRendererComponent,
        CustomDateComponent,
        NormalButtonRendererComponent,
        ButtonRendererComponent1,
        ManagerpermissionuserComponent,
        AddusertogrouproleComponent,
        ManagerpagesComponent,
        WebsitepagesComponent,
        ManagerinfoComponent,
        WebsiterinfoComponent,
        AvatarComponent,
        ListfieldsinformComponent,
        AddfieldsformComponent,
        ListrolepageComponent,
        CustomerCategoryComponent,
        ButtonRendererMutiComponent,
        SettingRoleComponent,
        DetailRoleComponent,
        

        // NgxLoadingModule.forRoot({}),
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        LMarkdownEditorModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireAuthModule,
        AutoCompleteModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        ToastModule,
        TooltipModule,
        ButtonModule,
        SidebarModule,
        CommonFormModule,
        DialogModule,
        MultiSelectModule,
        AutoCompleteModule,
        AgGridModule.withComponents([
            ButtonRendererComponent,
            CustomDateComponent,
            ButtonRendererComponent1,
            ButtonRendererMutiComponent,
            AvatarComponent
          ]),

        //   AgGridModule.withComponents([
        //     ButtonRendererComponent,
        //     CustomDateComponent,
        //     ButtonRendererComponent1,
        //     AvatarComponent
        //   ]),
          BrowserModule,
          ButtonModule,
          DropdownModule,
          DialogModule,
          CurrencyDirectiveModule,
          TabViewModule,
          ConfirmDialogModule,
          TabViewModule,
          CommonPagingModule,
          MultiSelectModule,
          TableModule,
        //   TreeSelectModule,
          CardModule,
          SplitButtonModule,
          PickListModule
    ],
    entryComponents: [
       NormalButtonRendererComponent, ButtonRendererComponent1],
    providers: [
        AuthService,
        AuthGuardService,
        FirebaseAuthService,
        ApiCoreService,
        ExportFileService,
        ApiService,
        FeedBaseService,
        NotificationService,
        ApiCoreUserService,
        ApiCoreCustomerService,
        MessageService,
        ConfirmationService,
        ApiCoreBaseService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
