

<div class="content-header row"  style="margin: -10px;" [hidden]="isEdit">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"  routerLink="/home"> Trang chủ
        </li>
        <!-- <li class="breadcrumb-item">
            <a routerLink="/home">Quản lý bán hàng </a>
          </li> -->
        <li class="breadcrumb-item active">Danh sách tham số (Form)
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body" [hidden]="isEdit">
  <section id="configuration">
    <div class="row">
      <div class="col-12" style="padding: 0px;">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-3 ">
                    <div class="form-group">
                      <label for="">Tìm kiếm: </label>
                      <input type="text" class="input-customize" (keydown.enter)="find()" id="filter"
                        class="form-control" [(ngModel)]="tmp.filter" placeholder="Tìm kiếm theo mô tả">
                      <!-- <i class="ft-x" (click)="cancel()" style="cursor: pointer;"></i> -->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Module: </label>
                        <select [(ngModel)]="tmp.mod_cd" name="mod_cd" (change)="getTableGridList()" class="form-control">
                            <option *ngFor="let item of moduleTables; let i=index" value={{item.objCode}}>{{item.objName}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Bảng: </label>
                        <select  id="" class="form-control" [(ngModel)]="tmp.table_name" name="table_name">
                            <option *ngFor="let item of tableGrids; let i=index" value={{item.table_name}}>{{item.table_name}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <div class="form-group">
                      <label for="" style="visibility: hidden;">Tìm kiếm: </label><br>
                      <p-button label="" icon="pi pi-search" styleClass="mr-1" iconPos="left" (click)="find()"></p-button>
                      <p-button label="" icon="pi pi-refresh" styleClass="ui-button-secondary" iconPos="left" (click)="cancelSearch()"></p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-right">
              <p-button label="Thêm mới" (click)="addFields()" icon="fa fa-plus" iconPos="left"></p-button>
              </div>

            </div>
            <a class="heading-elements-toggle">
              <i class="fa fa-ellipsis-v font-medium-3"></i>
            </a>
          </div>
        </div>

        <div class="card">
          <div class="card-content collapse show">
            <div class=" card-dashboard" style="margin-top: 10px">
              <div id="DataTables_Table_0_wrapper1" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div class="">
                  <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px" id="myGrid" class="ag-theme-balham"
                    [rowData]="items?.dataList.data" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                    [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams" [detailRowHeight]="detailRowHeight"
                    (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" floatingFilter enableCellTextSelection=true
                    [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                    [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-md-4">
                    <form>
                      Hiển thị:
                      <select name="pageSize" style="height: calc(2rem + 2px) !important;"
                        [(ngModel)]="pagingComponent.pageSize" #pagingComponent.pageSize="ngModel"
                        (change)="changePageSize()" class="">
                        <option [value]="10">10</option>
                        <option [value]="25">25</option>
                        <option [value]="50">50</option>
                        <option [value]="100">100</option>
                        <option [value]="1000000000">All</option>
                      </select>
                      <span>Từ {{ countRecord.currentRecordStart }} đến {{ countRecord.currentRecordEnd
                        }} trên tổng số
                        {{ countRecord.totalRecord }} kết quả</span>
                    </form>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4 d-flex justify-content-end">
                    <app-base-paging (pageChange)="load()"></app-base-paging>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--card-->
      </div>
    </div>
  </section>
</div>

<div [hidden]="!isEdit">
    <app-addfieldsform [data]="dataFieldsInfo" [isEditForm]="isEditForm" [moduleTables]="moduleTables" (back)="cancelpage($event)"></app-addfieldsform>
  </div>



