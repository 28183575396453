import { Injectable } from '@angular/core';
import { ApiCoreBaseService } from './api-core-base.service';
@Injectable()
export class ApiCoreService {

  constructor(
    private apiCoreService: ApiCoreBaseService) {
  }

  getConsultantPage(filter: any = '', subProdCd: any = '', userType: any = '', groupCd: any = '',
    departmentCd: any = '', gridWidth: any = 1550, offset: any = '', pageSize: any = '') {
    return this.apiCoreService.getRequest(
      `GetConsultantPage`
      + `?filter=${filter}`
      + `&sub_prod_cd=${subProdCd}`
      + `&user_type=${userType}`
      + `&group_cd=${groupCd}`
      + `&department_cd=${departmentCd}`
      + `&gridWidth=${gridWidth}`
      + `&offSet=${offset}`
      + `&pageSize=${pageSize}`);
  }

  setConsultant(data) {
    return this.apiCoreService.postRequest('SetConsultant', data);
  }

  getConsultantInfo(consultantId = '') {
    return this.apiCoreService.getRequest(`GetConsultantInfo?consultant_id=${consultantId}`);
  }

  getSubProductList(modCd: any = '', agentFlg: any = '') {
    return this.apiCoreService.getRequest(`GetSubProductList?mod_cd=${modCd}&agent_flg=${agentFlg}`);
  }

  getAppModules() {
    return this.apiCoreService.getRequest(`GetAppModules`);
  }

  setConsultantLock(data) {
    return this.apiCoreService.putRequest(`SetConsultantLock`, data);
  }

  delConsultant(consultant_id) {
    return this.apiCoreService.deleteRequest(`DelConsultant?consultant_id=${consultant_id}`, null);
  }
}
