import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-loading-overlay',
    template: `
   
    `,
    styles: [
        `
  .custom-date-filter a {
    position: absolute;
    right: 7px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
  }
    `
    ]
})
export class CustomDateComponent {
    private date: Date;
    private params: any;
    private picker: any;
    private bsInlineValue: string;

    agInit(params: any): void {
        this.params = params;
        console.log(params);
    }

    ngAfterViewInit(): void {
       
    }

    refresh(params?: any): boolean {
        return true;
      }

    ngOnDestroy() {
        console.log(`Destroying DateComponent`);
    }

    onDateChanged(selectedDates) {
        this.picker = {
            onChange: this.onDateChanged.bind(this),
            wrap: true
        }
        this.date = selectedDates || null;
        this.setDate(selectedDates)
        this.params.onDateChanged(this.picker);
    }

    getDate(): Date {
        return this.date;
    }

    setDate(date: Date): void {
        this.date = date || null;
        // this.picker.setDate(date);
    }

    clear() {
        console.log(this.params)
        this.bsInlineValue = '';
        this.onDateChanged('');
    }
}