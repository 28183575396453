import { Component, OnInit, ViewChild } from '@angular/core';
import { ButtonRendererComponent1 } from '../viewcommon/renderer/button-renderer.component-1';
import { AvatarComponent } from '../common/avatar/avatar.component';
import { BasePagingComponent } from '../viewcommon/base-paging/base-paging.component';
import * as moment from 'moment';
import { AgGridFn } from '../utils/common/function-common';
import { ApiService } from '../services/api.service';
import { MessageService} from 'primeng/api';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
declare var jQuery: any;
@Component({
  selector: 'app-customer-category',
  templateUrl: './customer-category.component.html',
  styleUrls: ['./customer-category.component.css']
})
export class CustomerCategoryComponent implements OnInit {
  @ViewChild(BasePagingComponent, { static: true }) pagingComponent: BasePagingComponent;
  public modules: Module[] = AllModules;
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
  ) {
    this.frameworkComponents = {
      buttonRenderer1: ButtonRendererComponent1,
      avatarRenderer: AvatarComponent,
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 40;
      } else {
        return 350;
      }
      },
      this.defaultColDef = {
        resizable: true,
      };
   }

  public frameworkComponents;
  public gridApi;
  public rowSelection;
  public gridApiItemMap;
  public domLayout;
  public gridColumnApi;
  public groupDefaultExpanded;
  public columnDefs;
  public defaultColDef;
  public items = [];
  public moduleTables: any = []
  public detailRowHeight;
  public detailCellRendererParams;
  public getRowHeight: any;
  gridflexs: any;
  countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  objectAction: any;
  clientWidth = 0;
  params = {
    filter: '',
    gridWidth: '',
    offSet: 0,
    pageSize: 10,
    base_type: 0
  };
  isEdit = false;
  visible = false;
  modelCategory = {
    base_type: 0,
    categoryCd: '',
    categoryName: '',
    isAll: false,
    webId: ''
  };
  baseTypeList = [];
  listWebs = [];

  ngOnInit(): void {
    this.getCategoryPage();
    this.getBaseTypeList();
    this.getClientWebList();
  }

  getCategoryPage() {
    const offSet = this.pagingComponent.getOffset();
    const pageSize = this.pagingComponent.pageSize;
    this.params.gridWidth = jQuery('#myGrid')[0].clientWidth;
    this.params = {...this.params, offSet, pageSize};
    this.apiService.getCategoryPage(this.params)
    .subscribe(result => {
      this.items = result.data.dataList.data;
      if (offSet === 0) {
        this.gridflexs = result.data.gridflexs;
        this.gridflexs.forEach(column => {
          if (column.columnField === 'actions') {
            this.objectAction = column;
          }
        });
      }
      if (this.gridApi) {
        this.gridApi.setRowData(result.data.dataList.data);
      }
      this.onInitTable();
      this.pagingComponent.total = result.data.dataList.recordsTotal;
      this.countRecord.totalRecord = result.data.dataList.recordsTotal;
      this.countRecord.currentRecordStart = offSet + 1;
      if ((result.data.dataList.recordsTotal - offSet) > pageSize) {
        this.countRecord.currentRecordEnd = offSet + Number(pageSize);
      } else {
        this.countRecord.currentRecordEnd = result.data.dataList.recordsTotal;
      }
    });
  }

  getClientWebList() {
    this.apiService.getClientWebList('').subscribe(res => {
      this.listWebs = res.data;
    });
  }

  getBaseTypeList() {
    this.apiService.getBaseTypeList().subscribe(result => {
      this.baseTypeList = result.data;
    })
  }

  load() {
    this.getCategoryPage();
  }

  addCategory() {
    this.visible = true;
    this.isEdit = false;
    this.modelCategory = {
      base_type: 0,
      categoryCd: '',
      categoryName: '',
      isAll: false,
      webId: ''
    };
  }

  saveCategory() {
    this.apiService.setCategory(this.modelCategory)
    .subscribe(result => {
      if (result.status === 'success') {
        this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: this.isEdit ? 'Sửa phân loại khách hàng thành công !' : 'Thêm phân loại khách hàng thành công !'});
      }
    });
  }

  cancel() {
    this.visible = false;
    this.isEdit = false;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  changePageSize() {
    this.load();
  }

  onInitTable() {
    this.columnDefs = [
      ...AgGridFn(this.gridflexs),
      {
        headerName: 'Thao tác', field: 'button', filter: '', cellRenderer: 'buttonRenderer1',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: this.objectAction ? this.objectAction.columnWidth : 100,
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handleEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-dropbox shadow',
                show: true
              },
            ]
          };
        },
      },
    ];
  }

  handleEdit(e) {
    const { base_type, categoryCd , categoryName , isAll , webId } = e.rowData;
    this.modelCategory = { base_type, categoryCd , categoryName , isAll , webId };
    this.isEdit = true;
    this.visible = true;
  }

  cancelSearch() {
    this.params = {
      filter: '',
      gridWidth: '',
      offSet: 0,
      pageSize: 10,
      base_type: 0
    };
  }

}
