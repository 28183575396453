import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { UserManager } from 'oidc-client';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    private manager: UserManager = new UserManager(environment.authenSettings);
    constructor(
        private apiService: ApiService,
        ) {
    }

    ngOnInit() {
        $.getScript('./assets/app/js/core/app-menu.js');
        $.getScript('./assets/app/js/core/app.js');
       
        if (localStorage.getItem('menuItems')) {
            this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
            this.menuItems = this.menuItems.filter(menuItem => menuItem);
          } else {
            this.manager.getUser().then(user => {
                this.apiService.getListMenuByUserId(user.profile.sub, 'F64B0750-2B1D-4011-89FF-36642C1D65BE').subscribe(results => {
                    this.menuItems = results.data.filter(menuItem => menuItem);
                    localStorage.setItem('menuItems',JSON.stringify(results.data));
                })
              });
          }
    }

}
