import { DetailRoleComponent } from './setting-role/detail-role/detail-role.component';
import { SettingRoleComponent } from './setting-role/setting-role.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

/**sunshine component */
import { SunshineComponent } from './sunshine/sunshine.component';
import { HomeComponent } from './home/home.component';
import { ManagerpermissionuserComponent } from './managerpermissionuser/managerpermissionuser.component';
import { ManagerpagesComponent } from './managerpages/managerpages.component';
import { WebsitepagesComponent } from './websitepages/websitepages.component';
import { ListfieldsinformComponent } from './listfieldsinform/listfieldsinform.component';
import { CustomerCategoryComponent } from './customer-category/customer-category.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  /**Sunshine route */
  {
    path: '', component: SunshineComponent,
    data: { title: '' }, children: [
      { path: 'home', component: HomeComponent },
      { path: 'managerpermissionuser', component: ManagerpermissionuserComponent }
      , { path: 'managerpage', component: ManagerpagesComponent }
      , { path: 'managerwebpage', component: WebsitepagesComponent }
      , { path: 'listfieldsinform', component: ListfieldsinformComponent }
      , { path: 'categorypage', component: CustomerCategoryComponent }
      , { path: 'setting-role', component: SettingRoleComponent }
      , { path: 'setting-role/:id', component: DetailRoleComponent }
      , {
        path: 'listfieldsagird',
        loadChildren: () => import('./manage-fields/manage-fields.module').then(m => m.ManageFieldsModule)
      },
      {
        path: 'consultantpage',
        loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule)
      }
    ], canActivate: [AuthGuardService]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
