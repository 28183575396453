
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var jQuery: any;
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/api-core/api-core-service/config.service';
import { ApiCoreServiceService } from 'src/app/services/api-core/api-core-service/api-core-service.service';
import { FieldsForm } from 'src/app/types/fieldsform';
import { MessageService, SelectItem } from 'primeng/api';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as queryString from 'querystring';
const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado'];
@Component({
  selector: 'app-addfieldsform',
  templateUrl: './addfieldsform.component.html',
  styleUrls: ['./addfieldsform.component.css'],
  providers: [ConfigService, ApiCoreServiceService]
})
export class AddfieldsformComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() isEditForm: any;
  @Input() moduleTables: any;
  @Output() back = new EventEmitter<any>();
  public model: any;
  public loading = false;
  dataFieldsInfo : any
  tableGrids : any
  columnFieldList: any;
  objectFields: any = {};
  cars: SelectItem[];
  multiSelects: any[] = [];
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private router: Router,
    private apiConfigService: ConfigService
  ) {

    this.cars = [
      {label: 'Audi', value: 'Audi'},
      {label: 'BMW', value: 'BMW'},
      {label: 'Fiat', value: 'Fiat'},
      {label: 'Ford', value: 'Ford'},
      {label: 'Honda', value: 'Honda'},
      {label: 'Jaguar', value: 'Jaguar'},
      {label: 'Mercedes', value: 'Mercedes'},
      {label: 'Renault', value: 'Renault'},
      {label: 'VW', value: 'VW'},
      {label: 'Volvo', value: 'Volvo'},
  ];
   }
  metas = [];
  submit: boolean = false;
  filter = {
    mod_cd: '',
    table_name: '',
    column_name: ''
  };

  modelFields:Partial<FieldsForm> = {
    columnClass: "",
    columnLabel: "",
    columnObject: "",
    columnTooltip: "",
    columnType: "",
    columnValue: "",
    data_type: "",
    field_name: "",
    group_cd: "",
    isChange: false,
    isDisable: true,
    isRequire: false,
    isSpecial: false,
    isVisiable: true,
    originalValue: null
  }

  ngOnInit() {

  }

  getTableGridList(value) {
      // import * as queryString from 'querystring';
      const queryParams = queryString.stringify({ modCd: value, tableType: 'table'});
      this.apiService.getTableGridList(queryParams).subscribe(results => {
      if(results.status === 'success') {
        this.tableGrids = results.data;
      }
    })
  }

  getFieldColumnList(e) {
    const queryParams = queryString.stringify({ modCd: this.filter.mod_cd, tableName: this.filter.table_name});
    this.apiService.getFieldColumnList(queryParams).subscribe(results => {
      if(results.status === 'success') {
        this.columnFieldList = results.data;
      }
    })
  }

  ngOnChanges() {
    console.log(this.isEditForm)
    this.dataFieldsInfo = null;
    if(this.data && this.data.id > 0) {
      this.callApiFields(this.data.id, '', '');
      this.dataFieldsInfo = null;
      this.filter = {
        mod_cd: '',
        table_name: '',
        column_name: ''
      };
    }
  }

  handleFilter() {
    if(this.data && this.data.id > 0) {
    } else {
      this.callApiFields(0, this.filter.table_name, this.filter.column_name);
    }
  }

  callApiFields(id, table_name, field_name) {
    this.apiService.getConfigFieldView(id, table_name, field_name).subscribe(results => {
      if(results.status === 'success') {
        this.dataFieldsInfo = results.data;
        if (this.dataFieldsInfo && this.dataFieldsInfo.group_fields && this.dataFieldsInfo.group_fields.length > 0) {
          this.dataFieldsInfo.group_fields.forEach(groupChildren => {
            groupChildren.fields.forEach(data => {
              data.field_name = `${data.field_name}-1`
              data[`${data.field_name}`] = data.columnValue;
              if (data.data_type === 'datetime') {
                data[`${data.field_name}`] = data.columnValue ? new Date(this.convestDate(data.columnValue)) : '';
              }
              if (data.columnType === 'select') {
                this.apiService.getCustObjectList(data.columnObject).subscribe(results => {
                  data.options = results.data;
                })
              }
            });
          })
        }
        console.log(this.dataFieldsInfo)
      }
    })
  }

  convestDate(data) {
    console.log(data)
    if (data) {
      let date1s = data.split('/');
      return `${date1s[2]}-${date1s[1]}-${date1s[0]}`
    }
  }

  backpage() {
    this.back.emit("back")
  }


  saveCustomer() {
    this.loading = true;
    this.submit = true;
    this.dataFieldsInfo.group_fields.forEach(results => {
      results.fields.forEach(data => {
        if (data.data_type === 'datetime') {
          data.columnValue = data[data.field_name] ? moment(data[data.field_name]).format("DD/MM/YYYY") : null;
          delete data[data.field_name];
        } else {
          data.columnValue = data[data.field_name];
          delete data[data.field_name];
        }
        if ((data.columnType === 'select') && data.options) {
          delete data.options;
        }
        data.field_name = data.field_name.split("-")[0]
      })
    });
    setTimeout(() => {
      const params = {
        id: this.dataFieldsInfo.id,
        group_fields: this.dataFieldsInfo.group_fields,
      };

      this.apiService.setConfigFieldView(params)
      .subscribe(result => {
        if (result.status === 'success') {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Cập nhật tham số thành công'});
          this.back.emit("success");
        }
      });

    }, 50);

  }

  onUploadOutput(event, dataParent) {
    if (event.target.files[0] && event.target.files[0].size > 0) {
      const getDAte = new Date();
      const getTime = getDAte.getTime();
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`housing/avatars/${getTime}-${event.target.files[0].name}`).put(event.target.files[0]);
      uploadTask.on('state_changed', (snapshot) => {
      }, (error) => {
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (downloadURL) {
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Upload ảnh thành công'});
            dataParent.fields.forEach(data => {
              if (data.columnType === 'image') {
                data[data.field_name] = downloadURL;
              }
            })
          }
        });
      });
    }
  }

  deleteAvatar(dataParent, field_name) {
    dataParent.fields.forEach(data => {
      if (data.field_name === field_name) {
        data[data.field_name] = '';
      }
    });
  }
  viewDemo() {
     for(let obj of this.dataFieldsInfo.group_fields[0].fields) {
       console.log(obj.field_name)
      this.objectFields[obj.field_name.split('-')[0]] = obj[obj.field_name] === '0' ? false : obj[obj.field_name] === '1' ? true : obj[obj.field_name];
     }
     console.log(this.objectFields)
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? []
      : states.filter(v => v.toLowerCase().startsWith(term.toLocaleLowerCase())).splice(0, 10))
  )
}









