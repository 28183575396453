
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AgGridFn } from 'src/app/utils/common/function-common';
import { Component, OnInit, AfterViewChecked, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, flatMap, switchMap } from 'rxjs/operators';
import { PagingComponent } from '../viewcommon/renderer/paging/paging.component';
import { AvatarComponent } from '../viewcommon/avatar/avatar.component';
import { ButtonRendererComponent1 } from 'src/app/viewcommon/renderer/button-renderer.component-1';
import { ButtonRendererMutiComponent } from '../viewcommon/renderer/button-renderermutibuttons.component';
import { NgForm } from '@angular/forms';
import { FeedBaseService } from '../services/firebase.service';
import * as queryString from 'querystring';
declare var jQuery: any;
import * as firebase from 'firebase';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFirestore } from '@angular/fire/firestore';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { ApiCoreUserService } from '../services/api-core/api-core-user/api-core-user.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-managerpages',
  templateUrl: './managerpages.component.html',
  styleUrls: ['./managerpages.component.css'],
})
export class ManagerpagesComponent implements OnInit, AfterViewChecked {
  public modules: Module[] = AllModules;
  public agGridFn = AgGridFn;
  public detailManager;
  public organizes;
  public isEdit = false;
  public moduleTables;
  public columnDefs = [];
  public key_car;
  public listCars: any[] = [];
  public detailRowHeight;
  public defaultColDef;
  public frameworkComponents;
  public groupDefaultExpanded;
  public detailCellRendererParams;
  public gridApi: any;
  public clientWidth: any;
  public gridColumnApi: any;
  public objectAction: any;
  public items: any;
  public gridflexs: any;
  public getRowHeight;
  public pageSize = 25;
  public loading = false;
  public currentPage = 0;
  confimPassword = false;
  modelPass = {
    userLogin: '',
    userPassword: '',
    userPassCf: ''
  }
  displayChangPassword = false;
  submitPass = false;
  public filter = {
    filter: '',
    webId: '6F84B537-05F2-4B11-A891-67B4EF1847DC',
    userRole: 0,
    webRole: ''
  };
  public filterModal = {
    webId: '',
    perentId: null,
    orgId: null,
    employeeId: ''
  };
  userRoles = [
    { label: 'Tất cả', value: 0 },
    { label: 'Quản lý', value: 1 },
    { label: 'Chuyên viên', value: 2 },
    { label: 'Người dùng', value: 3 },
  ]
  public countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  public webType: any = [];
  @ViewChild(PagingComponent, { static: true }) pagingComponent: PagingComponent;
  public listWebs: any = [];
  public modelUser = {
    custId: '',
    loginName: '',
    password: '',
    userPasswordconfirm: '',
  };
  public gridflexdetails;
  public objectActionDetails;
  public seachManager;
  public managerDetails
  public gridApi1
  public gridColumnApi1
  public columnDefs1
  public getRowHeight1
  public managerInfoList = [];
  public detailcar
  public hideForm = true;
  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private db: AngularFirestore,
    private service: FeedBaseService,
    private apiCoreUserService: ApiCoreUserService,
    private apiService: ApiService) {
    this.defaultColDef = {
      resizable: true,
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 80;
      } else {
        return 400;
      }
    }
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent1,
      avatarRenderer: AvatarComponent,
      buttonRendererMuti: ButtonRendererMutiComponent,
    };
  }

  ngOnInit() {
    this.getOrganize();
    this.getClientWebList();
    this.getClientWebRoleList();

    this.clientWidth = jQuery('#myGrid')[0].clientWidth;
    this.loading = true;
    this.getConfigs();
    this.pagingComponent.pageSize = this.pageSize;
    this.loading = false;
  }
  webRoleListsMuti
  getClientWebRoleList() {
    this.apiService.getClientWebRoleList(this.filter.webId).subscribe(results => {
      if (results.status === 'success') {
        this.webRoleListsMuti = results.data.map(data => {
          return {
            label: data.roleName,
            value: data.roleId
          }
        });
        this.webRoleListsMuti = [{ label: 'Tất cả', value: '' }, ...this.webRoleListsMuti]
      }
    })
  }

  getClientWebList() {
    this.apiService.getClientWebList('').subscribe(res => {
      this.listWebs = res.data.map(d => {
        return {
          label: d.webName,
          value: d.webId
        }
      });
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  selectedItem1(event) {
    this.detailcar = event;
    this.callApi(event.phone, null)
  };

  getConfigs() {
    this.loading = true;
    this.apiService.getManagerListpage(this.filter.filter, this.filter.webId, this.filter.userRole, this.filter.webRole, this.clientWidth,
      this.currentPage, this.pageSize)
      .subscribe(res => {
        this.items = res.data;
        console.log(this.items)
        if (this.currentPage === 0) {
          this.gridflexs = this.items.gridflexs;
          this.gridflexdetails = this.items.gridflexdetails;
          this.gridflexs.forEach(column => {
            if (column.columnField === 'actions') {
              this.objectAction = column;
            }
          });
          this.gridflexdetails.forEach(column => {
            if (column.columnField === 'actions') {
              this.objectActionDetails = column;
            }
          });
        }
        // if (this.gridApi) {
        //   this.gridApi.setRowData(this.items.dataList.data);
        // }
        this.initTableGrid();
        this.pagingComponent.total = this.items.dataList.recordsTotal;
        this.countRecord.totalRecord = this.items.dataList.recordsTotal;
        this.countRecord.currentRecordStart = this.currentPage + 1;
        if ((this.items.dataList.recordsTotal - this.currentPage) > this.pageSize) {
          this.countRecord.currentRecordEnd = this.currentPage + Number(this.pageSize);
        } else {
          this.countRecord.currentRecordEnd = this.items.dataList.recordsTotal;
        }
        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }

  handleChangePaging(name) {
    this.currentPage = this.pagingComponent.getOffset();
    this.pageSize = this.pagingComponent.pageSize;
    this.getConfigs();
  }

  handleFilter() {
    this.currentPage = 0;
    this.getConfigs();
  }

  initTableGrid() {
    this.columnDefs = [
      ...this.agGridFn(this.gridflexs),
      {
        headerName: '', field: 'button', filter: '',
        cellRenderer: 'buttonRendererMuti',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: 60,
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handleEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-dropbox shadow',
              },
              {
                onClick: this.lockUser.bind(this),
                label: 'Khóa người dùng',
                icon: 'pi pi-lock',
                class: 'btn-dropbox shadow',
                hide: params.data.lock_st === 1
              },
              {
                onClick: this.unLockUser.bind(this),
                label: 'Mở khóa khách hàng',
                icon: 'pi pi-lock-open',
                class: 'btn-dropbox shadow',
                hide: ((params.data.lock_st === 0) || (params.data.lock_st === null))
              },
              {
                onClick: this.handleEditPassword.bind(this),
                label: 'Thay đổi mật khẩu',
                icon: 'fa fa-edit',
                class: 'btn-dropbox shadow',
              },
              {
                onClick: this.handleDelete.bind(this),
                label: 'Xóa',
                icon: 'pi pi-trash',
                class: 'btn-danger shadow',
              },
            ]
          };
        }
      },
    ];
    this.groupDefaultExpanded = 0;
    this.detailRowHeight = 400;
    this.detailCellRendererParams = {
      detailGridOptions: {
        // domLayout : "autoHeight",
        frameworkComponents: {
          buttonRenderer1: ButtonRendererMutiComponent
        },
        getRowHeight: (params) => {
          return 60;
        },
        columnDefs: [
          ...this.agGridFn(this.gridflexdetails),
          {
            headerName: 'Chức năng',
            field: 'button',
            filter: '',
            pinned: this.objectActionDetails ? this.objectActionDetails.pinned : "",
            width: this.objectActionDetails ? this.objectActionDetails.columnWidth : 100,
            cellRenderer: 'buttonRenderer1',
            cellClass: this.objectActionDetails ? this.objectActionDetails.cellClass : ['border-right'],
            cellRendererParams: params => {
              return {
                buttons: [
                  {
                    onClick: this.handleEdit.bind(this),
                    label: 'Sửa',
                    icon: 'fa fa-edit',
                    class: 'btn-dropbox shadow',
                  },
                  {
                    onClick: this.handleEditPassword.bind(this),
                    label: 'Thay đổi mật khẩu',
                    icon: 'fa fa-edit',
                    class: 'btn-dropbox shadow',
                  },
                  {
                    onClick: this.handleDelete.bind(this),
                    label: 'Xóa',
                    icon: 'pi pi-trash',
                    class: 'btn-danger shadow',
                  },
                ]
              }
            },
          },
        ],
      },
      getDetailRowData: function (params) {
        params.successCallback(params.data.coreUsers);
      }
    };
  }

  handleEditPassword(event) {
    this.modelPass.userLogin = event.rowData.loginName;
    this.modelPass.userPassword = "";
    this.modelPass.userPassCf = "";
    this.displayChangPassword = true;


  }
  checkPasswordcf() {
    if (this.modelPass.userPassword === this.modelPass.userPassCf) {
      this.confimPassword = false;
    } else {
      this.confimPassword = true;
    }
  }

  saveChangePass(form: NgForm) {
    this.submitPass = true;
    if (form.invalid || this.confimPassword) {
      return;
    }
    const params = { ... this.modelPass };
    delete params.userPassCf
    this.apiService.setResetPassword(params).subscribe(results => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Sửa thông tin tài khoản thành công !' });
        this.displayChangPassword = false;
      }
    })

  }

  lockUser(event) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn khóa người dùng?',
      accept: () => {
        const queryParams = { userId: event.rowData.userId }
        this.apiCoreUserService.lockUser(queryParams).subscribe(response => {
          if (response.status === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Đã khóa người dùng' });
            this.getConfigs();
          }
        })
      }
    });
  }

  unLockUser(event) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn mở khóa người dùng ?',
      accept: () => {
        // this.spinner.show();
        const queryParams = { userId: event.rowData.userId }
        this.apiCoreUserService.unLockUser(queryParams).subscribe(response => {
          if (response.status === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Đã mở khóa người dùng' });
            this.getConfigs();
            // this.load();
            // this.spinner.hide();
          }
        })
      }
    });

  }

  handleDelete(e) {
    if (confirm('Bạn chắc chắn muốn xóa tài khoản này khỏi danh sách quản trị')) {
      this.apiService.delManagerInfo(e.rowData.userId, this.filter.webId).subscribe(result => {
        if (result.status === 'success') {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa thành công !' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: result.message });
        }
      })
    }
  }
  handleEdit(e) {
    this.detailManager = e.rowData;
    this.detailManager.webId = this.filter.webId;
    this.isEdit = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // if (this.items && this.items.dataList.data && this.items.dataList.data.length > 0) {
    //   this.gridApi.setRowData(this.items.dataList.data);
    // }
  }

  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  handleChangePageSize(event) {
    this.pageSize = +event.target.value;
    this.pagingComponent.pageSize = +event.target.value;
    this.getConfigs();
  }

  cancelSearch() {
    this.filter.filter = ''
    this.getConfigs();
  }
  results = []
  search(event): void {

    this.apiService.getEmployeeSearch(
      queryString.stringify({ filter: event.query, orgId: this.filterModal.perentId })
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(t => of(t))
      )
      .subscribe((res: any) => {
        this.results = res.data.map(item => ({
          name: item.fullName + '-' + item.phone || item.employeeName + '-' + item.phone, code: item.employeeId, custId: item.custId, phone: item.phone, email: item.email, departmentName: item.departmentName
        }));
        console.log('result:', this.results);
      });
  }


  onback() {
    this.isEdit = false;
    this.getConfigs();
  }

  handleAddManager() {
    this.seachManager = true;
    this.filterModal = {
      webId: '',
      perentId: null,
      orgId: null,
      employeeId: ''
    };
    this.detailcar = null;
    this.key_car = null;
    this.managerDetails = [];
    this.detailManager = null;
    this.hideForm = true;
  }
  organizationCd
  getCardCustomers() {
    this.apiService.getEmployeeList(this.key_car, '', -1, -1, 0, this.organizationCd, null, null).subscribe((result: any) => {
      this.listCars = result.data;
      this.listCars.forEach(data => data.fullNameAndCar = `${data.phone}---${data.email}`);
    });
  }


  callApi(custId, userLogin) {
    this.spinner.show();
    this.apiService.getUserSearchPage(custId).subscribe((result: any) => {
      if (result.status === 'success') {
        this.gridApi1.setRowData(result.data.dataList.data);
        this.managerInfoList = result.data.dataList.data;
        this.initAgrid();
        this.spinner.hide();
      }else {
        this.spinner.hide();
      }
    });
  }

  initAgrid() {
    this.columnDefs1 = [
      {
        headerName: 'Tên đăng nhập',
        field: 'loginName',
        resizable: true,
        cellClass: ['border-right']
      },
      {
        headerName: 'Số điện thoại',
        field: 'phone',
        resizable: true,
        cellClass: ['border-right']
      },
      {
        headerName: 'Email',
        field: 'email',
        resizable: true,
        cellClass: ['border-right']
      },

      {
        headerName: 'Thao tác',
        field: 'button',
        filter: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: (params) => this.showbuton(params)
      },]
    this.getRowHeight1 = params => {
      return 40;
    }
  }

  showbuton(e) {
    return {
      buttons: [
        {
          onClick: this.checkSquare.bind(this),
          label: 'Chọn',
          icon: 'fa fa-check-square-o',
          class: 'btn-primary mg-5',
          show: true
        },
        {
          onClick: this.lockCard.bind(this),
          label: 'Khóa tài khoản',
          icon: 'fa fa-lock',
          class: 'btn-warning mg-5',
          show: !e.data.isLock
        },
        {
          onClick: this.unlockCard.bind(this),
          label: 'Mở khóa tài khoản',
          icon: 'fa fa-unlock',
          class: 'btn-warning mg-5',
          show: e.data.isLock
        },
        // && e.data.app_user === 'webmanager'
        {
          onClick: this.deleteCard.bind(this),
          label: 'Xóa',
          icon: 'pi pi-trash',
          class: 'btn-danger',
          show: e.data.isLock && e.data.app_user === 'webmanager'
        },
      ]
    }
  }

  arrayThreadsIds = [];
  lockCard(e) {
    this.arrayThreadsIds = [];
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện hành động này không?',
      accept: () => {
        this.apiService.lockUser(e.rowData.userId).subscribe(results => {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Khóa tài khoản thành công' });
          this.callApi(this.detailcar.phone, this.modelUser.loginName);
        }, error => { });
      }
    });
  }

  unlockCard(e) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện hành động này không?',
      accept: () => {
        this.apiService.unLockUser(e.rowData.userId).subscribe(results => {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Mở khóa tài khoản thành công' });
          this.callApi(this.detailcar.phone, this.modelUser.loginName);
        }, error => { });
      }
    });
  }

  deleteCard(e) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện hành động này không?',
      accept: () => {
        this.apiService.deleteUserCust(e.rowData.userId).subscribe(results => {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa tài khoản thành công' });
          this.service.getThreadsIdInUsers('users', e.rowData.userId).then((ketqua: any) => {
            const arrayThreadsIds = [];
            ketqua.forEach(element1 => {
              arrayThreadsIds.push(element1.id);
            });
            return arrayThreadsIds
          }).then(dataIds => {
            if (dataIds.length > 0) {
              const promise = [];
              const batchArray = [];
              batchArray.push(this.db.firestore.batch());
              let operationCounter = 0;
              let batchIndex = 0;
              for (let id of dataIds) {
                const remove = this.db.firestore.collection('threads').doc(id).collection('users').doc(e.rowData.userId);
                batchArray[batchIndex].delete(remove);
                operationCounter++;
                if (operationCounter === 499) {
                  batchArray.push(this.db.firestore.batch());
                  batchIndex++;
                  operationCounter = 0;
                }
              }
              batchArray.forEach(async batch => await batch.commit());
              this.service.deleteUserId('users', e.rowData.userId).catch(error => { });
              this.callApi(this.detailcar.phone, this.modelUser.loginName);
            } else {
              this.service.deleteUserId('users', e.rowData.userId).catch(error => { });
              this.callApi(this.detailcar.phone, this.modelUser.loginName);
            }
          });
        }, error => { });
      }
    });
  }

  checkSquare(e) {
    if (!this.filterModal.webId) {
      alert("Bạn chưa chọn Trang web: ");
      return;
    }
    this.detailManager = e.rowData;
    this.detailManager.webId = this.filterModal.webId;
    this.seachManager = !this.seachManager;
    this.isEdit = true;
  }

  addUser() {
    this.hideForm = false;
    setTimeout(() => {
      jQuery("#userLogin").focus();
    }, 50);
    this.modelUser = {
      custId: this.detailcar.custId,
      loginName: '',
      password: '',
      userPasswordconfirm: '',
    };
    this.organizationCd = ''
  }

  saveUser() {
    if (this.managerInfoList && this.managerInfoList.length) {
      if (this.managerInfoList.map(function (e) { return e.userLogin }).indexOf(this.modelUser.loginName) > -1) {
        alert('Tài khoản của bạn đã tồn tại!');
        return;
      }
    }
    if (!this.modelUser.password || !this.modelUser.userPasswordconfirm ||
      (this.modelUser.password != this.modelUser.userPasswordconfirm)) {
      alert('Mật khẩu không đúng!');
      return;
    }
    delete this.modelUser.userPasswordconfirm;
    this.apiService.setCreateUser(this.modelUser).subscribe((results: any) => {
      if (results.status === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Tạo tài khoản đăng nhập thành công' });
        this.callApi(this.detailcar.phone, this.modelUser.loginName);
        if (results.data) {
          const data = {
            authentication_id: 'web_s_user_prod',
            auths: [this.detailcar.phone, this.modelUser.loginName],
            created_date: firebase.firestore.FieldValue.serverTimestamp(),
            customer_id: this.modelUser.custId,
            last_active: firebase.firestore.FieldValue.serverTimestamp(),
            meta: {
              avatar: this.detailcar.avatarUrl || null,
              email: this.detailcar.email || null,
              name: `${this.detailcar.fullName} (${this.modelUser.loginName})`,
              phone: this.detailcar.phone || null
            },
            online: true,
            permissions: [],
            roles: [],
          }
          this.service.createDocumentWithName('users', results.data, data)
            .then(() => {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'update user trên firebase thành công' });
              this.hideForm = !this.hideForm;

            })
            .catch((error) => {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'update user trên firebase Thất bại' });
            });
        }

      }
    }, error => { });
  }


  getOrganize(): void {
    const queryParams = queryString.stringify({ filter: '' });
    this.apiService.getOrganizeListHRM(queryParams)
      .subscribe(
        (results: any) => {
          this.organizes = results.data
            .map(d => {
              return {
                label: d.organizationName || d.organizationCd,
                value: d.organizeId
              };
            });
        },
        error => { });
  }

  departmentFiltes = []
  getOrganizeTree(value): void {
    const queryParams = queryString.stringify({ parent_id: value });
    this.apiService.getOrganizeTree(queryParams)
      .subscribe((results: any) => {
        if (results && results.status === 'success') {
          this.departmentFiltes = results.data || [];
        }
      },
        error => { });
  }

  excelStyles = [
    {
      id: 'stringType',
      dataType: 'string'
    },
    {
      id: 'dateType',
      dataType: 'dateTime'
    },
    {
      id: 'numberType',
      dataType: 'number'
    }
  ];

  exportGrid() {
    this.gridApi.exportDataAsExcel();
  }

  getContextMenuItems(params) {
    var result = [
      'copy',
      'paste',
      'separator',
    ];
    return result;
  }

}

