


<div class="content-header row" style="margin: -10px;" [hidden]="isEdit">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/home"><a><i class="fa fa-home"></i> Trang chủ</a>
        </li>
        <li class="breadcrumb-item active">Danh sách website
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body" [hidden]="isEdit">
  <section id="configuration">
    <div class="row">
      <div class="col-12" style="padding: 0px;">
        <div class="card">
          <div class="card-header">
            <div class="row ">
                <div class="col-md-2 w-100">
                    <fieldset class="form-group">
                        <label>Nội dung </label><br>
                        <input placeholder="Nhập nội dung tìm kiếm..." class="form-control shadow-item" name="filter" id="filter"
                               [(ngModel)]="filter.filter" />
                    </fieldset>
                </div>
                <div class="col-md-2 w-100">
                    <fieldset class="form-group">
                        <label style="visibility: hidden;">dddd </label><br />
                        <p-button label="" icon="pi pi-search" styleClass="mr-1" iconPos="left" (click)="handleFilter()"></p-button>
                        <p-button label="" icon="pi pi-refresh" styleClass="ui-button-secondary" iconPos="left" (click)="cancelSearch()"></p-button>
                    </fieldset>
                </div>
                <div class="col-md-8 text-right"> 
                    <label style="visibility: hidden;">ddddddd</label><br />
                    <p-button label="Thêm mới" (click)="handleAddWeb()" icon="fa fa-plus"></p-button>
                </div>
            </div>
          </div>
      
        </div>

        <div class="card">
          <div class="card-content collapse show">
            <div class=" card-dashboard" style="margin-top: 10px">
              <div id="DataTables_Table_0_wrapper1" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div class="" >
                    <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid
                    style="width: 100%; height: 700px"
                    id="myGrid"
                    class="ag-theme-balham shadow"
                    [rowData]="items?.dataList.data"
                    [columnDefs]="columnDefs"
                    floatingFilter enableCellTextSelection=true
                    [masterDetail]="true"
                    [detailCellRendererParams]="detailCellRendererParams"
                    (gridReady)="onGridReady($event)"
                    [defaultColDef]="defaultColDef"
                    [groupUseEntireRow]="true"
                    [animateRows]="true"
                    [frameworkComponents]="frameworkComponents"
                    [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
              </div>

        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4">
            <form>
              Hiển thị:
              <select class="dropdown-custom shadow-border" [(ngModel)]="pageSize" name="pageSize"
                                    (change)="handleChangePageSize($event)">
                                <option [value]="5">5</option>
                                <option [value]="10">10</option>
                                <option [value]="25">25</option>
                                <option [value]="50">50</option>
                                <option [value]="100">100</option>
                                <option [value]="1000000000">All</option>
                            </select>
                            <span>Từ {{ countRecord.currentRecordStart }} đến {{ countRecord.currentRecordEnd }}
                                trên tổng số
                                {{ countRecord.totalRecord }} kết quả</span>
            </form>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4 d-flex justify-content-end">
            <app-paging (pageChange)="handleChangePaging($event)"></app-paging>
          </div>

        </div>
                </div>
              </div>
          </div>



        </div>
    
      </div>
    </div>
  </section>
</div>

<app-websiterinfo [hidden]="!isEdit" (back)="onback()" [dataFieldsInfo]="dataFieldsInfo"></app-websiterinfo>

<!-- <p-dialog [(visible)]="seachManager" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <p-header >
      Tìm kiếm Tài khoản 
  </p-header>
    <form action="" #manager="ngForm">
        <div class="row mb-1">
            <div class="col-md-3">
                <label for="">Trang Web: <span style="color:red">*</span></label>
                    <select class="form-control" id="webId" [(ngModel)]="filterModal.webId" name="webId">
                        <option value="" disabled>Chọn loại Website</option>
                        <option value="{{item.webId}}" *ngFor="let item of listWebs; let i= index">{{item.webName}}</option>
                    </select>
            </div>
            <div class="col-md-6">
                <label for="">Search số điện thoại: </label>
                        <input [ngbTypeahead]="search" (keyup)="getCardCustomers()" name="key_car"
                          style="padding: 19px;height: calc(2.3rem + 2px)" placeholder="Nhập số điện thoai để tìm kiếm" [resultFormatter]="formatter"
                          (selectItem)="selectedItem1($event)" [inputFormatter]="formatter" required value=""
                          type="text" [(ngModel)]="key_car" id="key_car" class="form-control w-100">
                        <span *ngIf="key_car" (click)="cancel()"><i class="sun-icon-x icon-input-search"></i></span>
            </div>
            <div class="col-md-3">
                <label for="" style="visibility: hidden;">Thêm mới </label> <br>
                <p-button label="Thêm mới" [disabled]="!detailcar" styleClass="mr-1" (onClick)="addUser()"></p-button>
            </div>
        </div>
        <div [hidden]="hideForm" class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8; padding: 0px;">
            <label style="font-weight: bold;">Nhập thông tin người dùng<span class="noti-number noti-number-on ml5">New</span></label>
          </div>
          <div [hidden]="hideForm">
              <form action="" #createFormUser="ngForm">
                <div class="row mb-2 mt-1">
                    <div class="col-md-3">
                        <label for="">Tên đăng nhập: <span style="color:red">*</span></label>
                           <input id="userLogin" type="text" class="form-control" [(ngModel)]="modelUser.userLogin" name="userLogin" #userLogin="ngModel">
                           <div *ngIf="userLogin.errors && (userLogin.dirty || userLogin.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!userLogin.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Mật khẩu: <span style="color:red">*</span></label>
                        <input type="password" class="form-control" class="form-control" [(ngModel)]="modelUser.userPassword" name="userPassword" #userPassword="ngModel">  
                        <div *ngIf="userPassword.errors && (userPassword.dirty || userPassword.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!userPassword.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Nhập lại mật khẩu: <span style="color:red">*</span></label>
                        <input type="password" class="form-control" [(ngModel)]="modelUser.userPasswordconfirm" name="userPasswordconfirm" #userPasswordconfirm="ngModel">  
                        <div *ngIf="userPasswordconfirm.errors && (userPasswordconfirm.dirty || userPasswordconfirm.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!userPasswordconfirm.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="" style="visibility: hidden;">Lưu lại </label> <br>
                        <p-button label="Lưu" styleClass="mr-1" [disabled]="!createFormUser.form.valid" (onClick)="saveUser()"></p-button>
                        <p-button label="Hủy" styleClass=" ui-button-danger" (click)="this.hideForm = !this.hideForm"></p-button>
                    </div>
                </div>
              </form>
          </div>
         
        <div class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8; padding: 0px;">
            <label style="font-weight: bold;">Thông tin tài khoản</label>
          </div>
        <div class="row">
            <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid
                                     style="width: 100%; height: 400px"
                                     id="myGrid" enableCellTextSelection=true
                                     class="ag-theme-balham shadow"
                                     [rowData]="managerDetails"
                                     [columnDefs]="columnDefs1"
                                     (gridReady)="onGridReady1($event)"
                                     [animateRows]="true"
                                     [getRowHeight]="getRowHeight1"
                                     [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
        </div>
      <p-footer >
       <div class="col-md-12 d-flex justify-content-end mt-1">
        <p-button label="Đóng"  styleClass="ui-button-secondary" (click)="seachManager = !seachManager"></p-button>
       </div>
    </p-footer>
    </form>
  </p-dialog> -->
