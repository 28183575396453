const host = {
  socketServer: 'https://chat.sunshineservice.vn:3001',
  authServer: 'https://auth.ksfinance.co',
  apiServer: 'https://api.ksfinance.co',
  apiCoreServer: 'https://api.core.ksfinance.co',
  apiHrmServer: 'https://apihrm.sunshinetech.com.vn',
  apiSreServer: 'https://api.sre.ksfinance.co',
  uploadServer: 'https://api.data.ksfinance.co/api/v1/FileHandler',
  cloudFunction: 'https://asia-northeast1-sunshine-app-production.cloudfunctions.net'
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'web_s_user_prod',
  redirect_uri: 'https://web-user.ksfinance.co/auth-callback',
  post_logout_redirect_uri: 'https://web-user.ksfinance.co',
  response_type: 'id_token token',
  scope: 'openid profile api_sre api_core_bigtec api_sre_housing api_hrm_bigtec',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://web-user.ksfinance.co/silent-refresh.html'
};

const firebase = {
  apiKey: 'AIzaSyAb3orVc8nnGT0L2JgbdzXrRND393mFiFU',
  authDomain: 'sunshine-app-production.firebaseapp.com',
  databaseURL: 'https://sunshine-app-production.firebaseio.com',
  projectId: 'sunshine-app-production',
  storageBucket: 'sunshine-app-production.appspot.com'
  // apiKey: 'AIzaSyAczqJoNnTDPPLktoPtQ694IH38sR8wX6w',
  // authDomain: 'sunshine-super-app.firebaseapp.com',
  // databaseURL: 'https://sunshine-super-app.firebaseio.com',
  // projectId: 'sunshine-super-app',
  // storageBucket: 'sunshine-super-app.appspot.com',
  // messagingSenderId: '504497996884',
  // appId: '1:504497996884:web:e07732b704e759529819c1',
};

export const environment = {
  production: true,
  apiBase: host.apiServer,
  authBase: host.authServer + '/connect/token',
  apiCoreBase: host.apiCoreServer,
  apiHrmBase: host.apiHrmServer,
  apiSreBase: host.apiSreServer,
  socketServer: host.socketServer,
  url_conversation: host.socketServer + '/api/v1/conversation',
  url_member: host.socketServer + '/api/v1/member',
  uploadServer: host.uploadServer,
  cloudFunctionServer: host.cloudFunction,
  url_chatuserins: host.socketServer + '/api/v1/user',
  url_getgrouptype: host.socketServer + '/api/v1/grouptype',
  apiChatGetGroupType: host.socketServer + '/api/v1/grouptype',
  authenSettings: authenSettings,
  firebase
};








