import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PagingComponent } from './paging/paging.component';

@NgModule({
    declarations: [PagingComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule
    ],
    entryComponents: [],
    exports: [
        PagingComponent
    ],
    providers: []
})
export class CommonPagingModule {}