<!-- fixed-top-->
<nav id="stackNav" #stackNav class="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-semi-dark">
  <div class="navbar-wrapper">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs">
            <i class="ft-menu font-large-1"></i>
          </a>
        </li>
        <li class="nav-item mr-auto" style="max-width: 83%;">
          <a class="navbar-brand" style="margin-top: 5px;">
            <!-- <img alt="stack admin logo" src="assets/app/images/logo/logo-sspay.png" style="max-width: 92%;" class="brand-logo" id="stackLogo"> -->
            <h5 class="brand-text">S-User</h5>
          </a>
        </li>
        <li class="nav-item d-none d-md-block float-right"><a data-toggle="collapse" class="nav-link modern-nav-toggle pr-0"><i data-ticon="ft-toggle-right" class="toggle-icon ft-toggle-right font-medium-3 white" id="stackNavToggle"></i></a></li>
        <li class="nav-item d-md-none">
          <a data-toggle="collapse" data-target="#navbar-mobile" class="nav-link open-navbar-container">
            <i class="fa fa-ellipsis-v"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div id="navbar-mobile" class="collapse navbar-collapse">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block">
            <a routerLink="/changelog" class="nav-link nav-menu-main hidden-xs font-small-3 d-none" id="stackNavBack" #stackNavBack>
              <i class="ft-arrow-left"></i>Back
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav float-right">          
          <li class="dropdown dropdown-user nav-item notBtn" ngbDropdown placement="bottom-right">
            <a href="javascript:;" class="dropdown-toggle nav-link dropdown-user-link" id="dropdownUser" ngbDropdownToggle>
              <span class="avatar avatar-online">
                <img src="assets/app/images/portrait/small/avatar-s-1.png" alt="avatar">
                <i></i>
              </span>
              <span class="user-name">{{ username }}</span>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownUser" class="box">
              <a class="dropdown-item" href="javascript:;">
                <i class="ft-user"></i> Edit Profile
              </a>
              <a class="dropdown-item" href="javascript:;">
                <i class="ft-mail"></i> My Inbox
              </a>
              <a class="dropdown-item" href="javascript:;">
                <i class="ft-check-square"></i> Task
              </a>
              <a class="dropdown-item" href="javascript:;">
                <i class="ft-message-square"></i> Chats
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript:;" (click)="signout()">
                <i class="ft-power"></i> Logout
              </a>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>