import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FormCommonComponent } from './common-form.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurrencyDirectiveModule } from 'src/app/utils/common/currency.module';
@NgModule({
    declarations: [FormCommonComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        CurrencyDirectiveModule,
        MultiSelectModule,
        DropdownModule,
        ScrollPanelModule,
        CheckboxModule,
        CalendarModule,
        AutoCompleteModule
    ],
    entryComponents: [],
    exports: [
        FormCommonComponent
    ],
    providers: []
})
export class CommonFormModule {}