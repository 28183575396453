
export function datetostring(date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; // January is 0!

    const yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    const datestring = dd + '/' + mm + '/' + yyyy;
    return datestring;
}

export function dateFormatter(params) {
    if (!params.value) {
        return '';
    }
    let newDate = new Date(params.value);

    let dd = newDate.getDate();
    let mm = newDate.getMonth() + 1; // January is 0!
    let ddString = dd.toString(), mmString = mm.toString();
    const yyyy = newDate.getFullYear();
    if (dd < 10) {
        ddString = '0' + dd;
    }
    if (mm < 10) {
        mmString = '0' + mm;
    }

    const datestring = ddString + '/' + mmString + '/' + yyyy;
    return datestring;
}

export function currencyFormatter(params) {
    return params.value ? params.value.toLocaleString() : '';
}

export function stringtodate(datestring) {
    if (datestring) {
        const parts = datestring.split('/');
        const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
        return mydate;
    } else {
        return new Date();
    }
}

export function formatMoney(money: number) {
    if (money) {
        return money.toLocaleString();
    }
}

export function formatNumber(number) {
    console.log(number)
    return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function addDays(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function formatBytes(bytes, decimals) {
    if (bytes == 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function CheckAction(path, action) {
    const menuItems = localStorage.hasOwnProperty('menuItems') ? JSON.parse(localStorage.getItem('menuItems')) : null;
    if(menuItems) {
      let menus = menuItems.filter(m => m.path === path)
      if(menus && menus.length > 0) {
        const arrAction = menus[0].actions.map(d=> d.actionCd);
        if(arrAction.indexOf(action) > -1) {
          return true
        }else {
            return false
        }
      }
      return true
    }
}

export function AgGridFn(lists) {
    let arrAgGrids = [];
    for (let value of lists) {
        let row = {};
        if (value.isStatusLable) {
            row = {
                headerName: value.columnCaption,
                field: value.columnField,
                cellClass: value.cellClass,
                filter: value.isFilter ? 'agTextColumnFilter' : '',
                sortable: true,
                width: value.columnWidth,
                cellRenderer: params => {
                    var a = document.createElement('div');
                    a.innerHTML = params.value;
                    return a;
                  },
                hide: value.isHide ? true : false,
                pinned: value.pinned,
                headerTooltip: value.columnCaption,
                tooltipField: value.columnField
            }
        } else {
            if (value.fieldType === 'image') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    // cellClass: value.cellClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    width: value.columnWidth,
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    cellRenderer: "avatarRenderer",
                    headerTooltip: value.columnCaption,
                    tooltipField: value.columnField,
                    cellClass: ['text-center', 'text-right', 'border-right', 'd-flex', 'align-items-center', 'justify-content-center'],
                    // valueFormatter: value.fieldType == 'decimal' ? ""
                }
            } else if (value.fieldType === 'check') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    cellClass: value.cellClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    width: value.columnWidth,
                    cellRenderer: params => {
                        return ` <span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
                        <input type="checkbox" ${params.value ? 'checked' : ''} ${params.value ? '' : 'disabled'} class="custom-control-input"  >
                        <label class="custom-control-label"></label>
                      </span>`;
                    },
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    headerTooltip: value.columnCaption,
                    // tooltipField: value.columnField
                    // valueFormatter: value.fieldType == 'decimal' ? ""
                }
            } else {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    cellClass: value.cellClass,
                    cellClassRules: value.conditionClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    width: value.columnWidth,
                    cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption,
                    valueFormatter: value.fieldType == 'decimal' ? "x.toLocaleString()": ""
                }
            }
        }

        arrAgGrids.push(row);
    }
    return arrAgGrids
}


export function CheckColorColumn(param,condition , value) {
   if(condition === '==') {
       return param == value;
   }else if(condition === '<') {
    return param < value;
   }else if(condition === '>') {
    return param > value;
   }else if(condition === '<=') {
    return param <= value;
   }else if(condition === '>=') {
    return param >= value;
   }else if(condition === '!=') {
    return param != value;
   }
   return false;
}