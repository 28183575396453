import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const apiBaseUrl  =  environment.apiBase + '/api/v1/customer';
@Injectable()
export class ApiCoreCustomerService {
    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
    }

    options = {
        headers: new HttpHeaders({
            'Authorization': this.authService.getAuthorizationHeaderValue(),
            'Content-Type': 'application/json',
        })
    };

    getRequest(url): Observable<any> {
        return this.http.get<any>(
            `${apiBaseUrl}/${url}`,
            {
                headers: new HttpHeaders({
                    Authorization: this.authService.getAuthorizationHeaderValue(),
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    putRequest(apiMethodName, data): Observable<any> {
        return this.http
            .put<any>(`${apiBaseUrl}/${apiMethodName}`, data, this.options);
    }
    postRequest(apiMethodName, data): Observable<any> {
        return this.http
            .post<any>(`${apiBaseUrl}/${apiMethodName}`, data, this.options);
    }
    deleteRequest(apiMethodName, data): Observable<any> {
        return this.http
            .delete<any>(`${apiBaseUrl}/${apiMethodName}`, this.options);
    }
}