
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var jQuery: any;
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ButtonRendererComponent } from 'src/app/viewcommon/renderer/button-renderer.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-managerinfo',
  templateUrl: './managerinfo.component.html',
  styleUrls: ['./managerinfo.component.scss']
})
export class ManagerinfoComponent implements OnInit, OnChanges {
  @Input() detailManager: any;
  @Input() moduleTables: any;
  @Input() organizes: any;
  public selectedRoles: any[] = [];
  public selectedWebRoles: any[] = [];
  public roleLists = [];
  public roleListsMuti = [];
  public frameworkComponents;
  public gridApi;
  public frameworkComponents1;
  public gridApi1;
  public rowSelection;
  public domLayout;
  public gridColumnApi;
  public groupDefaultExpanded;
  public columnDefs;
  public columnDefs1;
  public defaultColDef;
  public detailRowHeight;
  public gridColumnApi1
  public detailCellRendererParams;
  public detailCellRendererParams1;
  public getRowHeight: any;
  public getRowHeight1: any;
  public categoryLists = [];
  public webRoleLists = [];
  public webRoleListsMuti = [];
  public baseTypesNews = [];
  public loading = false;
  public dataFieldsInfo: any
  public tableGrids: any
  public gridApiChildren: any
  public columnFieldList: any
  saleGroups: any = [];
  app_chats: any = [];
  @Output() back = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.getRowHeight1 = (params) => {
      if (params.node.master) {
        return 35;
      } else {
        return 300
      }
    }
  }
  metas = [];
  submit: boolean = false;
  filter = {
    mod_cd: '',
    table_name: '',
    column_name: ''
  };

  ngOnInit() { }

  getSaleGroups() {
    this.apiService.getSaleGroups(this.detailManager.webId).subscribe(res => {
      this.saleGroups = res.data.map(item => {
        return {
          label: item.chat_name,
          value: {
            mod_cd: item.mod_cd,
            chat_cd: item.chat_cd,
            chat_name: item.chat_name
          }
        };
      });
    });
  }

  ngOnChanges() {
    if (this.detailManager) this.getCategoryList();
    this.onInit();
    this.dataFieldsInfo = null;
    if (this.detailManager && this.detailManager.userId && this.detailManager.webId) {
      this.getSaleGroups();
      this.getRoleList();
      this.getCategoryListByUserId();
      this.getClientWebRoleList();
      this.callApiFields(this.detailManager.userId, this.detailManager.webId);
      this.dataFieldsInfo = null;
      this.filter = {
        mod_cd: '',
        table_name: '',
        column_name: ''
      };
    }
  }
  categoryListsOld = []
  getCategoryList() {
    this.apiService.getCategoryList(this.detailManager.webId, '').subscribe(results => {
      if (results.status === 'success') {
        this.categoryListsOld = results.data;
        this.categoryLists = _.cloneDeep(this.categoryListsOld);
      }
    })
  }
  categoryListsByUser_Id = [];
  getCategoryListByUserId() {
    this.apiService.getCategoryList(this.detailManager.webId, this.detailManager.userId).subscribe(results => {
      if (results.status === 'success') {
        this.categoryListsByUser_Id = results.data;
        if (this.gridApi1) {
          this.gridApi1.setRowData(this.categoryListsByUser_Id);
        }
        this.onInit1();
      }
    })
  }

  getClientWebRoleList() {
    this.apiService.getClientWebRoleList(this.detailManager.webId).subscribe(results => {
      if (results.status === 'success') {
        this.webRoleLists = results.data;
        this.webRoleListsMuti = this.webRoleLists.map(data => {
          return {
            label: data.roleName,
            value: data.roleId
          }
        });
      }
    })
  }
  managerList = [];
  callApiFields(userId, webId) {
    this.apiService.getManagerInfo(userId, webId).subscribe(results => {
      if (results.status === 'success') {
        this.dataFieldsInfo = results.data;
        if (this.dataFieldsInfo && this.dataFieldsInfo.group_fields && this.dataFieldsInfo.group_fields.length > 0) {
          this.dataFieldsInfo.group_fields.forEach(groupChildren => {
            groupChildren.fields.forEach(data => {
              data[`${data.field_name}`] = data.columnValue;
              if (data.data_type === 'datetime') {
                data[`${data.field_name}`] = data.columnValue ? new Date(this.convestDate(data.columnValue)) : '';
              }
              if (data.columnType === 'select') {
                if (data.field_name === 'orgId' && this.organizes) {
                  data.options = this.organizes.map(res => { return { objCode: res.organizationCd, objKey: 'organizationCd', objName: res.label, objValue: res.value } });
                } else if (data.field_name === 'userRole') {
                  this.apiService.getManagerList(1).subscribe(results => {
                    if (results.status === 'success') {
                      this.managerList = results.data;
                    }
                  })
                  this.apiService.getCustObjectList(data.columnObject).subscribe(results => {
                    data.options = results.data;
                  })
                } else {
                  this.apiService.getCustObjectList(data.columnObject).subscribe(results => {
                    data.options = results.data;
                  })
                }
              }
            });
          })
          setTimeout(() => {
            if (this.managerList && this.dataFieldsInfo && this.managerList.length > 0) {
              this.dataFieldsInfo.group_fields.forEach(groupChildren => {
                groupChildren.fields.forEach(data => {
                  if (data.field_name === 'reportId' && data.columnType === 'select') {
                    data.options = this.managerList.map(res => { return { objCode: res.userId, objKey: 'reportId', objName: res.fullName, objValue: res.userId } });
                  }
                })
              })
            }
          }, 500);
        }
        console.log('data field info', this.dataFieldsInfo);
        if (this.dataFieldsInfo && this.dataFieldsInfo.roles && this.dataFieldsInfo.roles.length > 0) {
          this.selectedRoles = this.dataFieldsInfo.roles;
        }
        if (this.dataFieldsInfo && this.dataFieldsInfo.web_roles && this.dataFieldsInfo.web_roles.length > 0) {
          this.selectedWebRoles = this.dataFieldsInfo.web_roles.map(res => { return { label: res.roleName, value: res.roleId } });
        }
        console.log(this.dataFieldsInfo)
        if (this.dataFieldsInfo && this.dataFieldsInfo.baseTypes && this.dataFieldsInfo.baseTypes.length > 0) {
          this.baseTypesNews = _.cloneDeep(this.dataFieldsInfo.baseTypes);
          if (this.gridApi) this.gridApi.setRowData(this.dataFieldsInfo.baseTypes);
        }
      }
    })
  }

  onchangeUserRole(value) {
    this.managerList = [];
    this.apiService.getManagerList(value).subscribe(results => {
      if (results.status === 'success') {
        this.managerList = results.data;
        this.dataFieldsInfo.group_fields.forEach(groupChildren => {
          groupChildren.fields.forEach(data => {
            if (data.field_name === 'reportId' && data.columnType === 'select') {
              data.options = this.managerList.map(res => { return { objCode: res.userId, objKey: 'reportId', objName: res.fullName, objValue: res.userId } });
            }
          })
        })
      }
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.dataFieldsInfo && this.dataFieldsInfo.baseTypes.length > 0) {
      this.gridApi.setRowData(this.dataFieldsInfo.baseTypes);
    }
  }

  convestDate(data) {
    if (data) {
      let date1s = data.split('/');
      return `${date1s[2]}-${date1s[1]}-${date1s[0]}`
    }
  }

  backpage() {
    this.back.emit("back")
  }

  saveManagerForm() {
    this.loading = true;
    this.submit = true;
    this.dataFieldsInfo.group_fields.forEach(results => {
      results.fields.forEach(data => {
        if (data.data_type === 'datetime') {
          data.columnValue = data[data.field_name] ? moment(data[data.field_name]).format("DD/MM/YYYY") : null;
          delete data[data.field_name];
        } else {
          data.columnValue = data[data.field_name];
          delete data[data.field_name];
        }
        if ((data.columnType === 'select') && data.options) {
          delete data.options;
        }
      })
    });
    let web_roles = []
    let base_types = []
    for (let item of this.webRoleLists) {
      if (this.selectedWebRoles.map(function (e) { return e.value }).indexOf(item.roleId) > -1) {
        web_roles.push(item);
      }
    }

    if (this.dataFieldsInfo.baseTypes && this.dataFieldsInfo.baseTypes.length > 0) {
      let arr1 = this.dataFieldsInfo.baseTypes.filter(this.comparer(this.baseTypesNews));
      let arr2 = this.baseTypesNews.filter(this.comparer(this.dataFieldsInfo.baseTypes));
      let arr2New = [];
      arr2.forEach(res => {
        res.isDel = true;
        arr2New.push(res);
        // if (res.isAll) {

        // } else {
        //   res.categories = [];
        //   arr2New.push(res);
        // }
      });
      base_types = this.dataFieldsInfo.baseTypes.concat(arr2New);
    } else {
      this.baseTypesNews.forEach(res => res.isDel = true);
      base_types = this.baseTypesNews
    }
    const params = {
      userId: this.dataFieldsInfo.userId,
      webId: this.detailManager.webId,
      baseTypes: base_types,
      group_fields: this.dataFieldsInfo.group_fields,
      web_roles: web_roles,
      roles: this.selectedRoles,
      // app_chats: this.app_chats
      app_chats: this.dataFieldsInfo.app_chats
    };
    this.apiService.setManagerInfo(params)
      .subscribe(result => {
        if (result.status === 'success') {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công !'});
          this.back.emit('success');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: result.message});
        }
      });
  }

  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.base_type == current.base_type
      }).length == 0;
    }
  }


  getRoleList() {
    this.apiService.getIdentityRole().subscribe(results => {
      if (results.status === 'success') {
        this.roleLists = results.data;
        this.roleListsMuti = this.roleLists.map(data => {
          return {
            label: data.name,
            value: data.name
          }
        });
      }
    })
  }



  onInit() {
    this.columnDefs = [
      { headerName: 'Mô tả', field: 'base_desc', cellRenderer: 'agGroupCellRenderer' },
      {
        headerName: 'isAll',
        field: 'isAll',
        width: 100,
        cellRenderer: params => {
          return ` <span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
            <input type="checkbox" ${params.value ? 'checked' : ''} ${params.value ? '' : 'disabled'} class="custom-control-input"  >
            <label class="custom-control-label"></label>
          </span>`;
        },
      },
      {
        headerName: 'Chọn',
        width: 100,
        field: 'checked',
        cellRenderer: (params) => {
          var input = document.createElement('input');
          input.type = "checkbox";
          // input.checked=params.value;
          input.addEventListener('click', (event) => {
            this.checkboxRightDisabled = false;
            this.checkboxleftDisabled = true;
            params.value = !params.value;
            params.node.data.checked = params.value;
            if (params.value) {
              this.arrParent.push(params.data);
              const arrCheck = this.arrChildren.filter(res => res.base_type != params.data.base_type);
              this.arrChildren = [...arrCheck];
            } else {
              let index = this.arrParent.findIndex(d => d.base_type === params.data.base_type);
              this.arrParent.splice(index, 1);
              if (this.arrParent.length === 0) this.checkboxRightDisabled = true; this.checkboxleftDisabled = true;
            }
          });
          return input;
        }
      },
    ];
    this.groupDefaultExpanded = 0;
    this.detailRowHeight = 300;
    this.detailCellRendererParams = {
      detailGridOptions: {
        // domLayout : "autoHeight",
        frameworkComponents: {
          buttonRenderer: ButtonRendererComponent
        },
        getRowHeight: (params) => {
          return 35;
        },
        defaultColDef: {
          cellClass: ['border-right']
        },
        columnDefs: [
          { headerName: 'categoryName', field: 'categoryName' },
          {
            headerName: 'Chọn',
            field: 'checked',
            width: 100,
            cellRenderer: (params) => {
              var input = document.createElement('input');
              input.type = "checkbox";
              // input.checked=params.value;
              input.addEventListener('click', (event) => {
                this.checkboxRightDisabled = false;
                this.checkboxleftDisabled = true;
                params.value = !params.value;
                params.node.data.checked = params.value;
                let index = this.arrParent.findIndex(s => s.base_type === params.data.base_type);
                if (index === -1) {
                  this.arrChildren.push(params.data);
                }
              });
              return input;
            }
          },
        ],

      },
      getDetailRowData: (params) => {
        if (params.data.isAll) {
          params.successCallback([]);
        } else {
          params.successCallback(params.data.categories);
        }
      }
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 35;
      } else {
        return params.data.isAll ? 0 : 300
      }
    }
    this.defaultColDef = {
      cellClass: ['border-right']
    }
  }
  arrParent = [];
  arrChildren = [];
  checkboxRightDisabled = true;
  checkboxleftDisabled = true;
  onInit1() {
    this.columnDefs1 = [
      { headerName: 'Mô tả', field: 'base_desc', cellRenderer: 'agGroupCellRenderer' },
      {
        headerName: 'Chọn',
        field: 'isAll',
        width: 100,
        cellRenderer: (params) => {
          var input = document.createElement('input');
          input.type = "checkbox";
          // input.checked=params.value;
          input.addEventListener('click', (event) => {
            this.checkboxRightDisabled = true;
            this.checkboxleftDisabled = false;
            params.value = !params.value;
            if (params.value) {
              params.node.data.isAll = params.value;
              this.arrParent.push(params.data);
              const arrCheck = this.arrChildren.filter(res => res.base_type != params.data.base_type);
              this.arrChildren = [...arrCheck];
            } else {
              let index = this.arrParent.findIndex(d => d.base_type === params.data.base_type);
              this.arrParent.splice(index, 1);
              // if (this.arrParent.length === 0) this.checkboxRightDisabled = true; this.checkboxleftDisabled = true;
            }

          });
          return input;
        }
      },
    ];
    this.groupDefaultExpanded = 0;
    this.detailRowHeight = 300;
    this.detailCellRendererParams1 = {
      detailGridOptions: {
        // domLayout : "autoHeight",
        frameworkComponents: {
          buttonRenderer: ButtonRendererComponent
        },
        getRowHeight: (params) => {
          return 35;
        },
        defaultColDef: {
          cellClass: ['border-right']
        },
        columnDefs: [
          { headerName: 'categoryName', field: 'categoryName' },
          {
            headerName: 'Chọn',
            field: 'checked',
            width: 120,
            cellRenderer: (params) => {
              var input = document.createElement('input');
              input.type = "checkbox";
              // input.checked=params.value;
              input.addEventListener('click', (event) => {
                this.checkboxRightDisabled = true;
                this.checkboxleftDisabled = false;
                params.value = !params.value;
                params.node.data.checked = params.value;
                let index = this.arrParent.findIndex(s => s.base_type === params.data.base_type);
                if (index === -1) {
                  this.arrChildren.push(params.data);
                }
              });
              return input;
            }
          },

        ],
        onFirstDataRendered(params) {
          this.gridApi1Children = params.api
        },
      },
      getDetailRowData: (params) => {
        if (params.data.isAll) {
          params.data.categories.forEach(element => {
            element.checked = true;
          });
          params.successCallback(params.data.categories);
        } else {
          params.successCallback(params.data.categories);
        }
      }
    };
    this.rowSelection = 'multiple';
  }

  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    if (this.categoryListsByUser_Id && this.categoryListsByUser_Id.length > 0) this.gridApi1.setRowData(this.categoryListsByUser_Id)
  }

  resetAgGrid() {
    this.gridApi.setRowData(this.dataFieldsInfo.baseTypes);
    this.gridApi1.setRowData(this.categoryListsByUser_Id);
  }

  chevronFn(type) {
    this.checkboxRightDisabled = true;
    this.checkboxleftDisabled = true;
    if (type === 'rights') {
      this.categoryListsByUser_Id = _.cloneDeep(this.categoryLists);
      this.dataFieldsInfo.baseTypes = [];
      this.resetAgGrid();
    } else if (type === 'right') {
      const lists = _.cloneDeep(this.categoryLists);
      if (this.arrParent && this.arrParent.length > 0) {
        this.arrParent.forEach(results => {
          let items = lists.filter(d => d.base_type === results.base_type);
          let index = this.dataFieldsInfo.baseTypes.findIndex(d => d.base_type === results.base_type);
          this.dataFieldsInfo.baseTypes.splice(index, 1);
          let checkIndexExistinCategoryListsByUser_Id = this.categoryListsByUser_Id.findIndex(res => res.base_type === items[0].base_type);
          if (checkIndexExistinCategoryListsByUser_Id === -1) {
            this.categoryListsByUser_Id.push(items[0]);
          } else {
            //remove rồi add lại
            this.categoryListsByUser_Id.splice(checkIndexExistinCategoryListsByUser_Id, 1);
            this.categoryListsByUser_Id.push(items[0]);
          }
        });
        this.resetAgGrid();
      }

      if (this.arrChildren && this.arrChildren.length > 0) {
        const lists = _.cloneDeep(this.categoryLists);
        this.arrChildren.forEach(d => {
          if (this.categoryListsByUser_Id.map(function (e) { return e.base_type }).indexOf(d.base_type) < 0) {
            const items = lists.filter(res => {
              if (res.base_type === d.base_type) {
                res.categories = [d];
                return res
              }
            })
            this.categoryListsByUser_Id.push(items[0]);
            this.gridApi1.setRowData(this.categoryListsByUser_Id)
          } else {
            this.categoryListsByUser_Id.forEach(m => {
              if (m.base_type === d.base_type) {
                m.categories.push(d);
                this.gridApi1.updateRowData({ update: [m] })
              }
            })

          }
          //còn lại
          this.dataFieldsInfo.baseTypes.forEach(element => {
            if (element.base_type === d.base_type) {
              const items = element.categories.filter(r => r.categoryCd != d.categoryCd);
              element.categories = items;
              if (element.categories.length === 0) {
                let index = this.dataFieldsInfo.baseTypes.findIndex(res => res.base_type === element.base_type);
                this.gridApi.updateRowData({ remove: [element] });
                this.dataFieldsInfo.baseTypes.splice(index, 1);
              } else {
                this.gridApi.updateRowData({ update: [element] });
              }
            }
          });

        })
      }

    } else if (type === 'lefts') {
      this.dataFieldsInfo.baseTypes = _.cloneDeep(this.categoryLists);
      this.dataFieldsInfo.baseTypes.forEach(element => element.isAll = true);
      this.categoryListsByUser_Id = [];
      this.resetAgGrid();
    } else {
      const lists = _.cloneDeep(this.categoryLists);
      if (this.arrParent && this.arrParent.length > 0) {
        this.arrParent.forEach(results => {
          let items = lists.filter(d => d.base_type === results.base_type);
          items.forEach(m => m.isAll = true);
          let index = this.categoryListsByUser_Id.findIndex(d => d.base_type === results.base_type);
          this.categoryListsByUser_Id.splice(index, 1);
          let checkIndexExistindataFieldsInfoBaseTypes = this.dataFieldsInfo.baseTypes.findIndex(res => res.base_type === items[0].base_type);
          if (checkIndexExistindataFieldsInfoBaseTypes === -1) {
            this.dataFieldsInfo.baseTypes.push(items[0]);
          } else {
            //remove rồi add lại
            this.dataFieldsInfo.baseTypes.splice(checkIndexExistindataFieldsInfoBaseTypes, 1);
            this.dataFieldsInfo.baseTypes.push(items[0]);
          }
        })
        this.resetAgGrid();
      }
      if (this.arrChildren && this.arrChildren.length > 0) {
        this.arrChildren.forEach(d => {
          if (this.dataFieldsInfo.baseTypes.map(function (e) { return e.base_type }).indexOf(d.base_type) < 0) {
            const items = lists.filter(res => {
              if (res.base_type === d.base_type) {
                res.categories = [d];
                return res
              }
            })
            this.dataFieldsInfo.baseTypes.push(items[0]);
            this.gridApi.setRowData(this.dataFieldsInfo.baseTypes)
          } else {
            this.dataFieldsInfo.baseTypes.forEach(m => {
              if (m.base_type === d.base_type) {
                m.categories.push(d);
                this.gridApi.updateRowData({ update: [m] })
              }
            })
          }
          //còn lại
          this.categoryListsByUser_Id.forEach(element => {
            if (element.base_type === d.base_type) {
              const items = element.categories.filter(r => r.categoryCd != d.categoryCd);
              element.categories = items;
              if (element.categories.length === 0) {
                const index = this.categoryListsByUser_Id.findIndex(res => res.base_type === element.base_type);
                this.gridApi1.updateRowData({ remove: [element] });
                this.categoryListsByUser_Id.splice(index, 1);
                this.dataFieldsInfo.baseTypes.forEach(element1 => element1.base_type === element.base_type ? element1.isAll = true : element1.isAll = element1.isAll);
                this.gridApi.setRowData(this.dataFieldsInfo.baseTypes)
              } else {
                this.gridApi1.updateRowData({ update: [element] });
              }
            }
          });

        })
      }
    }
    this.arrChildren = [];
    this.arrParent = []
  }

}










