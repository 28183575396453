import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
declare var jQuery: any;
@Component({
  selector: 'app-addusertogrouprole',
  templateUrl: './addusertogrouprole.component.html',
  styleUrls: ['./addusertogrouprole.component.scss']
})
export class AddusertogrouproleComponent implements OnInit {
  @Input() listGroupRole;
  @Input() detail;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  checkAll = false;
  modalRoleUser = {
    userId: '',
    fullName: '',
    roles : []
  }
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
  ) { }

    huy() {
      this.close.emit()
    }
  ngOnInit() {
      if(this.detail) {
        this.modalRoleUser.fullName = this.detail.fullName;
        this.modalRoleUser.userId = this.detail.userId;
      }
    // check isCheck true hết thì tích checked_all = true
    if(this.listGroupRole && this.listGroupRole.length > 0) {
      for(let index in this.listGroupRole) {
        if (this.listGroupRole[index].clientWebRoles.map(function (e) { return e.isCheck }).indexOf(false) < 0){
          this.listGroupRole[index].checked_all = true
        }else {
          this.listGroupRole[index].checked_all = false
        }
      }

      let arrCheck= this.listGroupRole.map(res => res.checked_all);
    if(arrCheck.includes(true) && arrCheck.includes(false)) {
      this.checkAll = false
      jQuery("#customCheck").prop( "checked", false );
    }else if(arrCheck.includes(true) && !arrCheck.includes(false)) {
      this.checkAll = true
      jQuery("#customCheck").prop( "checked", true );
    }
    }
  }

  toggleMenu(e, i) {
    jQuery(`.collapse_${i + 1}`).toggleClass("show")
  }

  onCheck() {
    this.checkAll = !this.checkAll;
    if (this.checkAll) {
      this.listGroupRole.forEach(element => {
        element.checked_all = true;
        element.clientWebRoles.forEach(element1 => {
          element1.isCheck = true
        });
      });
    } else {
      this.listGroupRole.forEach(element => {
        element.checked_all = false;
        element.clientWebRoles.forEach(element1 => {
          element1.isCheck = false;
        });
      });
    }
  }

  onCheckWebRole(item, idx) {
    this.listGroupRole[idx].checked_all = !this.listGroupRole[idx].checked_all;
    if(this.listGroupRole[idx].checked_all) {
      this.listGroupRole[idx].clientWebRoles.forEach(element1 => {
        element1.isCheck = true;
      });
    }else {
      this.listGroupRole[idx].clientWebRoles.forEach(element1 => {
        element1.isCheck = false;
      });
    }
    let arrCheck= this.listGroupRole.map(res => res.checked_all);
    if(arrCheck.includes(true) && arrCheck.includes(false)) {
      this.checkAll = false
      jQuery("#customCheck").prop( "checked", false );
    }else if(arrCheck.includes(true) && !arrCheck.includes(false)) {
      this.checkAll = true
      jQuery("#customCheck").prop( "checked", true );
    }
  }

  onCheckIsGroupRole(paternIdx, idx) {
    this.listGroupRole[paternIdx].clientWebRoles[idx].isCheck = !this.listGroupRole[paternIdx].clientWebRoles[idx].isCheck;
    if (this.listGroupRole[paternIdx].clientWebRoles.map(function (e) { return e.isCheck }).indexOf(false) < 0){
      this.listGroupRole[paternIdx].checked_all = true
    }else {
      this.listGroupRole[paternIdx].checked_all = false
    }

    let arrCheck= this.listGroupRole.map(res => res.checked_all);
    if(arrCheck.includes(true) && arrCheck.includes(false)) {
      this.checkAll = false
      jQuery("#customCheck").prop( "checked", false );
    }else if(arrCheck.includes(true) && !arrCheck.includes(false)) {
      this.checkAll = true
      jQuery("#customCheck").prop( "checked", true );
    }
  }

  saveRoleUser() {
    this.selectionWebRole().then(results => {
      delete this.modalRoleUser.fullName;
      this.modalRoleUser.roles = results;
      this.apiService.addUserToWeb(this.modalRoleUser).subscribe((results: any) => {
        if(results.status === 'success') {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Cập nhật quyền user thành công'});
          this.cancelModel();
          this.save.emit("success");
        }
      })
    })
  }

  async selectionWebRole() {
    let lists = [];
    this.listGroupRole.forEach(web => {
      web.clientWebRoles.forEach(item => {
        if (item.isCheck) {
          lists.push(item.roleCd);
        }
      })
      
    });
    return await lists;
  }

  cancelModel() {
    this.modalRoleUser = {
      userId: '',
      fullName: '',
      roles : []
    }
  }

}
