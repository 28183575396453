import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MessageService} from 'primeng/api';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, flatMap } from 'rxjs/operators';
import { CheckAction } from '../utils/common/function-common';
import { RoleModel, CatalogModel, Role } from '../models/customer.model';
import { ButtonRendererComponent } from '../viewcommon/renderer/button-renderer.component';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
declare var jQuery: any;
@Component({
  selector: 'app-managerpermissionuser',
  templateUrl: './managerpermissionuser.component.html',
  styleUrls: ['./managerpermissionuser.component.scss']
})
export class ManagerpermissionuserComponent implements OnInit {
  public checkAction= CheckAction;
  public modules: Module[] = AllModules;
  public loading= false;
  listCars: any[] = [];
  public gridApi;
  public detailRowHeight;
  public detailCellRendererParams;
  public gridColumnApi;
  public frameworkComponents: any;
  public columnDefs;
  public defaultColDef;
  public getRowHeight;
  public colResizeDefault;
  public rowData: any[] = [];
  public listGroupRole: any[] = [];
  selectedItems = [];
  public key_car;
  itemList = [];
  userLogin = '';
  model: any = {
    userLogin: '',
    userPassword: ''
  };
  modelPass: any = {
    userLogin: '',
    userPass: ''
  };
  userid;
  userName;
  show_dialogcalculate = false;
  show_dialogcreate = false;
  show_dialogaddcard = false;
  dropdownList1 = [];
  selectedItems1 = [];
  listRoleModel: RoleModel[];
  listRole = [];
  listCatalog = [];
  dropdownList3 = [];
  selectedItems3 = [];
  listCatalogModel: CatalogModel[];
  constructor(
    private apiService: ApiService,
    private messageService: MessageService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };
  }

  ngOnInit() {
    this.onInitGrid();
  }

  getCardCustomers() {
    this.loading = true;
    this.apiService.getEmployeeList(this.key_car, '', -1, -1, 0, null, null, null).subscribe((result: any) => {
      this.listCars = result.data;
      this.loading = false;
      this.listCars.forEach(data => data.fullNameAndCar = `${data.userLogin}---${data.phone}---${data.fullName}`);
    });
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  formatter = (result) => result['fullNameAndCar'] ? result['fullNameAndCar'] : result;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.listCars.filter(v => (v.userLogin ? v.userLogin.toLowerCase().indexOf(term.toLowerCase()) > -1 : null) || (v.phone ? v.phone.toLowerCase().indexOf(term.toLowerCase()) > -1 : null) || (v.fullName ? v.fullName.toLowerCase().indexOf(term.toLowerCase()) > -1 : null)).slice(0, 10))
    )

  detailcar
  selectedItem1(event) {
    this.detailcar = event;
    this.model.custId = event.custId;
    this.apiService.getUsersByCust(event.custId).subscribe((result: any) => {
      if (result.status === 'success') {
        this.gridApi.setRowData(result.data);
      }
    });
  };
  createUser() {
    this.model.userLogin = '';
    this.model.userPassword = '';
    this.show_dialogcreate = true;
    jQuery('#createSMS').modal('show');
  }

  SaveUser() {
    this.userLogin = this.model.userLogin;
    this.apiService.setUser(this.model.custId, this.model.userLogin, this.model.userPassword).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Tạo tài khoản đăng nhập thành công'});
      this.callApi(this.model.custId);
    }, error => { });
    jQuery('#createSMS').modal('hide');
    this.show_dialogcreate = false;
  }

  CancelUser() {
    this.show_dialogcreate = false;
  }

  editPass(userLogin) {
    this.modelPass.userLogin = userLogin;
    this.modelPass.userPass = '';
    this.show_dialogcalculate = true;
    jQuery('#editSMS').modal('show');
  }

  CancelPass() {
    this.show_dialogcalculate = false;
  }

  SavePass() {
    this.apiService.changePass(this.modelPass).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Thay đổi mật khẩu thành công'});
      this.callApi(this.detailcar.custId);
      this.CancelPass();
    }, error => { });
    jQuery('#editSMS').modal('hide');
  }

  SaveRole() {
    const p_role: Role = Role.createDefault();
    p_role.userId = this.userid;
    const selectedroles = this.selectedItems1.reduce(
      (prev, curr) => {
        if (prev === '') {
          return curr.id;
        }
        return prev + ',' + curr.id;
      }, '');

    p_role.roles = selectedroles.split(',');
    this.apiService.addUserToRoles(p_role).subscribe(
      (results: any) => {
        if (results.status === 'error') {
          this.messageService.add({ severity: 'error', summary: 'Thông báo', detail:'Có lỗi khi cập nhật role !'});
        } else {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Cập nhật role thành công'});
          this.callApi(this.detailcar.custId);
        }
      },
      error => {
      });
    jQuery('#roleDialog').modal('hide');

  }

  // onItemSelect1(item: any) {
  // }
  // OnItemDeSelect1(item: any) {
  // }
  // onSelectAll1(items: any) {
  // }
  // onDeSelectAll1(items: any) {
  // }

  initAddRole(userId) {
    // this.listRoleModel.length = 0;
    // this.listRole.length = 0;
    // this.selectedItems1.length = 0;
    this.listRoleModel = [];
    this.listRole = [];
    this.selectedItems1 = [];
    this.userid = userId;
    jQuery("#roleDialog").modal("show");
    this.apiService.getRoles().subscribe(
      (results: any) => {
        this.listRoleModel = results.data;
        this.onRenderRoles(this.listRoleModel);
      },
      error => { });
    this.apiService.getUserRoles(userId).subscribe(
      results => {
        this.listRole = results;
        let drpObject = { id: '', itemName: '' };
        for (let i = 0; i < this.listRole.length; i++) {
          for (let j = 0; j < this.listRoleModel.length; j++) {
            if (this.listRole[i] === this.listRoleModel[j].name) {
              drpObject = { id: '', itemName: '' };
              drpObject.id = this.listRoleModel[j].name;
              drpObject.itemName = this.listRoleModel[j].normalizedName;
              this.selectedItems1[i] = drpObject;
            }
          }
        }

      },
      error => { });
  }

  initAddCatalog(userId) {
    this.listCatalogModel = [];
    this.listCatalog = [];
    this.selectedItems3 = [];
    this.userid = userId;
    jQuery('#catalogDialog').modal('show');
    this.apiService.getRoleCategories().subscribe(
      (results: any) => {
        this.listCatalogModel = results.data;
        let drpObject = { id: '', itemName: '' };

        for (let i = 0; i < this.listCatalogModel.length; i++) {
          drpObject = { id: '', itemName: '' };
          drpObject.id = this.listCatalogModel[i].categoryCd;
          drpObject.itemName = this.listCatalogModel[i].categoryName;
          this.dropdownList3[i] = drpObject;
        }
      },
      error => { });
    setTimeout(() => {
      this.apiService.getUserCategories(userId).subscribe(
        results => {
          this.listCatalog = results;
          let drpObject = { id: '', itemName: '' };
          for (let i = 0; i < this.listCatalog.length; i++) {

            for (let j = 0; j < this.listCatalogModel.length; j++) {

              if (this.listCatalog[i] === this.listCatalogModel[j].categoryCd) {
                drpObject = { id: '', itemName: '' };
                drpObject.id = this.listCatalogModel[j].categoryCd;
                drpObject.itemName = this.listCatalogModel[j].categoryName;
                this.selectedItems3[i] = drpObject;
              }
            }
          }
        },
        error => { });
    }, 100);
  }

  SaveCatalog() {
    const c_role: Role = Role.createDefault();
    c_role.userId = this.userid;
    const selectedweb = this.selectedItems3.reduce(
      (prev, curr) => {
        if (prev === '') {
          return curr.id;
        }
        return prev + ',' + curr.id;
      }, '');
    c_role.roles = selectedweb.split(',');
    this.apiService.addUserToCategories(c_role).subscribe((results: any) => {
      if (results.status === 'error') {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail:'Có lỗi khi cập nhật Categories !'});
      } else {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Cập nhật Categories thành công'});
        this.callApi(this.detailcar.custId);
      }
    }, error => { });
    jQuery('#catalogDialog').modal('hide');
  }

  onRenderRoles(listRoleModel) {
    let drpObject = { id: '', itemName: '' };
    for (let i = 0; i < listRoleModel.length; i++) {
      drpObject = { id: '', itemName: '' };
      drpObject.id = listRoleModel[i].name;
      drpObject.itemName = listRoleModel[i].normalizedName;
      this.dropdownList1[i] = drpObject;
    }
  }

  addRole(e) {
    this.initAddRole(e.rowData.userId)
  }

  addCatalog(e) {
    this.initAddCatalog(e.rowData.userId)
  }

  editPassUser(e) {
    this.editPass(e.rowData.userLogin);
  }

  onInitGrid() {
    this.columnDefs = [
      {
        headerName: 'Tên đăng nhập',
        field: 'userLogin',
        resizable: true,
      },
      {
        headerName: 'Trạng thái',
        field: 'lockName',
        cellClass: function(params) { return (params.value==='Đang hoạt'?['border-right', 'text-center', 'text-success']:['border-right', 'text-center']); }
      },
      {
        headerName: 'Is admin',
        field: 'isAdmin',
        cellRenderer: params => {
            return ` <span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
            <input type="checkbox" ${params.value ? 'checked' : ''} ${params.value ? '' : 'disabled'} class="custom-control-input"  >
            <label class="custom-control-label"></label>
          </span>`;
        },
        headerTooltip: 'isAdmin',
      },
      {
        headerName: 'Thao tác',
        field: 'button',
        filter: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: (params) => this.showbuton(params)
      },
    ];
    this.detailRowHeight = 250;
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'Mã nhóm quyền',
            field: 'roleCd',
            cellClass: ['border-right'],
          },
          {
            headerName: 'Tên nhóm quyền',
            field: 'roleName',
            cellClass: ['border-right'],
          },
          {
            headerName: 'Thao tác', field: 'button', filter: '', cellRenderer: 'buttonRenderer',
            cellClass: ['border-right'],
            cellRendererParams: {
              buttons: [
                {
                  // label: 'Sửa nhóm quyền', icon: 'fa fa-edit', class: 'btn-success mr-g-5', onClick: this.editGroupRole.bind(this)
                },
                {
                  // label: 'Xóa', icon: 'sun-icon-trashcan', class: 'btn-danger', onClick: this.deleteGroupRole.bind(this)
                },
              ]
            },
          },
        ],
        defaultColDef: {
          resizable: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          cellClass: ['border-right', 'text-center'],
        },
        frameworkComponents: {
          buttonRenderer: ButtonRendererComponent
        },
        detailRowHeight: 250,
        masterDetail: true,
        onFirstDataRendered: (params) => {
          // this.childApi = params.api;
          params.api.sizeColumnsToFit();
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.clientWebRoles);
      },
    };
    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      cellClass: ['border-right'],
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 40;
      } else {
        return 200;
      }
    };
  }

  onBtnClick1(e) {
    this.apiService.getGroupRole(e.rowData.userId).subscribe((result: any) => {
      this.listGroupRole = result.data;
      this.listGroupRole.forEach(s => {
        s.checked_all = false
      })
      this.showModal(e.rowData, '')
    });
  }
  onBtnClick2() {

  }

  cancel() {
    this.key_car = null;
    this.detailcar = null
    this.gridApi.setRowData([])
  }

  callApi(custId) {
    this.apiService.getUsersByCust(custId).subscribe((result: any) => {
      if (result.status === 'success') {
        this.gridApi.setRowData(result.data);
        this.displayRole = false;
      }
    });
  }

  detailInfo = null;
  displayRole = false;
  showModal(detail, type) {
    this.displayRole = true;
    this.detailInfo= detail;
    this.listGroupRole= this.listGroupRole || [];
  }

  onSave(receiveEntry) {
    if (receiveEntry) {
      this.callApi(this.detailcar.custId);
    }
  }

  lockCard(e) {
    this.apiService.lockUser(e.rowData.userId).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Khóa tài khoản thành công'});
      this.callApi(e.rowData.custId);
    }, error => { });
  }

  unlockCard(e) {
    this.apiService.unLockUser(e.rowData.userId).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Mở khóa tài khoản thành công'});
      this.callApi(e.rowData.custId);
    }, error => { });
  }

  deleteCard(e) {
    if (confirm('Bạn có chắc chắn muốn xóa tài khoản này không?')) {
      this.apiService.deleteUserCust(e.rowData.userId).subscribe(results => {
        this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Xóa tài khoản thành công'});
        this.callApi(e.rowData.custId);
      }, error => { });
    }
  }

  convertChatGroupResponse(result): any[] {
    return result.data.map(item => {
      return {
        'id': item.groupTypeId,
        'itemName': item.groupTypeName
      };
    });
  }

  chatGroup(userLogin) {
    this.userName = userLogin;
    jQuery('#chatDialog').modal('show');
    this.apiService.getChatGroup('')
      .pipe(map(allGroup => this.convertChatGroupResponse(allGroup)))
      .pipe(map(allGroupItem => this.itemList = allGroupItem))
      .pipe(flatMap(_ => this.apiService.getChatGroup(userLogin)))
      .pipe(map(userGroup => this.convertChatGroupResponse(userGroup)))
      .pipe(map(userGroupItem => { this.selectedItems = userGroupItem; }))
      .subscribe();
    this.show_dialogaddcard = true;
  }
  CancelChat() {
    this.show_dialogaddcard = false;
  }
  SaveChat() {
    const selectedChatGroup = this.selectedItems.reduce(
      (prev, curr) => {
        if (prev === '') {
          return curr.id;
        }
        return prev + ',' + curr.id;
      }, '');
    this.apiService.updateChatGroup(this.userName, { groupTypes: selectedChatGroup }).subscribe(_ => {
      this.CancelChat();
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Cập nhật thành công'});
      this.callApi(this.detailcar.custId);
    });
    jQuery('#chatDialog').modal('hide');
  }



  addGroupChat(e, data) {
    if(data.rowData.isAdmin) {
      this.chatGroup(data.rowData.userLogin);
    }else {
      alert("Xin lỗi tài khoản này không phải admin");
    }
  }

  lockAdmin(item) {
    this.apiService.setUserAdmin(item.userId, false).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Đặt quyền user thành công'});
      this.callApi(item.custId);
    }, error => { });
  }

  unlockAdmin(item) {
    this.apiService.setUserAdmin(item.userId, true).subscribe(results => {
      this.messageService.add({ severity: 'success', summary: 'Thông báo', detail:'Đặt quyền Admin thành công'});
      this.callApi(item.custId);
    }, error => { });
  }

  checkUserOrAdmin(e) {
    if(e.rowData.isAdmin) {
      this.lockAdmin(e.rowData);
    }else {
      this.unlockAdmin(e.rowData);
    }
  }

  showbuton(e) {
    if (e.data.isLock) {
      return {
        buttons: [
          {
            onClick: this.checkUserOrAdmin.bind(this),
            label: e.data.isAdmin ? 'Đặt quyền user' : 'Đặt quyền admin',
            icon: e.data.isAdmin ? 'fa fa-user-times' : 'fa fa-user-plus',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.editPassUser.bind(this),
            label: 'Sửa mật khẩu',
            icon: 'fa fa-street-view',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addRole.bind(this),
            label: 'Phân quyền role',
            icon: 'fa fa-anchor',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addGroupChat.bind(this, e.data),
            label: 'Phân quyền nhóm chát',
            icon: 'fa fa-users',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.onBtnClick1.bind(this),
            label: 'Thêm quyền web',
            icon: 'fa fa-recycle',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addCatalog.bind(this),
            label: 'Phân quyền catalog',
            icon: 'fa fa-map-signs',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.unlockCard.bind(this),
            label: 'Mở khóa tài khoản',
            icon: 'fa fa-unlock',
            class: 'btn-warning mg-5',
            hide: false
          },
          {
            onClick: this.deleteCard.bind(this),
            label: 'Xóa',
            icon: 'fa fa-trash-o',
            class: 'btn-danger',
            hide: false
          },
        ]
      }
    } else {
      return {
        buttons: [
          {
            onClick: this.checkUserOrAdmin.bind(this),
            label: e.data.isAdmin ? 'Đặt quyền user' : 'Đặt quyền admin',
            icon: e.data.isAdmin ? 'fa fa-user-times' : 'fa fa-user-plus',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.editPassUser.bind(this),
            label: 'Sửa mật khẩu',
            icon: 'fa fa-street-view',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addRole.bind(this),
            label: 'Phân quyền role',
            icon: 'fa fa-anchor',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addGroupChat.bind(this, e.data),
            label: 'Phân quyền nhóm chát',
            icon: 'fa fa-users',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.onBtnClick1.bind(this),
            label: 'Thêm quyền web',
            icon: 'fa fa-recycle',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.addCatalog.bind(this),
            label: 'Phân quyền catalog',
            icon: 'fa fa-map-signs',
            class: 'btn-primary mg-5',
            hide: false
          },
          {
            onClick: this.lockCard.bind(this),
            label: 'Khóa tài khoản',
            icon: 'fa fa-lock',
            class: 'btn-warning mg-5',
            hide: false
          },
        ]
      }
    }
  }

}
