


<div class="content-header row" style="margin: -10px;" [hidden]="isEdit">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/home"><a><i class="fa fa-home"></i> Trang chủ</a>
        </li>
        <li class="breadcrumb-item active">Danh sách K/H quản trị
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body" [hidden]="isEdit">
  <section id="configuration">
    <div class="row">
      <div class="col-12" style="padding: 0px;">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-2 w-100">
                  <fieldset class="form-group">
                      <label>Nội dung </label><br>
                      <input type="search" placeholder="Theo tên đăng nhập" class="form-control shadow-item" (change)="handleFilter()" name="filter" id="filter"
                             [(ngModel)]="filter.filter" />
                  </fieldset>
              </div>
            <div class="col-md-2 w-100">
                <div class="form-group">
                  <label for="">Trang Web</label>
                  <p-dropdown [options]="listWebs" [(ngModel)]="filter.webId" name="webId" [autoDisplayFirst]="false" (onChange)="getClientWebRoleList()" >
                    <ng-template let-item pTemplate="selectedItem">
                        <span style="vertical-align:middle; ">{{item.label}}</span>
                    </ng-template>
                    <ng-template let-car pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                </div>
              </div>
              <div class="col-md-2 w-100">
                <div class="form-group">
                  <label for="">Vai trò</label>
                  <p-dropdown [options]="userRoles" [(ngModel)]="filter.userRole" name="userRole" [autoDisplayFirst]="false" (onChange)="handleFilter()" >
                    <ng-template let-item pTemplate="selectedItem">
                        <span style="vertical-align:middle; ">{{item.label}}</span>
                    </ng-template>
                    <ng-template let-car pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                </div>
              </div>
              <div class="col-md-2 w-100">
                <div class="form-group">
                  <label for="">Quyền web</label>
                  <p-dropdown [options]="webRoleListsMuti" [(ngModel)]="filter.webRole" name="webRole" [autoDisplayFirst]="false" (onChange)="handleFilter()" >
                    <ng-template let-item pTemplate="selectedItem">
                        <span style="vertical-align:middle; ">{{item.label}}</span>
                    </ng-template>
                    <ng-template let-car pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                </div>
              </div>
           
              <div class="col-md-2 w-100">
                    <div class="row">
                      <div class="col-md-12">
                        <label style="visibility: hidden;">dddd </label><br />
                        <span class="">
                          <p-button label="" icon="pi pi-search" styleClass="mr-1" iconPos="left" (click)="handleFilter()"></p-button>
                          <p-button label="" icon="pi pi-refresh" styleClass="ui-button-secondary" iconPos="left" (click)="cancelSearch()"></p-button>
                        </span>
                      </div>
                    </div>
              </div>
              <div class="col-md-2 text-right">
                  <label style="visibility: hidden;">ddddddd</label><br />
                  <p-button label="Thêm mới" styleClass="p-button-sm mr-1" (click)="handleAddManager()" icon="fa fa-plus"></p-button>
                  <button pButton pRipple label="Export" class="p-button-sm" (click)="exportGrid()"></button>
              </div>
          </div>
          </div>

        </div>

        <div class="card">
          <div class="card-content collapse show">
            <div class=" card-dashboard" style="margin-top: 10px">
              <div id="DataTables_Table_0_wrapper1" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div class="" >

                  <ag-grid-angular 
 [modules]="modules" #agGrid
                                   style="width: 100%; height: 650px"
                                   id="myGrid"
                                   class="ag-theme-balham shadow"
                                   [rowData]="items?.dataList?.data"
                                   [columnDefs]="columnDefs"
                                   floatingFilter
                                   enableCellTextSelection=true
                                   [masterDetail]="true"
                                   [allowContextMenuWithControlKey]="true" [excelStyles]="excelStyles"  [getContextMenuItems]="getContextMenuItems"
                                   [detailCellRendererParams]="detailCellRendererParams"
                                   (gridReady)="onGridReady($event)"
                                   [defaultColDef]="defaultColDef"
                                   [groupUseEntireRow]="true"
                                   [animateRows]="true"
                                   [frameworkComponents]="frameworkComponents"
                                   [getRowHeight]="getRowHeight">
                  </ag-grid-angular>
              </div>

        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4">
            <form>
              Hiển thị:
              <select class="dropdown-custom shadow-border" [(ngModel)]="pageSize" name="pageSize"
                          (change)="handleChangePageSize($event)">
                      <option [value]="5">5</option>
                      <option [value]="10">10</option>
                      <option [value]="25">25</option>
                      <option [value]="50">50</option>
                      <option [value]="100">100</option>
                      <option [value]="1000000000">All</option>
                  </select>
              <span>Từ {{ countRecord.currentRecordStart }} đến {{ countRecord.currentRecordEnd }}
                trên tổng số
                {{ countRecord.totalRecord }} kết quả</span>
            </form>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4 d-flex justify-content-end">
            <app-paging (pageChange)="handleChangePaging($event)"></app-paging>
          </div>

        </div>
                </div>
              </div>
          </div>



        </div>

      </div>
    </div>
  </section>
</div>
<app-managerinfo [hidden]="!isEdit" (back)="onback()" [moduleTables]="moduleTables" [detailManager]="detailManager" [organizes]="organizes"></app-managerinfo>

<p-dialog [(visible)]="seachManager" baseZIndex="1" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <!-- <ngx-loading [show]="loading1" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <p-header >
      Tìm kiếm Tài khoản
  </p-header>
    <form action="" #manager="ngForm">
        <div class="row mb-1">
            <div class="col-md-3">
                <label for="">Trang Web: <span style="color:red">*</span></label>
                    <p-dropdown [options]="listWebs" [(ngModel)]="filterModal.webId" name="webId" [autoDisplayFirst]="false">
                      <ng-template let-item pTemplate="selectedItem">
                          <span style="vertical-align:middle; ">{{item.label}}</span>
                      </ng-template>
                      <ng-template let-car pTemplate="item">
                          <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                              <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
            </div>

            <div class="col-md-9">
              <label for="">Tổ chức</label>
              <p-dropdown appendTo="body" baseZIndex="100" [autoDisplayFirst]="false" 
                            [options]="organizes" [(ngModel)]="filterModal.perentId" required 
                            name="perentId" filter="true">
                            <ng-template let-item pTemplate="selectedItem">
                                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                            </ng-template>
                            <ng-template let-car pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                    <div style="font-size:14px;float:left;margin-top:4px">{{car.label}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
            </div>
            <!-- <div class="col-sm-12">
              <label for="">Chọn phòng ban</label> <br>
              <p-treeSelect [(ngModel)]="filterModal.org_id" [options]="departmentFiltes" display="chip" [metaKeySelection]="false" selectionMode="checkbox" appendTo="body" name="sub_prod_cd" placeholder="Chọn phòng ban"></p-treeSelect>
            </div> -->
          <div class="col-sm-12 form-group">
              <label>Tìm kiếm tên nhân viên</label> 
              <p-autoComplete  [suggestions]="results" [(ngModel)]="filterModal.employeeId" (onSelect)="selectedItem1($event)" name="employeeId" [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}" (completeMethod)="search($event)" field="name" >
                  <ng-template let-employee pTemplate="item">
                      <div class="country-item">
                          <div>{{employee?.name}}</div>
                      </div>
                  </ng-template>
              </p-autoComplete>
          </div>














<!-- 
            <div class="col-md-3">
                <label for="">Chọn tổ chức: <span style="color:red">*</span></label>
                    <select class="form-control" id="organizationCd" [(ngModel)]="organizationCd" name="organizationCd">
                        <option value="" disabled>Chọn tổ chức</option>
                        <option value="{{item.organizationCd}}" *ngFor="let item of organizes; let i= index">{{item.organizationName}}</option>
                    </select>
            </div>
            <div class="col-md-5">
                <label for="">Search số điện thoại: </label>
                        <input [ngbTypeahead]="search" (keyup)="getCardCustomers()" name="key_car"
                          style="padding: 19px;height: calc(2.3rem + 2px)" placeholder="Nhập số điện thoai để tìm kiếm" [resultFormatter]="formatter"
                          (selectItem)="selectedItem1($event)" [inputFormatter]="formatter" required value=""
                          type="text" [(ngModel)]="key_car" id="key_car" class="form-control w-100">
                        <span *ngIf="key_car" (click)="cancel()"><i class="sun-icon-x icon-input-search"></i></span>
            </div> -->
            <div class="col-md-12">
                <p-button label="Thêm mới" [disabled]="!detailcar" icon="pi pi-plus" styleClass="mr-1 p-button-sm" (onClick)="addUser()"></p-button>
                <button pButton pRipple label="Export" class="p-button-sm" (click)="exportGrid()"></button>
                <label for="" style="visibility: hidden;">Thêm mới </label>
            </div>
        </div>
        <div [hidden]="hideForm" class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8; padding: 0px;">
            <label style="font-weight: bold;">Thêm mới tài khoản login<span class="noti-number noti-number-on ml5">New</span></label>
          </div>
          <div [hidden]="hideForm">
              <form action="" #createFormUser="ngForm">
                <div class="row mb-2 mt-1">
                    <div class="col-md-3">
                        <label for="">Tên đăng nhập: <span style="color:red">*</span></label>
                           <input id="loginName" type="text" class="form-control" [(ngModel)]="modelUser.loginName" name="loginName" #loginName="ngModel">
                           <div *ngIf="loginName.errors && (loginName.dirty || loginName.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!loginName.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Mật khẩu: <span style="color:red">*</span></label>
                        <input type="password" class="form-control" class="form-control" [(ngModel)]="modelUser.password" name="password" #password="ngModel">
                        <div *ngIf="password.errors && (password.dirty || password.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!password.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Nhập lại mật khẩu: <span style="color:red">*</span></label>
                        <input type="password" class="form-control" [(ngModel)]="modelUser.userPasswordconfirm" name="userPasswordconfirm" #userPasswordconfirm="ngModel">
                        <div *ngIf="userPasswordconfirm.errors && (userPasswordconfirm.dirty || userPasswordconfirm.touched)"
                        class="alert-validation alert-danger">
                        <div [hidden]="!userPasswordconfirm.errors.required">
                          Tên khách hàng bắt buộc nhập!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="" style="visibility: hidden;">Lưu lại </label> <br>
                        <p-button label="Lưu" styleClass="mr-1" icon="pi pi-check p-button-sm" [disabled]="!createFormUser.form.valid" (onClick)="saveUser()"></p-button>
                        <p-button label="Hủy" styleClass=" p-button-danger p-button-sm" icon="pi pi-times" (click)="this.hideForm = !this.hideForm"></p-button>
                    </div>
                </div>
              </form>
          </div>

        <div class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8; padding: 0px;">
            <label style="font-weight: bold;">Thông tin tài khoản</label>
          </div>
        <div class="row">
            <ag-grid-angular 
 [modules]="modules" #agGrid
                                     style="width: 100%; height: 400px"
                                     id="myGrid"
                                     [allowContextMenuWithControlKey]="true" [excelStyles]="excelStyles"  [getContextMenuItems]="getContextMenuItems" 
                                     class="ag-theme-balham shadow"
                                     [rowData]="managerDetails"
                                     enableCellTextSelection=true
                                     [columnDefs]="columnDefs1"
                                     (gridReady)="onGridReady1($event)"
                                     [animateRows]="true"
                                     [getRowHeight]="getRowHeight1"
                                     [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
        </div>
      <p-footer >
       <div class="col-md-12 d-flex justify-content-end mt-1">
        <!-- <p-button label="Lưu lại" styleClass="mr-1" (click)="saveAgreeSch()"></p-button> -->
        <p-button label="Đóng"  styleClass="ui-button-secondary" (click)="seachManager = !seachManager"></p-button>
       </div>
    </p-footer>
    </form>
  </p-dialog>

  <p-dialog [(visible)]="displayChangPassword" modal="true" [style]="{width: '800px', height: 'auto'}">
    <p-header>
        Cập nhật mật khẩu tài khoản  [{{modelPass.userLogin}}]
    </p-header>
    <form #editSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
      <div class="box-body" style="padding:10px;">

        <div class="form-row" style="margin:10px 0px;">
          <label class="col-sm-4 control-label">Tên đăng nhập
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-8">
            {{modelPass.userLogin}}
          </div>
        </div>
        <div class="form-row" style="margin:10px 0px;">
          <label class="col-sm-4 control-label">Mật khẩu
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-8">
            <input type="password" class="form-control" name="userPassword" autocomplete="off" (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassword" required
              value="" #userPassword="ngModel" placeholder="Password">
            <div *ngIf="submitPass && !modelPass.userPassword " class="alert-validation alert-danger">
              <div *ngIf="!modelPass.userPassword">
                Password bắt buộc nhập!
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" style="margin:10px 0px;">
          <label class="col-sm-4 control-label">Nhập lại mật khẩu
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-8">
            <input type="password" class="form-control" name="userPassCf" autocomplete="off"  (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassCf" required
              #userPassCf="ngModel" id="userPassCf" placeholder="Nhập lại Password">
              <div *ngIf="submitPass && (confimPassword || !modelPass.userPassCf) " class="alert-validation alert-danger">
                <div *ngIf="!modelPass.userPassCf || confimPassword">
                  Nhập lại mật khẩu không đúng
                </div>
              </div>
          </div>
        </div>
        <div class="form-row" style="margin:10px 0px;">
          <div class="col-md-12 text-right">
            <p-button label="Lưu lại" styleClass="mr-1" (click)="saveChangePass(editSMSF.form)"></p-button>
          <p-button label="Hủy" styleClass="p-button-secondary" (click)="displayChangPassword = !displayChangPassword"></p-button>
          </div>

        </div>
      </div>

    </form>

</p-dialog>
