import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AgGridFn } from 'src/app/utils/common/function-common';
declare var jQuery: any;
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Action } from 'src/app/types/action.type';
import { ButtonRendererComponent1 } from 'src/app/viewcommon/renderer/button-renderer.component-1';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
@Component({
  selector: 'app-listrolepage',
  templateUrl: './listrolepage.component.html',
  styleUrls: ['./listrolepage.component.css']
})
export class ListrolepageComponent implements OnInit, OnChanges {
  @Input() dataFieldsInfo: any;
  public agGridFn = AgGridFn;
  public modules: Module[] = AllModules;
  public gridApi;
  public detailRowHeight;
  public detailCellRendererParams;
  public gridColumnApi;
  public frameworkComponents: any;
  public columnDefs;
  public defaultColDef;
  public getRowHeight;
  public colResizeDefault;
  public rowData: any[] = [];
  public gridflexdetails: any;
  public gridflexs: any;
  public objectAction: any;
  public detailRole: any;
  public display: boolean = false;
  public gridApi1;
  public gridColumnApi1;
  public columnDefs1;
  public getRowHeight1;
  public displayRole;
  public modelRole
  public detailrole
  public items: MenuItem[];
  public sourceActions: Action[] = [];
  public targetActions: Action[] = [];
  constructor(
    private api: ApiService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }
  cols: any[];
  dataDropdownClick
  onDropdownClick(role) {
    this.dataDropdownClick = role
  }
  ngOnChanges() {
    this.targetActions = _.cloneDeep(this.dataFieldsInfo.clientaction);
    this.items = [
      {
        label: 'Sửa role', icon: 'pi pi-refresh', command: (e) => {
          this.editRole();
        }
      },
      {
        label: 'Xóa all menu', icon: 'fa fa-trash', command: (e) => {
          this.delete();
        }
      },
    ];
    this.cols = [
      { field: 'title', header: 'Tên menu' },
    ];
    // this.dataFieldsInfo.roles.forEach(element1 => {
    //   element1.rolemenu.forEach(element => element.webRoleCd = element1.roleCd);
    // });
    this.gridflexs = this.dataFieldsInfo.view_grids_menu;
    this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus]
    this.gridflexs.forEach(column => {
      if (column.columnField === 'actions') {
        this.objectAction = column;
      }
    });
    this.onInit1();

  }

  delete() {
    if (confirm(`Hiện tại có ${this.dataDropdownClick.rolemenu.length} menu bạn có chắc chắn muốn xóa hết !`)) {
      if (this.dataDropdownClick.roleId) {
        this.api.delClientRoleMenu({ id: this.dataDropdownClick.roleId, webId: this.dataDropdownClick.webId, typeDel: 1 }).subscribe(results => {
          if (results.status === 'success') {
            const index = this.dataFieldsInfo.roles.findIndex(res => res.roleId === this.dataDropdownClick.roleId);
            this.dataFieldsInfo.roles[index].rolemenu = [];
            this.dataFieldsInfo.roles[index].rolemenu = [...this.dataFieldsInfo.roles[index].rolemenu];
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa menu thành công !'});
          } else {
            this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message});
          }
        })
      } else {
        const index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === this.dataDropdownClick.roleCd);
        this.dataFieldsInfo.roles[index].rolemenu = [];
        this.dataFieldsInfo.roles[index].rolemenu = [...this.dataFieldsInfo.roles[index].rolemenu];
      }

    }

  }

  selectsMenus = [];

  onInit() {
    this.columnDefs = [
      {
        headerName: 'Chọn',
        width: 100,
        field: 'checked',
        cellClass: ['border-right'],
        cellRenderer: (params) => {
          var input = document.createElement('input');
          input.type = "checkbox";
          console.log(params.data.checked)
          input.checked = params.data.checked;
          input.addEventListener('click', (event) => {
            params.value = !params.value;
            params.node.data.checked = params.value;
            if (params.value) {
              let menu = {
                action: params.data.action,
                menuCd: params.data.menuCd,
                menuId: params.data.menuId,
                menuRoleId: 0,
                roleCd: this.detailRole.roleCd,
                title: params.data.title,
                userId: params.data.userId,
                webId: params.data.webId,
                webRoleId: this.detailRole.roleId,
                tabCd: params.data.tabCd,
                intPos: params.data.intPos
              }
              this.selectsMenus.push(menu);
            } else {
              let index = this.selectsMenus.findIndex(d => d.menuCd === params.data.menuCd);
              this.selectsMenus.splice(index, 1);
            }
          });
          return input;
        }
      },
      ...this.agGridFn(this.gridflexs),
    ];
  }

  onInit1() {
    this.columnDefs1 = [
      {
        headerName: 'Tên menu',
        field: 'title',
        cellClass: ['border-right'],
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Tab',
        field: 'tabCd',
        width: 100,
        cellClass: ['border-right'],
        filter: 'agTextColumnFilter',
        editable: true,
      },
      {
        headerName: 'Sắp xếp',
        field: 'intPos',
        width: 100,
        cellClass: ['border-right'],
        filter: 'agTextColumnFilter',
        editable: true,
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 100,
        cellClass: ['border-right'],
        filter: 'agTextColumnFilter',
        editable: true,
      },
      {
        headerName: 'Thao tác', field: 'button', filter: '',
        cellRenderer: 'buttonRenderer',
        cellClass: ['border-right', 'text-center'],
        width: 100,
        pinned: 'right',
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handledelete.bind(this),
                label: 'Xóa',
                icon: 'fa fa-trash',
                class: 'btn-danger mr-1',
                show: true
              },
              {
                onClick: this.handleaddaction.bind(this),
                label: 'Thêm action',
                icon: 'fa fa-plus-circle',
                class: 'btn-primary',
                show: params.data.menuId > 0
              },

            ]
          };
        }
      },
    ];
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent1,
    };
    this.getRowHeight1 = params => {
      return 40
    }
  }

  deleteRole(role) {
    if (role.roleId) {
      if (confirm(`Nhóm quyền này đang chứa ${role.rolemenu.length} menu bạn chắc chắn xóa hết !`)) {
        this.api.delClientRoleMenu({ id: role.roleId, webId: role.webId, typeDel: 0 }).subscribe(results => {
          if (results.status === 'success') {
            const index = this.dataFieldsInfo.roles.findIndex(res => res.roleId === role.roleId);
            this.dataFieldsInfo.roles.splice(index, 1);
            this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa role thành công !'});
          } else {
            this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message});
          }
        })
      }

    } else {
      const index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === role.roleCd);
      this.dataFieldsInfo.roles.splice(index, 1);
      this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
    }
  }

  handledelete(e) {
    if (e.rowData.menuId > 0) {
      if (confirm(`Bạn chắc chắn muốn xóa menu ${e.rowData.title} khỏi danh sách quyền`)) {
        this.api.delClientRoleMenu({ id: e.rowData.menuRoleId, webId: e.rowData.webId, typeDel: 2 }).subscribe(results => {
          if (results.status === 'success') {
            let index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === e.rowData.roleCd);
            let indexChildren = this.dataFieldsInfo.roles[index].rolemenu.findIndex(res => res.menuId === e.rowData.menuId);
            this.dataFieldsInfo.roles[index].rolemenu.splice(indexChildren, 1);
            this.dataFieldsInfo.roles[index].rolemenu = [...this.dataFieldsInfo.roles[index].rolemenu];
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa role thành công !'});
          } else {
            this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results.message});
          }
        })
      }
    } else {
      let index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === e.rowData.roleCd);
      let indexChildren = this.dataFieldsInfo.roles[index].rolemenu.findIndex(res => res.menuCd === e.rowData.menuCd);
      this.dataFieldsInfo.roles[index].rolemenu.splice(indexChildren, 1);
      this.dataFieldsInfo.roles[index].rolemenu = [...this.dataFieldsInfo.roles[index].rolemenu]
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  addMenuToRole(role) {
    console.log(role)
    this.detailRole = role;
    this.dataFieldsInfo.menus.forEach(element => {
      if (role.rolemenu.map(function (e) { return e.menuCd }).indexOf(element.menuCd) > -1) {
        element.checked = true
      } else {
        element.checked = false
      }
    });
    this.onInit();
    this.display = true;
  }

  saveMenu() {
    const index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === this.detailRole.roleCd);
    this.dataFieldsInfo.roles[index].rolemenu = _.uniqBy(this.dataFieldsInfo.roles[index].rolemenu.concat(this.selectsMenus), 'menuCd')
    this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles];
    this.display = false;
  }

  editRole() {
    this.detailrole = { ...this.dataDropdownClick }
    this.modelRole = { ...this.dataDropdownClick };
    this.displayRole = true;
  }

  saveRole() {
    if (this.detailRole.roleId) {
      let index = this.dataFieldsInfo.roles.findIndex(res => res.roleId === this.detailRole.roleId);
      this.dataFieldsInfo.roles[index] = { ...this.dataFieldsInfo.roles[index], ...this.modelRole }
    } else {
      let index = this.dataFieldsInfo.roles.findIndex(res => res.roleCd === this.detailRole.roleCd);
      this.dataFieldsInfo.roles[index] = { ...this.dataFieldsInfo.roles[index], ...this.modelRole }
    }
    this.dataFieldsInfo.roles = [...this.dataFieldsInfo.roles]
    this.displayRole = false;
  }

  saveAll() {
    let selectMenu = [];
    this.selectsMenus = [];
    this.dataFieldsInfo.menus.forEach(element => {
      element.checked = true;
      let menu = {
        action: element.action,
        menuCd: element.menuCd,
        menuId: element.menuId,
        menuRoleId: 0,
        roleCd: this.detailRole.roleCd,
        title: element.title,
        userId: element.userId,
        webId: element.webId,
        webRoleId: this.detailRole.roleId,
        tabCd: element.tabCd,
        intPos: element.intPos
      }
      selectMenu.push(menu);
    });
    this.dataFieldsInfo.menus = [...this.dataFieldsInfo.menus]
    this.selectsMenus = [...selectMenu];
  }

  displayAction = false;
  detailMenuInRole
  handleaddaction(e) {
    this.targetActions = _.cloneDeep(this.dataFieldsInfo.clientaction);
    console.log(this.dataFieldsInfo.clientaction)
    console.log(e.rowData.action)
    this.detailMenuInRole = e.rowData;
    if (e.rowData.action) {
      const actions = e.rowData.action.split(',');
      this.sourceActions = this.dataFieldsInfo.clientaction.filter(word => new RegExp(word.actionCd).test(e.rowData.action))
      this.targetActions = this.dataFieldsInfo.clientaction.filter(word => !new RegExp(word.actionCd).test(e.rowData.action))
      this.targetActions.forEach(res => res.menuId = e.rowData.menuId);
      this.sourceActions.forEach(res => res.menuId = e.rowData.menuId);
    } else {
      this.targetActions = _.cloneDeep(this.dataFieldsInfo.clientaction);
      this.sourceActions = [];
      this.targetActions.forEach(res => res.menuId = e.rowData.menuId);
    }
    this.displayAction = true;
  }

  saveActionToMenu() {
    this.targetActions.forEach(res => res.menuId = 0);
    if (this.sourceActions && this.sourceActions.length > 0) {
      let indexRole = this.dataFieldsInfo.roles.findIndex(res => res.roleId === this.detailMenuInRole.webRoleId);
      let index = this.dataFieldsInfo.roles[indexRole].rolemenu.findIndex(res => res.menuId === this.sourceActions[0].menuId);
      this.dataFieldsInfo.roles[indexRole].rolemenu[index].action = this.sourceActions.map(res => res.actionCd).toString();
      this.dataFieldsInfo.roles[indexRole].rolemenu = [...this.dataFieldsInfo.roles[indexRole].rolemenu]
    } else {
      let indexRole = this.dataFieldsInfo.roles.findIndex(res => res.roleId === this.detailMenuInRole.webRoleId);
      let index = this.dataFieldsInfo.roles[indexRole].rolemenu.findIndex(res => res.menuId === this.detailMenuInRole.menuId);
      this.dataFieldsInfo.roles[indexRole].rolemenu[index].action = '';
      this.dataFieldsInfo.roles[indexRole].rolemenu = [...this.dataFieldsInfo.roles[indexRole].rolemenu]
    }
    this.displayAction = false;
  }
}
