
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AgGridFn } from 'src/app/utils/common/function-common';
import { Component, OnInit, AfterViewChecked, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MessageService} from 'primeng/api';
import { NormalButtonRendererComponent } from '../viewcommon/renderer/normal-button-renderer.component';
import { PagingComponent } from '../viewcommon/renderer/paging/paging.component';
import { AvatarComponent } from '../viewcommon/avatar/avatar.component';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
declare var jQuery: any;
@Component({
  selector: 'app-setting-role',
  templateUrl: './setting-role.component.html',
  styleUrls: ['./setting-role.component.css']
})
export class SettingRoleComponent implements OnInit , AfterViewChecked {
  public agGridFn = AgGridFn;
  public modules: Module[] = AllModules;
  public columnDefs;
  public detailRowHeight;
  public defaultColDef;
  public frameworkComponents;
  public groupDefaultExpanded;
  public detailCellRendererParams;
  public gridApi: any;
  public clientWidth: any;
  public gridColumnApi: any;
  public objectAction: any;
  public objectActionChildren: any;
  public items: any;
  public gridflexs: any;
  public getRowHeight;
  public pageSize = 25;
  public loading = false;
  public currentPage = 0;
  public gridflexdetails :any;
  public filter = {
    filter: '',
  };
  public dataFieldsInfo;
  public moduleTables
  public isEdit: boolean = false;
  public countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  public webType: any = [];
  @ViewChild(PagingComponent, { static: true }) pagingComponent: PagingComponent;
  public listWebs: any = [];
  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private apiService: ApiService) {
    this.defaultColDef = {
      resizable: true,
    };
    this.getRowHeight = (params) => {
      return 90;
    }
    this.frameworkComponents = {
      buttonRenderer: NormalButtonRendererComponent,
      avatarRenderer: AvatarComponent,
    };
  }

  ngOnInit() {
    this.clientWidth = jQuery('#myGrid')[0].clientWidth;
    this.loading = true;
    this.getClientWebList();
    this.getConfigs();
    this.pagingComponent.pageSize = this.pageSize;
    this.loading = false;
  }

  getClientWebList() {
    this.apiService.getClientWebList('').subscribe(res => {
        this.listWebs = res.data;
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  getConfigs() {
    this.loading = true;
    this.apiService.getClientWebPage(this.filter.filter, this.clientWidth, this.currentPage, this.pageSize)
      .subscribe(res => {
        this.items = res.data;
        if (this.currentPage === 0) {
          this.gridflexs =  this.items.gridflexs;
          this.gridflexdetails =  this.items.gridflexdetails;
          this.gridflexs.forEach(column => {
            if (column.columnField === 'actions') {
              this.objectAction = column;
            }
          });
          this.gridflexdetails.forEach(column => {
            if (column.columnField === 'actions') {
              this.objectAction = column;
            }
          });
        }
        if (this.gridApi) {
          this.gridApi.setRowData(this.items.dataList.data);
        }
        this.initTableGrid();
        this.pagingComponent.total = this.items.dataList.recordsTotal;
        this.countRecord.totalRecord = this.items.dataList.recordsTotal;
        this.countRecord.currentRecordStart = this.currentPage + 1;
        if ((this.items.dataList.recordsTotal - this.currentPage) > this.pageSize) {
          this.countRecord.currentRecordEnd = this.currentPage + Number(this.pageSize);
        } else {
          this.countRecord.currentRecordEnd = this.items.dataList.recordsTotal;
        }
        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }

  handleChangePaging(name) {
    this.currentPage = this.pagingComponent.getOffset();
    this.pageSize = this.pagingComponent.pageSize;
    this.getConfigs();
  }

  handleFilter() {
    this.currentPage = 0;
    this.getConfigs();
  }

  initTableGrid() {
    this.columnDefs = [
      ...this.agGridFn(this.gridflexs),
      {
        headerName: 'Thao tác', field: 'button', filter: '',
        cellRenderer: 'buttonRenderer',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: this.objectAction ? this.objectAction.columnWidth : 100,
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handleEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-dropbox shadow',
              },
              // {
              //   onClick: this.handleDelete.bind(this),
              //   label: 'Xóa',
              //   icon: 'fa fa-trash',
              //   class: 'btn-google shadow',
              // },
            ]
          };
        }
      },
    ];
    this.groupDefaultExpanded = 0;
    this.detailRowHeight = 400;
    this.detailCellRendererParams = {
      detailGridOptions: {
        // domLayout : "autoHeight",
        frameworkComponents: {
          buttonRenderer1: NormalButtonRendererComponent
        },
        getRowHeight : (params) => {
          return 40;
        },
        columnDefs: [
          ...this.agGridFn(this.gridflexdetails),
          {
            headerName: 'Chức năng',
            field: 'button',
            filter: '',
            pinned: this.objectActionChildren ? this.objectActionChildren.pinned : "",
            width: this.objectActionChildren ? this.objectActionChildren.columnWidth : 100,
            cellRenderer: 'buttonRenderer1',
            cellClass: this.objectActionChildren ? this.objectActionChildren.cellClass : ['border-right'],
            cellRendererParams: params =>  {
              return {
                buttons: [
                  // {
                  //   onClick: this.approveService.bind(this),
                  //   label: 'Xác nhận dịch vụ',
                  //   icon: 'fa fa-address-card',
                  //   class: 'btn-microsoft',
                  //   show: true
                  // },
                ]
              }
            },
          },
        ],
      },
      getDetailRowData: function (params) {
        params.successCallback(params.data.clientWebRoles);
      }
    };

  }

  handleEdit(e) {
    // this.callApiGetInfo(e.rowData.webId);
    this.router.navigate([`/setting-role/${e.rowData.webId}`])
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if(this.items && this.items.dataList.data && this.items.dataList.data.length> 0) {
      this.gridApi.setRowData(this.items.dataList.data);
    }
  }

  handleChangePageSize(event) {
    this.pageSize = +event.target.value;
    this.pagingComponent.pageSize = +event.target.value;
    this.getConfigs();
  }

  cancelSearch() {
    this.filter = {
      filter: ''
    };
    this.getConfigs();
  }
  
  handleAddWeb() {
    this.dataFieldsInfo = null;
    this.callApiGetInfo(0)
  }

  callApiGetInfo(webId) {
    this.apiService.getClientWebInfoManager(webId).subscribe(results => {
      if(results.status === 'success') {
        this.dataFieldsInfo = results.data;
      }
    })
  }

  onback() {
    this.isEdit = false;
    this.getConfigs();
  }
}

