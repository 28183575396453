import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'child-avatar',
  template: `
    <div>
    <img *ngIf="value" src="{{value}}" alt="" style="width:60px; height: 60px; border-radius: 50%">
    
    <img *ngIf="!value" src="/assets/images/avatar.jpg" alt="" style="max-width: 50%">
    </div>
    `
})
export class AvatarComponent implements OnInit {
  value: string
  constructor() { }

  agInit(params: any) {
    this.value = params.value;
  }
  ngOnInit() {
  }

}
