<form action="" #createCustomer="ngForm" *ngIf="dataForm && dataForm?.group_fields && (screenForm === 'screen-width')" style="margin-top: -20px;">
  <div *ngIf="dataForm && dataForm?.group_fields" style="margin-right: -15px;">

    <div class="row wrapper" *ngIf="isFormProfile">
      <div class="left" *ngIf="!displayFiles; else templatesFile">
        <ng-container *ngFor="let fielsParent of dataForm?.group_fields ; let idx = index">
          <div class="row" *ngIf="!displayFiles; else templatesFile">
            <div class="col-md-12 " [ngClass]="{'mt-2': idx > 0}" style="border-bottom: 1px solid #98a4b8; padding: 0;">
              <label style="font-weight: bold;">{{fielsParent.group_name}}</label>
            </div>
            <ng-container *ngFor="let element of fielsParent?.fields;let i=index">
              <!-- <div [ngClass]="{'custom_image': dataForm.formUser}" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
                *ngIf="element.columnType === 'image'" class="{{element.columnClass}} custom_image">
                <div class="form-group">
                  <label style="font-weight: bold;">Ảnh đại diện</label>
                  <div class="d-flex">
                    <div class="upload-area d-inline-flex align-items-center" *ngIf="!element[element.field_name]">
                      <div class="text-center p-3 ml-auto mr-auto" style="color: #007ad9;">
                        <span class="fa fa-plus"></span><br>
                        Thêm mới
                      </div>
                      <input (change)="onUploadOutput($event, fielsParent)"
                        accept="image/jpeg,image/png,image/jpg,image/gif" type="file" name="myfile1" class="input-file">
                    </div>
                    <div *ngIf="element[element.field_name]" class="upload-area uploaded d-inline-flex align-items-center"
                      style="justify-content:center">
                      <ng-container>
                        <button type="button" (click)="deleteAvatar(fielsParent, element.field_name)"
                          class="btn btn-danger-ct rounded-circle btn-close"><span class="fa fa-remove"></span></button>
                        <img id="img" src={{element[element.field_name]}} alt="" class="preview">
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngIf="anotherType == true">
                    <br>
                    <span class="btn bg-gray-light text-primary">{{fileName}} &nbsp;<span (click)="closeFile()"
                        class="oi oi-x text-secondary"></span></span>
                  </ng-container>
      
                </div>
              </div> -->
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
                *ngIf="element.columnType === 'input' && (element.data_type === 'float' || element.data_type === 'nvarchar')"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                  name={{element.field_name}} [disabled]="element.isDisable"
                  [required]="element.isRequire && element.isVisiable">
                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
      
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'input' && element.data_type === 'int'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                  name={{element.field_name}} [disabled]="element.isDisable"
                  [required]="element.isRequire && element.isVisiable">
                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
      
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'dropdown'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label> <br>
                <p-dropdown appendTo="body" baseZIndex="100" [autoDisplayFirst]="false" [showClear]="true"
                  [disabled]="element.isDisable" [options]="element.options"
                  [required]="element.isRequire && element.isVisiable" [(ngModel)]="element[element.field_name]"
                  name={{element.field_name}} filter="true">
                  <ng-template let-item pTemplate="selectedItem">
                    <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                  </ng-template>
                  <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                      <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <div *ngIf="element.isRequire && submit && (!element[element.field_name] || element[element.field_name] === '')"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'number'" class="{{element.columnClass}}"
                style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label>
                <input type="number" class="form-control" (change)="isNumber($event)" [(ngModel)]="element[element.field_name]"
                  name={{element.field_name}} [disabled]="element.isDisable"
                  [required]="element.isRequire && element.isVisiable">
                <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                  <div [hidden]="!element?.message?.Required">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'currency'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label>
                <input maxLength=18 type="text" (keypress)="numberOnly($event)" (change)="changePrice($event, element.field_name, fielsParent, i)"
                  class="form-control" [(ngModel)]="element[element.field_name]" currency name={{element.field_name}}
                  [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
                <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                  <div [hidden]="!element?.message?.Required">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'checkbox'"
                class="{{element.columnClass}} d-flex align-items-end" style="margin-top: 10px;">
                <p-checkbox name={{element.field_name}} binary="true" label="{{element.columnLabel}} :"
                  [required]="element.isRequire && element.isVisiable" [disabled]="element.isDisable"
                  [(ngModel)]="element[element.field_name]"></p-checkbox>
              </div>
              
              <ng-container *ngIf="element.columnType === 'textarea' && (element.field_name === 'res_Add1' || element.field_name === 'trad_Add1');else contentTextarea">
                <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                  <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                  <p-button label="Tách địa chỉ" icon="ft-scissors" styleClass="p-button-sm" title="Chi tiết địa chỉ" iconPos="left" (click)="sliptAddress(element[element.field_name],element.field_name, fielsParent)"></p-button>
                  <textarea type="text" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" class="form-control" (change)="saveAddress($event.target.value)" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
                </div>
                <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                  <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                  <p-button label="Copy địa chỉ" icon="ft-repeat" styleClass="p-button-sm" title="Coppy địa chỉ thường chú" iconPos="left" (click)="coppyAddress1( fielsParent)"></p-button>
                  <textarea type="text" class="form-control" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
                </div>
              </ng-container>
              <ng-template #contentTextarea>
                <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                    *ngIf="element.isRequire">*</span></label>
                <textarea type="text" class="form-control" (change)="saveAddress($event.target.value)"
                  [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable"
                  [required]="element.isRequire && element.isVisiable"></textarea>
                  <div *ngIf="element.isRequire && submit && !element[element.field_name] && !element.isDisable"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              </ng-template>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'select'" class="{{element.columnClass}}"
                style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                    style="color:red" *ngIf="element.isRequire">*</span></label>
                <select class="form-control" [(ngModel)]="element[element.field_name]" name={{element.field_name}}
                  [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
                  <option *ngFor="let option of element.options ; let i=index" value="{{option.value}}">
                    {{option.label}}</option>
                </select>
                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div> 
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datetime'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                    *ngIf="element.isRequire">*</span></label> <br>
                <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                  [(ngModel)]="element[element.field_name]" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                  dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datefulltime'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                    *ngIf="element.isRequire">*</span></label> <br>
                <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                  [(ngModel)]="element[element.field_name]" [monthNavigator]="true" showTime="true" hourFormat="24" [yearNavigator]="true"
                  yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                  dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
                <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                  class="alert-validation alert-danger">
                  <div [hidden]="element[element.field_name]">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'timeonly'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                    *ngIf="element.isRequire">*</span></label> <br>
                <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                  [(ngModel)]="element[element.field_name]" [timeOnly]="true" inputId="timeonly"
                  [required]="element.isRequire && element.isVisiable" placeholder="HH:mm" name={{element.field_name}}>
                </p-calendar>
                <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                  <div [hidden]="!element?.message?.Required">
                    Trường bắt buộc nhập!
                  </div>
                </div>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'multiselect'"
                class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :: <span
                    style="color:red" *ngIf="element.isRequire">*</span></label> <br>
                <p-multiSelect [options]="element.options" [(ngModel)]="element[element.field_name]"
                  name={{element.field_name}} defaultLabel="Select a option" optionLabel="name" display="chip">
                </p-multiSelect>
              </div>
            </ng-container>
          </div>
          
        </ng-container>
          <!-- <div class="social_media">
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
            </ul>
        </div> -->
      </div>
      <div class="right">
        <ng-container *ngFor="let fielsParent of dataForm?.group_fields ; let idx = index">
          <ng-container *ngFor="let element of fielsParent?.fields;let i=index">
            <div style="position: relative;" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
            *ngIf="element.columnType === 'image'">
              <img src="{{element[element.field_name] ? element[element.field_name] : '../../../assets/images/img_avatar.png'}}" alt="user" class="w-100">
              <label for="file-input" *ngIf="!element[element.field_name]"><span class="pi pi-plus icon-plus" ></span></label>
              <button *ngIf="element[element.field_name]" style="padding: 0.5rem 0.7rem;position: absolute;right: -10px;top: -16px;" type="button" (click)="deleteAvatar(fielsParent, element.field_name)"
              class="btn btn-danger-ct rounded-circle btn-close"><span class="fa fa-remove"></span></button>
              <h4>NGUYỄN TIẾN DŨNG</h4>
              <p>Vị trí: Nhân viên</p>
              <input (change)="onUploadOutput($event, fielsParent)" style="display: none;"
              accept="image/jpeg,image/png,image/jpg,image/gif" type="file" id="file-input" name="myfile1" class="input-file">
              <hr>
              <button pButton pRipple type="button" label="Tài liệu" *ngIf="!displayFiles && dataForm?.metas?.length > 0" (click)="showListFile()" icon="pi pi-file-pdf" class="p-button-raised p-button-info"></button>
              <button pButton pRipple type="button" label="Thông tin chung" *ngIf="displayFiles" (click)="showListFile()" icon="pi pi-th-large" class="p-button-raised p-button-info"></button>
            </div>
            </ng-container>
        </ng-container>
    </div>

    <!-- <ng-template #templatesFile>
      <div class="left">
        <app-list-file-customer *ngIf="displayFiles" [dataForm]="dataForm" (callbackformFile)="onBackFile()"></app-list-file-customer>
      </div>
    </ng-template> -->

   </div>

   <div *ngIf="!isFormProfile">
    <ng-container *ngFor="let fielsParent of dataForm?.group_fields ; let idx = index" >
      <div class="">
        <div class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8">
          <label style="font-weight: bold;">{{fielsParent.group_name}}</label>
        </div>
        <div class="row">
          <ng-container *ngFor="let element of fielsParent?.fields;let i=index">
            <!-- <div [ngClass]="{'custom_image': dataForm.formUser}" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
              *ngIf="element.columnType === 'image'" class="{{element.columnClass}} custom_image">
              <div class="form-group">
                <label style="font-weight: bold;">Ảnh đại diện</label>
                <div class="d-flex">
                  <div class="upload-area d-inline-flex align-items-center" *ngIf="!element[element.field_name]">
                    <div class="text-center p-3 ml-auto mr-auto" style="color: #007ad9;">
                      <span class="fa fa-plus"></span><br>
                      Thêm mới
                    </div>
                    <input (change)="onUploadOutput($event, fielsParent)"
                      accept="image/jpeg,image/png,image/jpg,image/gif" type="file" name="myfile1" class="input-file">
                  </div>
                  <div *ngIf="element[element.field_name]" class="upload-area uploaded d-inline-flex align-items-center"
                    style="justify-content:center">
                    <ng-container>
                      <button type="button" (click)="deleteAvatar(fielsParent, element.field_name)"
                        class="btn btn-danger-ct rounded-circle btn-close"><span class="fa fa-remove"></span></button>
                      <img id="img" src={{element[element.field_name]}} alt="" class="preview">
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngIf="anotherType == true">
                  <br>
                  <span class="btn bg-gray-light text-primary">{{fileName}} &nbsp;<span (click)="closeFile()"
                      class="oi oi-x text-secondary"></span></span>
                </ng-container>

              </div>
            </div> -->
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
              *ngIf="element.columnType === 'input' && (element.data_type === 'float' || element.data_type === 'nvarchar')"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>

            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'input' && element.data_type === 'int'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>

            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'dropdown'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label> <br>
              <p-dropdown appendTo="body" baseZIndex="100" [autoDisplayFirst]="false" [showClear]="true"
                [disabled]="element.isDisable" [options]="element.options"
                [required]="element.isRequire && element.isVisiable" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} filter="true">
                <ng-template let-item pTemplate="selectedItem">
                  <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                </ng-template>
                <ng-template let-car pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="element.isRequire && submit && (!element[element.field_name] || element[element.field_name] === '')"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'number'" class="{{element.columnClass}}"
              style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="number" class="form-control" (change)="isNumber($event)" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'currency'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input maxLength=18 type="text" (keypress)="numberOnly($event)" (change)="changePrice($event, element.field_name, fielsParent, i)"
                class="form-control" [(ngModel)]="element[element.field_name]" currency name={{element.field_name}}
                [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'checkbox'"
              class="{{element.columnClass}} d-flex align-items-end" style="margin-top: 10px;">
              <p-checkbox name={{element.field_name}} binary="true" label="{{element.columnLabel}} :"
                [required]="element.isRequire && element.isVisiable" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]"></p-checkbox>
            </div>
            
            <ng-container *ngIf="element.columnType === 'textarea' && (element.field_name === 'res_Add1' || element.field_name === 'trad_Add1');else contentTextarea">
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                <p-button label="Tách địa chỉ" icon="ft-scissors" styleClass="p-button-sm" title="Chi tiết địa chỉ" iconPos="left" (click)="sliptAddress(element[element.field_name],element.field_name, fielsParent)"></p-button>
                <textarea type="text" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" class="form-control" (change)="saveAddress($event.target.value)" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                <p-button label="Copy địa chỉ" icon="ft-repeat" styleClass="p-button-sm" title="Coppy địa chỉ thường chú" iconPos="left" (click)="coppyAddress1( fielsParent)"></p-button>
                <textarea type="text" class="form-control" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
              </div>
            </ng-container>
            <ng-template #contentTextarea>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label>
              <textarea type="text" class="form-control" (change)="saveAddress($event.target.value)"
                [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable"></textarea>
                <div *ngIf="element.isRequire && submit && !element[element.field_name] && !element.isDisable"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            </ng-template>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'select'" class="{{element.columnClass}}"
              style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <select class="form-control" [(ngModel)]="element[element.field_name]" name={{element.field_name}}
                [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
                <option *ngFor="let option of element.options ; let i=index" value="{{option.value}}">
                  {{option.label}}</option>
              </select>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div> 
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datetime'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datefulltime'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [monthNavigator]="true" showTime="true" hourFormat="24" [yearNavigator]="true"
                yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'timeonly'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [timeOnly]="true" inputId="timeonly"
                [required]="element.isRequire && element.isVisiable" placeholder="HH:mm" name={{element.field_name}}>
              </p-calendar>
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'multiselect'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :: <span
                  style="color:red" *ngIf="element.isRequire">*</span></label> <br>
              <p-multiSelect [options]="element.options" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} defaultLabel="Select a option" optionLabel="name" display="chip">
              </p-multiSelect>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
   </div>
  
  </div>

  <div class="row " style="margin-top: 20px; margin-bottom: 10px" *ngIf="titleForm.value != 'Line'">
    <div class="col-md-12 d-flex justify-content-end">
      <p-button label="Xác minh chứng minh thư" styleClass="mr-1" [disabled]="verifiedCard" *ngIf="xacminhcmt && dataForm && dataForm.metas.length > 0" (click)="Fnxacminhcmt()"></p-button>
      <p-button label="Lưu lại" *ngIf="titleForm.value != 'View'" styleClass="mr-1 p-button-sm" icon="pi pi-check" (click)="saveScheme(createCustomer)">
      </p-button>
      <div *ngIf="backCallApi; else closeButtonNoCallApi">
        <p-button label="Đóng"  (click)="backpageCallApi()" icon="pi pi-times" styleClass="p-button-secondary p-button-sm"></p-button>
      </div>
      <ng-template #closeButtonNoCallApi>
        <p-button label="Đóng" *ngIf="!backCallApi" (click)="backpage()" icon="pi pi-times" styleClass="p-button-secondary p-button-sm">
        </p-button>
     </ng-template>
   
    </div>
  </div>
</form>
<form action="" #createCustomer="ngForm" *ngIf="dataForm && dataForm?.group_fields && (screenForm === 'screen-height')" style="margin-top: -20px;" 
  [ngClass]="{'custom_form': dataForm.formUser}">
  <div class="row">
    <div class="col-md-6 mt-2" style="padding: 0;" *ngFor="let fielsParent of dataForm?.group_fields ; let idx = index">
        <div class="col-md-12 d-flex justify-content-center align-items-end">
            <h3 class="form-text" >{{fielsParent.group_name}}</h3>
        </div>
        <hr>
        <ng-container *ngFor="let element of fielsParent?.fields;let i=index">
          <div class="col-md-12 d-flex mt-1" [ngClass]="{'isEmpty': element.isEmpty}"
          *ngIf="element.columnType === 'input' && (element.data_type === 'nvarchar')">
            <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
            <div class="col-md-7 input">
              <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
              name={{element.field_name}} [disabled]="element.isDisable"
              [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
              class="alert-validation alert-danger">
              <div [hidden]="element[element.field_name]">
                Trường bắt buộc nhập!
              </div>
            </div>
            </div>
        </div>

          <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'input' && ((element.data_type === 'int') || (element.data_type === 'float'))"
          class="col-md-12 d-flex mt-1">
            <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
            <div class="col-md-7 input">
                <input type="text" class="form-control text-right" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
                  <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                    class="alert-validation alert-danger">
                    <div [hidden]="element[element.field_name]">
                      Trường bắt buộc nhập!
                    </div>
                  </div>
            </div>
        </div>

          <div  [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'dropdown'"
          class="col-md-12 d-flex mt-1" >
            <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
            <div class="col-md-7 input">
              <p-dropdown appendTo="body" baseZIndex="100" [autoDisplayFirst]="false" [showClear]="true"
              [disabled]="element.isDisable" [options]="element.options"
              [required]="element.isRequire && element.isVisiable" [(ngModel)]="element[element.field_name]"
              name={{element.field_name}} filter="true">
              <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
              </ng-template>
              <ng-template let-car pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                  <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <div *ngIf="element.isRequire && submit && (!element[element.field_name] || element[element.field_name] === '')"
              class="alert-validation alert-danger">
              <div [hidden]="element[element.field_name]">
                Trường bắt buộc nhập!
              </div>
            </div>
            </div>
        </div>

        <div   class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'number'" >
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <div class="col-md-7 input">
            <input type="number" class="form-control text-right" (change)="isNumber($event)" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
          </div>
      </div>

        <div   class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'currency'">
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <div class="col-md-7 input">
              <input maxLength=18 type="text" (keypress)="numberOnly($event)" (change)="changePrice($event, element.field_name, fielsParent, i)"
              class="form-control text-right" [(ngModel)]="element[element.field_name]" currency name={{element.field_name}}
              [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
            <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
              <div [hidden]="!element?.message?.Required">
                Trường bắt buộc nhập!
              </div>
            </div>
          </div>
      </div>
        <div  class="col-md-12 d-flex mt-1"  [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'checkbox'" >
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <div class="col-md-7 input">
            <p-checkbox name={{element.field_name}} binary="true" label="{{element.columnLabel}} :"
            [required]="element.isRequire && element.isVisiable" [disabled]="element.isDisable"
            [(ngModel)]="element[element.field_name]"></p-checkbox>
          </div>
      </div>
      <ng-container *ngIf="element.columnType === 'textarea' && (element.field_name === 'res_Add1' || element.field_name === 'trad_Add1');else contentTextarea">
        <div class="col-md-12 d-flex mt-1"  [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"  >
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <p-button label="Tách địa chỉ" icon="ft-scissors" styleClass="p-button-sm" title="Chi tiết địa chỉ" iconPos="left" (click)="sliptAddress(element[element.field_name],element.field_name, fielsParent)"></p-button>
          <div class="col-md-7 input">
            <textarea type="text" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" class="form-control" (change)="saveAddress($event.target.value)" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
          </div>
      </div>
        <div class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'" >
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <p-button label="Copy địa chỉ" icon="ft-repeat" styleClass="p-button-sm" title="Coppy địa chỉ thường chú" iconPos="left" (click)="coppyAddress1( fielsParent)"></p-button>
          <div class="col-md-7 input">
            <textarea type="text" class="form-control" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
          </div>
      </div>
        </ng-container>
        <ng-template #contentTextarea>
          <div  class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea'" >
            <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
            <div class="col-md-7 input">
              <textarea type="text" class="form-control" (change)="saveAddress($event.target.value)"
              [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable"
              [required]="element.isRequire && element.isVisiable"></textarea>
              <div *ngIf="element.isRequire && submit && !element[element.field_name] && !element.isDisable"
              class="alert-validation alert-danger">
              <div [hidden]="element[element.field_name]">
                Trường bắt buộc nhập!
              </div>
            </div>
            </div>
        </div>
        </ng-template>
        <div  class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'select'">
          <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
          <div class="col-md-7 input">
            <select class="form-control" [(ngModel)]="element[element.field_name]" name={{element.field_name}}
            [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
            <option *ngFor="let option of element.options ; let i=index" value="{{option.value}}">
              {{option.label}}</option>
          </select>
          <div *ngIf="element.isRequire && submit && !element[element.field_name]"
            class="alert-validation alert-danger">
            <div [hidden]="element[element.field_name]">
              Trường bắt buộc nhập!
            </div>
          </div>
          </div>
      </div>
      <div class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datetime'">
        <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
        <div class="col-md-7 input">
          <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
              [(ngModel)]="element[element.field_name]" [monthNavigator]="true" [yearNavigator]="true"
              yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
              dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
            <div *ngIf="element.isRequire && submit && !element[element.field_name]"
              class="alert-validation alert-danger">
              <div [hidden]="element[element.field_name]">
                Trường bắt buộc nhập!
              </div>
            </div>
        </div>
    </div>

      <div class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datefulltime'">
        <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
        <div class="col-md-7 input">
          <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
          [(ngModel)]="element[element.field_name]" [monthNavigator]="true" showTime="true" hourFormat="24" [yearNavigator]="true"
          yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
          dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
          class="alert-validation alert-danger">
          <div [hidden]="element[element.field_name]">
            Trường bắt buộc nhập!
          </div>
        </div>
        </div>
    </div>
      <div class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'timeonly'">
        <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
        <div class="col-md-7 input">
                      <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
              [(ngModel)]="element[element.field_name]" [timeOnly]="true" inputId="timeonly"
              [required]="element.isRequire && element.isVisiable" placeholder="HH:mm" name={{element.field_name}}>
            </p-calendar>
            <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
              <div [hidden]="!element?.message?.Required">
                Trường bắt buộc nhập!
              </div>
            </div>
        </div>
    </div>

      <div class="col-md-12 d-flex mt-1" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'multiselect'">
        <label for="" class="col-md-5 form-label"  [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
        <div class="col-md-7 input">
              <p-multiSelect [options]="element.options" [(ngModel)]="element[element.field_name]"
              name={{element.field_name}} defaultLabel="Select a option" optionLabel="name" display="chip">
            </p-multiSelect>
        </div>
    </div>
        </ng-container>
    </div>
 </div>





  <div class="in" *ngIf="dataForm && dataForm?.group_fields" style="display: none;">
    <ng-container *ngFor="let fielsParent of dataForm?.group_fields ; let idx = index">
      <div class="">
        <div class="col-md-12 mt-2" style="border-bottom: 1px solid #98a4b8">
          <label style="font-weight: bold;">{{fielsParent.group_name}}</label>
        </div>
        <div class="row">
          <ng-container *ngFor="let element of fielsParent?.fields;let i=index">
            <div [ngClass]="{'custom_image': dataForm.formUser}" [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
              *ngIf="element.columnType === 'image'" class="{{element.columnClass}} custom_image">
              <div class="form-group">
                <label style="font-weight: bold;">Ảnh đại diện</label>
                <div class="d-flex">
                  <div class="upload-area d-inline-flex align-items-center" *ngIf="!element[element.field_name]">
                    <div class="text-center p-3 ml-auto mr-auto" style="color: #007ad9;">
                      <span class="fa fa-plus"></span><br>
                      Thêm mới
                    </div>
                    <input (change)="onUploadOutput($event, fielsParent)"
                      accept="image/jpeg,image/png,image/jpg,image/gif" type="file" name="myfile1" class="input-file">
                  </div>
                  <div *ngIf="element[element.field_name]" class="upload-area uploaded d-inline-flex align-items-center"
                    style="justify-content:center">
                    <ng-container>
                      <button type="button" (click)="deleteAvatar(fielsParent, element.field_name)"
                        class="btn btn-danger-ct rounded-circle btn-close"><span class="fa fa-remove"></span></button>
                      <img id="img" src={{element[element.field_name]}} alt="" class="preview">
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngIf="anotherType == true">
                  <br>
                  <span class="btn bg-gray-light text-primary">{{fileName}} &nbsp;<span (click)="closeFile()"
                      class="oi oi-x text-secondary"></span></span>
                </ng-container>

              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}"
              *ngIf="element.columnType === 'input' && (element.data_type === 'float' || element.data_type === 'nvarchar')"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>

            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'input' && element.data_type === 'int'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>

            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'dropdown'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label> <br>
              <p-dropdown appendTo="body" baseZIndex="100" [autoDisplayFirst]="false" [showClear]="true"
                [disabled]="element.isDisable" [options]="element.options"
                [required]="element.isRequire && element.isVisiable" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} filter="true">
                <ng-template let-item pTemplate="selectedItem">
                  <!-- <img src="assets/showcase/images/demo/car/{{item.label}}.png" style="width:16px;vertical-align:middle" /> -->
                  <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                </ng-template>
                <ng-template let-car pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <!-- <img src="assets/showcase/images/demo/car/{{car.label}}.png" style="width:24px;position:absolute;top:1px;left:5px"/> -->
                    <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="element.isRequire && submit && (!element[element.field_name] || element[element.field_name] === '')"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'number'" class="{{element.columnClass}}"
              style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input type="number" class="form-control" (change)="isNumber($event)" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'currency'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <input maxLength=18 type="text" (keypress)="numberOnly($event)" (change)="changePrice($event, element.field_name, fielsParent, i)"
                class="form-control" [(ngModel)]="element[element.field_name]" currency name={{element.field_name}}
                [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <!-- <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.data_type === 'datetime'" class="{{element.columnClass}}" style="margin-top: 10px;">
                <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                  <input [(ngModel)]="element[element.field_name]" name={{element.field_name}}
                  class="form-control"
                  #datepickerYMD="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
                  <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường  bắt buộc nhập!
                </div>
              </div>
              </div> -->
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'checkbox'"
              class="{{element.columnClass}} d-flex align-items-end" style="margin-top: 10px;">
              <p-checkbox name={{element.field_name}} binary="true" label="{{element.columnLabel}} :"
                [required]="element.isRequire && element.isVisiable" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]"></p-checkbox>
            </div>
            
            <ng-container *ngIf="element.columnType === 'textarea' && (element.field_name === 'res_Add1' || element.field_name === 'trad_Add1');else contentTextarea">
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                <p-button label="Tách địa chỉ" icon="ft-scissors" styleClass="p-button-sm" title="Chi tiết địa chỉ" iconPos="left" (click)="sliptAddress(element[element.field_name],element.field_name, fielsParent)"></p-button>
                <textarea type="text" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" class="form-control" (change)="saveAddress($event.target.value)" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
              </div>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'"  class="{{element.columnClass}}" style="margin-top: 10px;">
                <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red" *ngIf="element.isRequire">*</span></label>
                <p-button label="Copy địa chỉ" icon="ft-repeat" styleClass="p-button-sm" title="Coppy địa chỉ thường chú" iconPos="left" (click)="coppyAddress1( fielsParent)"></p-button>
                <textarea type="text" class="form-control" placeholder="vd: 50B Đường Tây Hồ, Phường Quảng An, Quận Tây Hồ, Hà Nội" [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable"></textarea>
              </div>
            </ng-container>
            <ng-template #contentTextarea>
              <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'textarea'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label>
              <textarea type="text" class="form-control" (change)="saveAddress($event.target.value)"
                [(ngModel)]="element[element.field_name]" name={{element.field_name}} [disabled]="element.isDisable"
                [required]="element.isRequire && element.isVisiable"></textarea>
                <div *ngIf="element.isRequire && submit && !element[element.field_name] && !element.isDisable"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            </ng-template>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'select'" class="{{element.columnClass}}"
              style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span
                  style="color:red" *ngIf="element.isRequire">*</span></label>
              <select class="form-control" [(ngModel)]="element[element.field_name]" name={{element.field_name}}
                [disabled]="element.isDisable" [required]="element.isRequire && element.isVisiable">
                <option *ngFor="let option of element.options ; let i=index" value="{{option.value}}">
                  {{option.label}}</option>
              </select>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div> 
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datetime'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'datefulltime'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [monthNavigator]="true" showTime="true" hourFormat="24" [yearNavigator]="true"
                yearRange="2000:2030" inputId="navigators" [required]="element.isRequire && element.isVisiable"
                dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>
              <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                class="alert-validation alert-danger">
                <div [hidden]="element[element.field_name]">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'timeonly'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label for="" [title]="element.columnTooltip">{{element.columnLabel}} :<span style="color:red"
                  *ngIf="element.isRequire">*</span></label> <br>
              <p-calendar placeholder="DD/MM/YYYY" appendTo="body" baseZIndex="101" [disabled]="element.isDisable"
                [(ngModel)]="element[element.field_name]" [timeOnly]="true" inputId="timeonly"
                [required]="element.isRequire && element.isVisiable" placeholder="HH:mm" name={{element.field_name}}>
              </p-calendar>
              <div *ngIf="element?.message?.Required && submit" class="alert-validation alert-danger">
                <div [hidden]="!element?.message?.Required">
                  Trường bắt buộc nhập!
                </div>
              </div>
            </div>
            <div [hidden]="!element.isVisiable" [ngClass]="{'isEmpty': element.isEmpty}" *ngIf="element.columnType === 'multiselect'"
              class="{{element.columnClass}}" style="margin-top: 10px;">
              <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}} :: <span
                  style="color:red" *ngIf="element.isRequire">*</span></label> <br>
              <p-multiSelect [options]="element.options" [(ngModel)]="element[element.field_name]"
                name={{element.field_name}} defaultLabel="Select a option" optionLabel="name" display="chip">
              </p-multiSelect>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row " style="margin-top: 20px; margin-bottom: 10px" *ngIf="titleForm.value != 'Line'">
    <div class="col-md-12 d-flex justify-content-end">
      <p-button label="Xác minh chứng minh thư" styleClass="mr-1" [disabled]="verifiedCard" *ngIf="xacminhcmt && dataForm && dataForm.metas.length > 0" (click)="Fnxacminhcmt()"></p-button>
      <p-button label="Lưu lại" *ngIf="titleForm.value != 'View'" styleClass="mr-1 p-button-sm" icon="pi pi-check" (click)="saveScheme(createCustomer)">
      </p-button>
      <div *ngIf="backCallApi; else closeButtonNoCallApi">
        <p-button label="Đóng"  (click)="backpageCallApi()" icon="pi pi-times" styleClass="p-button-secondary p-button-sm"></p-button>
      </div>
      <ng-template #closeButtonNoCallApi>
        <p-button label="Đóng" *ngIf="!backCallApi" (click)="backpage()" icon="pi pi-times" styleClass="p-button-secondary p-button-sm">
        </p-button>
     </ng-template>
   
    </div>
  </div>
</form>