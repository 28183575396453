<link href="//cdn.syncfusion.com/ej2/ej2-popups/styles/material.css" rel="stylesheet" />
<div class="content-header row" style="margin: -10px;">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/home">Trang chủ</a>
        </li>
        <li class="breadcrumb-item">
          <a>Cài đặt quản trị</a>
        </li>
        <li class="breadcrumb-item active" (click)="backpage()">
          Quay lại danh sách
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body" style="margin-top: -25px;">
  <section id="configuration">
    <div class="border-25">
      <div class="setting shadow-border border-25 p-2 bg-white mt-1" *ngIf="!detailManager">
        <div class="row">
          <div class="col-md-3 w-100">
            <div class="form-group">
              <label for="">Loại View Table</label>
              <select class="form-control" id="viewGrid" name="viewGrid" [(ngModel)]="filter.modCd"
                (change)="getTableGridList($event.target.value)">
                <option value="" disabled>Loại View Table</option>
                <option *ngFor="let item of moduleTables; let i=index" value={{item.objCode}}>{{item.objName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 w-100">
            <div class="form-group">
              <label for="">Bảng</label>
              <select id="" class="form-control" [(ngModel)]="filter.table_name" name="table_name"
                (change)="getFieldColumnList($event.target.value)">
                <option *ngFor="let item of tableGrids; let i=index" value={{item.table_name}}>{{item.table_name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 w-100">
            <div class="form-group">
              <label for="">Chọn tên cột</label>
              <select class="form-control" id="viewGrid" name="columnName" [(ngModel)]="filter.column_name">
                <option value="" disabled>Chọn tên cột</option>
                <option value="{{column.column_name}}" *ngFor="let column of columnFieldList; let i= index">
                  {{column.column_name}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 w-100">
            <div class="form-group">
              <label style="visibility: hidden;">sssssssssssss </label><br>
              <span class="">
                <p-button label="" icon="fa fa-search" iconPos="left" (click)="handleFilter()"></p-button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="table shadow-border border-25 p-2 mt-1 bg-white">
        <div class="">
          <form action="" #createCustomer="ngForm" *ngIf="dataFieldsInfo && dataFieldsInfo?.group_fields">
            <div class="in" *ngIf="dataFieldsInfo && dataFieldsInfo?.group_fields">
              <ng-container *ngFor="let fielsParent of dataFieldsInfo?.group_fields ; let idx = index">
                <div class="">
                  <div class="col-md-12" style="border-bottom: 1px solid #98a4b8">
                    <label style="font-weight: bold;">{{fielsParent.group_name}}<span
                        class="noti-number noti-number-on ml5">Edit</span></label>
                  </div>
                  <div class="row">
                    <ng-container *ngFor="let element of fielsParent?.fields;let i=index">

                      <!-- <div [ngClass]="{'custom_image': dataFieldsInfo.formUser}"
                                                [hidden]="!element.isVisiable" *ngIf="element.columnType === 'imagess'"
                                                class="{{element.columnClass}} custom_image">
                                                <div class="form-group">
                                                    <label style="font-weight: bold;">Ảnh đại diện</label>
                                                    <div class="d-flex">
                                                        <div class="upload-area d-inline-flex align-items-center"
                                                            *ngIf="!element[element.field_name]">
                                                            <div class="text-center p-3 ml-auto mr-auto"
                                                                style="color: #007ad9;">
                                                                <span class="fa fa-plus"></span><br>
                                                                Thêm mới
                                                            </div>
                                                            <input (change)="onUploadOutput($event, fielsParent)"
                                                                accept="image/jpeg,image/png,image/jpg,image/gif"
                                                                type="file" name="myfile1" class="input-file">
                                                        </div>
                                                        <div *ngIf="element[element.field_name]"
                                                            class="upload-area uploaded d-inline-flex align-items-center"
                                                            style="justify-content:center">
                                                            <ng-container>
                                                                <button type="button"
                                                                    (click)="deleteAvatar(fielsParent, element.field_name)"
                                                                    class="btn btn-danger-ct rounded-circle btn-close"><span
                                                                        class="fa fa-remove"></span></button>
                                                                <img id="img" src={{element[element.field_name]}} alt=""
                                                                    class="preview">
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <ng-container *ngIf="anotherType == true">
                                                        <br>
                                                        <span class="btn bg-gray-light text-primary">{{fileName}}
                                                            &nbsp;<span (click)="closeFile()"
                                                                class="oi oi-x text-secondary"></span></span>
                                                    </ng-container>

                                                </div>
                                            </div> -->
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'input' && element.data_type === 'nvarchar'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable">
                        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                          class="alert-validation alert-danger">
                          <div [hidden]="element[element.field_name]">
                            Chọn dự án bắt buộc nhập!
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'input' && element.data_type === 'int'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable">
                        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                          class="alert-validation alert-danger">
                          <div [hidden]="element[element.field_name]">
                            Chọn dự án bắt buộc nhập!
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!element.isVisiable" *ngIf="element.data_type === 'datetime'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                            *ngIf="element.isRequire">*</span></label>
                        <input [(ngModel)]="element[element.field_name]" name={{element.field_name}}
                          class="form-control" #datepickerYMD="bsDatepicker" bsDatepicker
                          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable">
                        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                          class="alert-validation alert-danger">
                          <div [hidden]="element[element.field_name]">
                            Chọn dự án bắt buộc nhập!
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!element.isVisiable" *ngIf="element.columnType === 'checkbox'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="text-nowrap" class="control-label"
                          [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                            *ngIf="element.isRequire">*</span></label>
                        <span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
                          <input type="checkbox" [(ngModel)]="element[element.field_name]" id={{element.field_name}}
                            name={{element.field_name}} class="custom-control-input" [disabled]="element.isDisable"
                            [required]="element.isRequire && element.isVisiable">
                          <label for={{element.field_name}} class="custom-control-label"></label>
                        </span>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'textarea' && element.field_name === 'res_Add1'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <p-button label="Click" icon="ft-scissors" title="Chi tiết địa chỉ" iconPos="left"
                          (click)="sliptAddress(element[element.field_name],element.field_name)">
                        </p-button><textarea type="text" class="form-control"
                          (change)="saveAddress($event.target.value)" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable"></textarea>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'textarea' && element.field_name === 'trad_Add1'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="mr-1" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <p-button label="Click" icon="ft-repeat" title="Coppy địa chỉ thường chú" iconPos="left"
                          (click)="coppyAddress1(element[element.field_name],element.field_name)">
                        </p-button><textarea type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable"></textarea>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'textarea' && element.field_name === 'note'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                            *ngIf="element.isRequire">*</span></label>
                        <textarea type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable"></textarea>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'textarea' && element.field_name === 'comment'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label for="" [title]="element.columnTooltip">{{element.columnLabel}}<span style="color:red"
                            *ngIf="element.isRequire">*</span></label>
                        <textarea type="text" class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable"></textarea>
                      </div>

                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'select' && element.field_name != 'userRole'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <select class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable">
                          <option *ngFor="let option of element.options ; let i=index" value={{option.objValue}}>
                            {{option.objName}}</option>
                        </select>
                        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                          class="alert-validation alert-danger">
                          <div [hidden]="element[element.field_name]">
                            Chọn dự án bắt buộc nhập!
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!element.isVisiable"
                        *ngIf="element.columnType === 'select' && element.field_name === 'userRole'"
                        class="{{element.columnClass}}" style="margin-top: 10px;">
                        <label class="text-nowrap" for="" [title]="element.columnTooltip">{{element.columnLabel}}<span
                            style="color:red" *ngIf="element.isRequire">*</span></label>
                        <select class="form-control" [(ngModel)]="element[element.field_name]"
                          name={{element.field_name}} [disabled]="element.isDisable"
                          [required]="element.isRequire && element.isVisiable">
                          <option *ngFor="let option of element.options ; let i=index" value={{option.objValue}}>
                            {{option.objName}}</option>
                        </select>
                        <div *ngIf="element.isRequire && submit && !element[element.field_name]"
                          class="alert-validation alert-danger">
                          <div [hidden]="element[element.field_name]">
                            Chọn dự án bắt buộc nhập!
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-md-12 mt-1" style="border-bottom: 1px solid #98a4b8">
              <label style="font-weight: bold;">Phân loại<span
                  class="noti-number noti-number-on ml5">Edit</span></label>
            </div>
            <div class="row mt-1">
              <div class="col-md-5">
                <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 300px" id="myGrid" class="ag-theme-balham"
                  [rowData]="dataFieldsInfo?.baseTypes" [columnDefs]="columnDefs"
                  [groupDefaultExpanded]="groupDefaultExpanded" [masterDetail]="true"
                  [detailCellRendererParams]="detailCellRendererParams" [detailRowHeight]="detailRowHeight"
                  (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" enableCellTextSelection=true
                  [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                  [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"></ag-grid-angular>

              </div>
              <div class="col-md-1 p-btn" style="margin-top: 50px;">
                <p-button icon="ft-chevron-right" styleClass="mt-1" [disabled]="checkboxRightDisabled"
                  (onClick)="chevronFn('right')"></p-button>
                <p-button icon="ft-chevrons-right" styleClass="mt-1" [disabled]="checkboxRightDisabled"
                  (onClick)="chevronFn('rights')"></p-button>
                <p-button icon="ft-chevron-left" styleClass="mt-1" [disabled]="checkboxleftDisabled"
                  (onClick)="chevronFn('left')"></p-button>
                <p-button icon="ft-chevrons-left" styleClass="mt-1" [disabled]="checkboxleftDisabled"
                  (onClick)="chevronFn('lefts')"></p-button>
              </div>
              <div class="col-md-6">
                <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 300px" id="myGrid" class="ag-theme-balham"
                  [rowData]="categoryListsByUser_Id" [columnDefs]="columnDefs1"
                  [groupDefaultExpanded]="groupDefaultExpanded" [masterDetail]="true"
                  [detailCellRendererParams]="detailCellRendererParams1" [detailRowHeight]="detailRowHeight"
                  [rowSelection]="rowSelection" enableCellTextSelection=true (gridReady)="onGridReady1($event)"
                  [defaultColDef]="defaultColDef" [groupUseEntireRow]="true" [enableRangeSelection]="true"
                  [animateRows]="true" [getRowHeight]="getRowHeight1" [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>
              </div>
            </div>
            <div class="col-md-12 mt-1" style="border-bottom: 1px solid #98a4b8">
              <label style="font-weight: bold;">Phân quyền<span
                  class="noti-number noti-number-on ml5">Edit</span></label>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <label for="">Chọn quyền role <span style="color:red">*</span></label>
                <p-multiSelect [options]="roleListsMuti" [(ngModel)]="selectedRoles" name="selectedRoles">
                  <ng-template let-value pTemplate="selectedItems">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                      <span>{{val}}</span>
                    </div>
                    <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Select
                      option</span>
                  </ng-template>
                  <ng-template let-car pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                  </ng-template>
                </p-multiSelect>
              </div>
              <div class="col-md-6">
                <label for="">Chọn quyền catalog <span style="color:red">*</span></label>
               <div class="col-md-12">
                <p-multiSelect [options]="webRoleListsMuti" [(ngModel)]="selectedWebRoles" optionLabel="label"
                  name="selectedWebRoles">
                  <ng-template let-selectedCars pTemplate="selectedItems">
                    <div *ngFor="let val of selectedCars" class="ui-multiselected-item-token ui-corner-all">
                      <span>{{val.label}}</span>
                    </div>
                    <span *ngIf="!selectedCars || selectedCars.length === 0"
                      class="ui-multiselected-empty-token ui-corner-all">Select option</span>
                  </ng-template>
                  <ng-template let-car pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                  </ng-template>
                </p-multiSelect>
               </div>
               <label class="mt-1" for="">Phân quyền người dùng <span style="color:red">*</span></label>
               <div class="col-md-12">
                <p-multiSelect [options]="saleGroups" [(ngModel)]="dataFieldsInfo.app_chats" name="app_chats">
                  <ng-template let-value pTemplate="selectedItems">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                      <span>{{val.chat_name}}</span>
                    </div>
                    <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Select option</span>
                  </ng-template>
                  <ng-template let-car pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                  </ng-template>
                </p-multiSelect>
               </div>
              </div>
            </div>
            <hr>
            <div class="row mt-3 mb-1">
              <div class="col-md-12 d-flex justify-content-end">
                <!-- <p-button label="Thê" styleClass="mr-1"  [disabled]="!createCustomer.form.valid" (click)="saveCustomer()"></p-button> -->
                <p-button label="Lưu lại" styleClass="mr-1" [disabled]="!createCustomer.form.valid"
                  (click)="saveManagerForm()"></p-button>
                <p-button label="Quay lại" styleClass="ui-button-secondary mr-1" (click)="backpage()">
                </p-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
