
import { Component, OnInit, ViewChild } from '@angular/core';
import { BasePagingComponent } from '../viewcommon/base-paging/base-paging.component';
import * as moment from 'moment';
import { AgGridFn } from '../utils/common/function-common';
import { ApiService } from '../services/api.service';
import { MessageService} from 'primeng/api';
import { ButtonRendererComponent } from '../viewcommon/renderer/button-renderer.component';
import { ButtonRendererComponent1 } from '../viewcommon/renderer/button-renderer.component-1';
import { AvatarComponent } from '../common/avatar/avatar.component';
import { ApiCoreServiceService } from '../services/api-core/api-core-service/api-core-service.service';
import { ConfigService } from '../services/api-core/api-core-service/config.service';
import * as queryString from 'querystring';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
declare var jQuery: any;
@Component({
  selector: 'app-listfieldsinform',
  templateUrl: './listfieldsinform.component.html',
  styleUrls: ['./listfieldsinform.component.css'],
  providers: [ConfigService, ApiCoreServiceService]
})
export class ListfieldsinformComponent implements OnInit {
  @ViewChild(BasePagingComponent, { static: true }) pagingComponent: BasePagingComponent;
  public agGridFn = AgGridFn;
  public modules: Module[] = AllModules;

  constructor(
    private apiService: ApiService,
    private apiConfigService: ConfigService

  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      buttonRenderer1: ButtonRendererComponent1,
      avatarRenderer: AvatarComponent,
    };
    this.getRowHeight = (params) => {
      if (params.node.master) {
        return 40;
      } else {
        return 350;
      }
    },
      this.defaultColDef = {
        resizable: true,
      };
  }
  gridflexdetails: any;
  gridflexs: any;
  detailFieldsInfo: any;
  tmp: any = { filter: '', table_name: 'ca830pb', mod_cd: 'Core' };
  isEdit = false;
  isEditForm = false;
  public frameworkComponents;
  public gridApi;
  public rowSelection;
  public gridApiItemMap;
  public domLayout;
  public gridColumnApi;
  public groupDefaultExpanded;
  public columnDefs;
  public defaultColDef;
  public items: any;
  public listProducts: any = [];
  public tableGrids: any = []
  public moduleTables: any = []
  public detailRowHeight;
  public detailCellRendererParams;
  public getRowHeight: any;
  public detailopenInfo: any;
  countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  dataFieldsInfo: any;
  objectAction: any;
  clientWidth = 0;
  ngOnInit() {
    this.clientWidth = jQuery('#myGrid')[0].clientWidth;
    this.load();
    this.pagingComponent.pageSize = 25;
    this.getModuleList();
    this.getTableGridList();
  }


  changePageSize() {
    this.load();
  }

  page = function (page_index) {
    this.pageIndex = page_index;
    this.load();
  };

  find() {
    this.load();
  }

  load() {
    const offset = this.pagingComponent.getOffset();
    const pageSize = this.pagingComponent.pageSize;
    this.apiService.getConfigTableViewPage(this.tmp.filter, offset, pageSize, this.clientWidth, this.tmp.table_name).subscribe(
      (results: any) => {
        this.items = results.data;
        if (offset == 0) {
          this.gridflexs = this.items.gridflexs;
          this.gridflexs.forEach(column => {
            if (column.columnField === 'actions') {
              this.objectAction = column;
            }
          });
        }
        if (this.gridApi) {
          this.gridApi.setRowData(this.items.dataList.data);
        }
        this.onInit();
        this.pagingComponent.total = this.items.dataList.recordsTotal;
        this.countRecord.totalRecord = this.items.dataList.recordsTotal;
        this.countRecord.currentRecordStart = offset + 1;
        if ((this.items.dataList.recordsTotal - offset) > pageSize) {
          this.countRecord.currentRecordEnd = offset + Number(pageSize);
        } else {
          this.countRecord.currentRecordEnd = this.items.dataList.recordsTotal;
        }
      },
      error => { });
  }

  onInit() {
    this.columnDefs = [
      ...this.agGridFn(this.gridflexs),
      {
        headerName: 'Thao tác', field: 'button', filter: '', cellRenderer: 'buttonRenderer',
        pinned: this.objectAction ? this.objectAction.pinned : 'right',
        width: this.objectAction ? this.objectAction.columnWidth : 100,
        cellClass: this.objectAction ? this.objectAction.cellClass : ['border-right'],
        cellRendererParams: params => {
          return {
            buttons: [
              {
                onClick: this.handleEdit.bind(this),
                label: 'Sửa',
                icon: 'fa fa-edit',
                class: 'btn-dropbox shadow',
                show: true
              },
            ]
          };
        },
      },
    ];
  }

  getModuleList() {
    this.apiService.getCustObjectList('module_data_list').subscribe(results => {
      if (results.status === 'success') {
        this.moduleTables = results.data;
      }
    })
  }

  getTableGridList() {
    // import * as queryString from 'querystring';
    const queryParams = queryString.stringify({ modCd: this.tmp.mod_cd, tableType: 'table'});
    this.apiService.getTableGridList(queryParams).subscribe(results => {
      if (results.status === 'success') {
        this.tableGrids = results.data;
      }
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.items && this.items.dataList && this.items.dataList.data.length > 0) {
      this.gridApi.setRowData(this.items.dataList.data);
    }
  }

  cancelSearch() {
    this.tmp = {
      filter: '',
    };
    this.load();
  }

  handleEdit(e) {
    this.isEdit = true;
    this.isEditForm = true;
    this.dataFieldsInfo = e.rowData;
  }

  addFields() {
    this.isEdit = true;
    this.isEditForm = false;
    this.dataFieldsInfo = null;
  }

  cancelpage(e) {
    this.isEdit = false;
    this.load();
  }
}





