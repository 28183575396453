
<div class="content-header row">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/home">Trang chủ</a>
        </li>
        <li class="breadcrumb-item">
          <a>Quản lý phân quyền</a>
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body">
  <section id="configuration">
    <div class="row">
      <div class="col-12">

        <div class="center">
          <div class="my-alert-center-style" class="alert-item">

          </div>
        </div>
        <div class="card">
          <div class="card-content collapse show">
            <div class="ssg-card-search card-dashboard">
              <div class="row">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-3 col-lg-3">
                      <fieldset class="form-group">
                        <label for="">Mã thẻ</label>
                        <p-autoComplete [(ngModel)]="key_car" name="key_car"
                        title="Nhập mã thẻ để tìm kiếm" baseZIndex="102" appendTo="body" [style]="{width: '100%'}"
                        [suggestions]="listCars" (onSelect)="selectedItem1($event)"
                        placeholder="Nhập mã thẻ để tìm kiếm" (completeMethod)="search($event)"
                        field="fullNameAndCar"></p-autoComplete>
                        <span *ngIf="key_car" (click)="cancel()"><i class="sun-icon-x icon-input-search"></i></span>
                      </fieldset>
                    </div>

                  </div>
                </div>
                <div class="col-md-2 text-right">
                  <p-button label="Thêm mới" *ngIf="checkAction('/managerpermissionuser', 'New')" (click)="createUser();" icon="fa fa-plus"></p-button>
                  <div class="btn-group ml-1">
                    <button type="button" class="btn btn-primary dropdown dropdown-toggle" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Quản lý
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" *ngIf="checkAction('/managerpermissionuser', 'ManagePage')"
                        [routerLink]="['/managerpage']">Quản lý trang</a>
                      <a class="dropdown-item" *ngIf="checkAction('/managerpermissionuser', 'ManageTab')"
                        [routerLink]="['/managertab']">Quản lý tab</a>
                      <a class="dropdown-item" *ngIf="checkAction('/managerpermissionuser', 'ManageMenu')"
                        [routerLink]="['/managermenu']">Quản lý menu</a>
                      <!-- <a class="dropdown-item" [routerLink]="['/managerrole']">Quản lý quyền</a>
                                <a class="dropdown-item" [routerLink]="['/manageraction']">Quản lý Thao tác</a>
                                <a class="dropdown-item" [routerLink]="['/grouprole']">Nhóm quyền</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">

          <div class="card-content collapse show">
            <div class="ssg-card-search card-dashboard">
              <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <h4 class="card-title" style="margin-bottom: -3px;">
                  <i _ngcontent-c4="" class="fa fa-list"></i> Thông tin nhân sự</h4>
                <hr>
                  <div class="row">
                    <div class="col-md-5">
                      <table class="table table-bordered">
                        <tbody>
                          <tr style="background-color: #fff;">
                            <td scope="row">Họ và tên</td>
                            <td colspan="2">{{detailcar?.fullName}}</td>
                          </tr>
                          <tr>
                            <td scope="row">Số điện thoại</td>
                            <td colspan="2">{{detailcar?.phone}}</td>
                          </tr>
                          <tr>
                            <td scope="row">Email</td>
                            <td colspan="2">{{detailcar?.email}}</td>
                          </tr>
                          <tr style="background-color: #fff;">
                            <td scope="row">Ngày sinh</td>
                            <td colspan="2">{{detailcar?.birthday}}</td>
                          </tr>
                         
                          <tr style="background-color: #fff;">
                            <th scope="row">Vị trí</th>
                            <th colspan="2">{{detailcar?.position}}</th>
                          </tr>
                          <tr>
                            <td scope="row">Giới tính</td>
                            <td colspan="2">{{detailcar?.sexName}}</td>
                          </tr>
                          <tr style="background-color: #fff;">
                            <td scope="row">Ngày cấp</td>
                            <td colspan="2">{{detailcar?.workDate}}</td>
                          </tr>
                          <tr>
                            <td scope="row">Trạng thái</td>
                            <td colspan="2">{{detailcar?.lockName}}</td>
                          </tr>
                          <tr>
                            <td scope="row">Ảnh avatar</td>
                            <td colspan="2">
                               <img *ngIf="detailcar?.avatarUrl" style="width: 20%;" src={{detailcar?.avatarUrl}} alt="">
                              <img *ngIf="!detailcar?.avatarUrl" style="width: 30%;" src="../../assets/images/avatar.jpg"
                                alt=""></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-7">
                      <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                      [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                      (firstDataRendered)="onFirstDataRendered($event)" enableCellTextSelection=true
                      [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                      [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                      [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                      [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              <i _ngcontent-c4="" class="fa fa-list"></i>Phân quyền Role</h4>
            <hr>
            <a class="heading-elements-toggle">
              <i class="fa fa-ellipsis-v font-medium-3"></i>
            </a>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <a data-action="collapse">
                    <i class="ft-minus"></i>
                  </a>
                </li>
                <li>
                  <a data-action="expand">
                    <i class="ft-maximize"></i>
                  </a>
                </li>
                <li>
                  <a data-action="close">
                    <i class="ft-x"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-content collapse show">
            <div class="card-body card-dashboard">
              <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4"
                style="margin-top: -40px;">
                 <div class="row">
                  <p-tabView>
                    <p-tabPanel header="Phân quyền role">
                      <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                       [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                      (firstDataRendered)="onFirstDataRendered($event)" enableCellTextSelection=true
                      [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                      [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                      [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                      [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
                    </p-tabPanel>
                    <p-tabPanel header="Phân quyền nhóm chát">
                      <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                       [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                      (firstDataRendered)="onFirstDataRendered($event)" enableCellTextSelection=true
                      [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                      [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                      [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                      [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
                    </p-tabPanel>
                    <p-tabPanel header="Phân quyền web">
                      <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                       [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                      (firstDataRendered)="onFirstDataRendered($event)" enableCellTextSelection=true
                      [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                      [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                      [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                      [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
                    </p-tabPanel>
                    <p-tabPanel header="Phân quyền catalog">
                      <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height:440px;" id="myGrid" class="ag-theme-balham"
                      [columnDefs]="columnDefs" [masterDetail]="true" floatingFilter
                      (firstDataRendered)="onFirstDataRendered($event)" enableCellTextSelection=true
                      [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)"
                      [defaultColDef]="defaultColDef" [animateRows]="true" [groupMultiAutoColumn]="true"
                      [detailRowHeight]="detailRowHeight" [frameworkComponents]="frameworkComponents"
                      [getRowHeight]="getRowHeight">
                    </ag-grid-angular>
                    </p-tabPanel>
                </p-tabView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- thêm mới tài khoản  -->
  <div class="modal fade text-left" id="createSMS" tabindex="-1" role="dialog" aria-labelledby="myModalLabel10"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning white">
          <h4 class="modal-title" id="myModalLabel10">Thêm tài khoản</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form #createSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <div class="box-body" style="padding:10px;">

            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Tên đăng nhập
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="userLogin" name="userLogin" [(ngModel)]="model.userLogin"
                  required #userLogin="ngModel" placeholder="Tên đăng nhập">
                <div *ngIf="userLogin.errors && (userLogin.dirty || userLogin.touched)"
                  class="alert-validation alert-danger">
                  <div [hidden]="!userLogin.errors.required">
                    Tên đăng nhập bắt buộc nhập!
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Password
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <input type="password" class="form-control" name="userPassword" autocomplete="off"
                  [(ngModel)]="model.userPassword" required value="" #userPassword="ngModel" id="userPassword"
                  placeholder="Password">
                <div *ngIf="userPassword.errors && (userPassword.dirty || userPassword.touched)"
                  class="alert-validation alert-danger">
                  <div [hidden]="!userPassword.errors.required">
                    Password bắt buộc nhập!
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Nhập lại Password
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <input type="password" class="form-control" name="userPasswordCf" autocomplete="off"
                  [(ngModel)]="model.userPasswordCf" required validateEqual="userPassword" value=""
                  #userPasswordCf="ngModel" id="userPasswordCf" placeholder="Nhập lại Password">
                <div *ngIf="!(userPasswordCf.valid || userPasswordCf.pristine)" class="alert-validation alert-danger">
                  <div [hidden]="userPasswordCf.valid || userPasswordCf.pristine">
                    Nhập lại Password không đúng!
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
        <div class="modal-footer" ng-reflect-ng-class="modal-footer">
          <button class="btn btn-primary" [disabled]="!createSMSF.form.valid" (click)="SaveUser(); "
            ng-reflect-ng-class="btn btn-primary">Lưu</button>
          <button data-dismiss="modal" aria-label="Close" class="btn btn-danger" (click)="CancelUser();"
            ng-reflect-ng-class="btn btn-danger">Hủy</button>
        </div>
      </div>
    </div>
  </div>

  <!-- sửa mật khẩu -->

  <div class="modal fade text-left" id="editSMS" tabindex="-1" role="dialog" aria-labelledby="myModalLabel10"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning white">
          <h4 class="modal-title" id="myModalLabel10">Thay đổi mật khẩu</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form #editSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <div class="box-body" style="padding:10px;">

            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Tên đăng nhập
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                {{modelPass.userLogin}}
              </div>
            </div>
            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Password
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <input type="password" class="form-control" name="userPass" autocomplete="off"
                  [(ngModel)]="modelPass.userPassword" required value="" #userPass="ngModel" id="userPass"
                  placeholder="Password">
                <div *ngIf="userPass.errors && (userPass.dirty || userPass.touched)"
                  class="alert-validation alert-danger">
                  <div [hidden]="!userPass.errors.required">
                    Password bắt buộc nhập!
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Nhập lại Password
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <input type="password" class="form-control" name="userPassCf" autocomplete="off"
                  [(ngModel)]="modelPass.userPassCf" required validateEqual="userPass" value="" #userPassCf="ngModel"
                  id="userPassCf" placeholder="Nhập lại Password">
                <div *ngIf="!(userPassCf.valid || userPassCf.pristine)" class="alert-validation alert-danger">
                  <div [hidden]="userPassCf.valid || userPassCf.pristine">
                    Nhập lại Password không đúng!
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
        <div class="modal-footer" ng-reflect-ng-class="modal-footer">
          <button class="btn btn-primary" [disabled]="!editSMSF.form.valid" (click)="SavePass(); "
            ng-reflect-ng-class="btn btn-primary">Lưu</button>
          <button data-dismiss="modal" aria-label="Close" class="btn btn-danger" (click)="CancelPass();  "
            ng-reflect-ng-class="btn btn-danger">Hủy</button>
        </div>

      </div>
    </div>
  </div>

  <!-- // cập nhật role -->

  <div class="modal fade text-left" id="roleDialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel10"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning white">
          <h4 class="modal-title" id="myModalLabel10">Phân quyền role</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form #chatSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <div class="box-body" style="padding:10px;">

            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Quyền truy cập role
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <p-multiSelect [options]="dropdownList1" [(ngModel)]="selectedItems1"
                name="selectedItems" defaultLabel="Select a option" optionLabel="name" display="chip">
              </p-multiSelect>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer" ng-reflect-ng-class="modal-footer">
          <button class="btn btn-primary" (click)="SaveRole(); " ng-reflect-ng-class="btn btn-primary">Lưu</button>
          <button data-dismiss="modal" aria-label="Close" class="btn btn-danger"
            ng-reflect-ng-class="btn btn-danger">Hủy</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Phân quyền catalog -->
  <div class="modal fade text-left" id="catalogDialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel10"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content xl-modal">
        <div class="modal-header bg-warning white">
          <h4 class="modal-title" id="myModalLabel10">Phân quyền Catalog</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form #chatSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <div class="box-body" style="padding:10px;">

            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-2 control-label">Phân quyền Catalog
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-10">
                <p-multiSelect [options]="dropdownList3" [(ngModel)]="selectedItems3"
                name="selectedItems" defaultLabel="Select a option" optionLabel="name" display="chip">
              </p-multiSelect>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer" ng-reflect-ng-class="modal-footer">
          <button class="btn btn-primary" (click)="SaveCatalog(); " ng-reflect-ng-class="btn btn-primary">Lưu</button>
          <button data-dismiss="modal" aria-label="Close" class="btn btn-danger"
            ng-reflect-ng-class="btn btn-danger">Hủy</button>
        </div>

      </div>
    </div>
  </div>

  <!-- chat -->
  <div class="modal fade text-left" id="chatDialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel10"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning white">
          <h4 class="modal-title" id="myModalLabel10">Phân quyền nhóm chat</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form #chatSMSF="ngForm" class="form-horizontal ng-pristine ng-valid ng-scope">
          <div class="box-body" style="padding:10px;">

            <div class="form-row" style="margin:10px 0px;">
              <label class="col-sm-4 control-label">Quyền truy cập vào nhóm chat
                <span style="color:red">*</span>
              </label>
              <div class="col-sm-8">
                <p-multiSelect [options]="itemList" [(ngModel)]="selectedItems"
                name="selectedItems" defaultLabel="Select a option" optionLabel="name" display="chip">
              </p-multiSelect>
              </div>
            </div>


          </div>
        </form>
        <div class="modal-footer" ng-reflect-ng-class="modal-footer">
          <button class="btn btn-primary" (click)="SaveChat(); " ng-reflect-ng-class="btn btn-primary">Lưu</button>
          <button data-dismiss="modal" aria-label="Close" class="btn btn-danger" (click)="CancelChat();"
            ng-reflect-ng-class="btn btn-danger">Hủy</button>
        </div>

      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="displayRole" [autoZIndex]="true" modal="true" [style]="{width: '1300px', height: 'auto'}">
  <p-header>
    Thêm nhóm quyền
  </p-header>
 <app-addusertogrouprole [listGroupRole]="listGroupRole" [detail]="detailInfo" (save)="onSave($event)" (close)="displayRole = !displayRole"></app-addusertogrouprole>
</p-dialog>
