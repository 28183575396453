


<div class="content-header row" style="margin: -10px;" [hidden]="isEdit">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/home"><a><i class="fa fa-home"></i> Trang chủ</a>
        </li>
        <li class="breadcrumb-item active">Danh sách website
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="content-body" [hidden]="isEdit">
  <section id="configuration">
    <div class="row">
      <div class="col-12" style="padding: 0px;">
        <div class="card">
          <div class="card-header">
            <div class="row">
             <div class="col-md-12">
              <app-common-form [dataForm]="dataFieldsInfo" [titleForm]="titleForm" (callback)="saveWebInfo($event)"
              (back)="backpage()"></app-common-form>
             </div>
            </div>
            <div class="row ">
                <p-tabView (onChange)="handleChange($event)">
                    <p-tabPanel header="Danh sách Các tab">
                      <div class="row col-md-12 text-right" style="margin-bottom: 5px;">
                        <button pButton  type="button" class="p-button-sm" icon="pi pi-plus" (click)="addTab()" label="Thêm mới"></button>
                    </div>
                    <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px" class="ag-theme-balham"
                    [rowData]="lisstTabs" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                    [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
                    [detailRowHeight]="detailRowHeight" (rowDoubleClicked)="rowDoubleClicked($event)" rowSelection="single"
                    (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" enableCellTextSelection=true floatingFilter
                    [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                    [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                    </p-tabPanel>
                    <p-tabPanel header="Danh sách các menu">
                        <div class="row col-md-12 text-right" style="margin-bottom: 5px;">
                            <button pButton  type="button" class="p-button-sm" icon="pi pi-plus" (click)="addMenu()" label="Thêm mới"></button>
                        </div>
    
                        <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px" class="ag-theme-balham"
                        [rowData]="lisstMenus" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                        [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams" 
                        [detailRowHeight]="detailRowHeight" (rowDoubleClicked)="rowDoubleClicked($event)" rowSelection="single"
                        (gridReady)="onGridReadyMenu($event)" [defaultColDef]="defaultColDef" enableCellTextSelection=true floatingFilter
                        [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                        [getRowHeight]="getRowHeight1" [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                    </p-tabPanel>
                    <p-tabPanel header="Danh sách các Action">
                        <div class="row col-md-12 text-right" style="margin-bottom: 5px;">
                            <button pButton  type="button" class="p-button-sm" icon="pi pi-plus" (click)="addAction()" label="Thêm mới"></button>
                        </div>
                        <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px"  class="ag-theme-balham"
                        [rowData]="lisstActions" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                        [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
                        [detailRowHeight]="detailRowHeight" (rowDoubleClicked)="rowDoubleClicked($event)" rowSelection="single"
                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" enableCellTextSelection=true floatingFilter
                        [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                        [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"></ag-grid-angular>
    
                    </p-tabPanel>
                    <p-tabPanel header="Danh sách các quyền">
                        <div class="row col-md-12 text-right" style="margin-bottom: 5px;">
                            <button pButton  type="button" class="p-button-sm" icon="pi pi-plus" (click)="addRole()" label="Thêm mới"></button>
                        </div>
                        <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px"  class="ag-theme-balham"
                        [rowData]="lisstRoles" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                        [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
                        [detailRowHeight]="detailRowHeight" (rowDoubleClicked)="clickRowRoleGetMenu($event)" 
                        (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef" enableCellTextSelection=true floatingFilter
                        [groupUseEntireRow]="true" [enableRangeSelection]="true" [animateRows]="true"
                        [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"></ag-grid-angular>
    
                    </p-tabPanel>
                </p-tabView>

              
            </div>
          </div>
      
        </div>
       
      </div>
    </div>
  </section>
</div>





<p-dialog [(visible)]="displayAddMenu" [baseZIndex]="99" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <p-header >
        {{titleForm.label}}
</p-header>
<div class="row">
    <app-common-form [dataForm]="detailMenuInfo" [titleForm]="titleForm" *ngIf="displayAddMenu" (callback)="saveMenuInfo($event)"
    (back)="backpage()"></app-common-form>
</div>
<div class="row text-right mt-1">
    <p-pickList [source]="sourceActions" [target]="targetActions" [style]="{height: '600px'}" [showSourceControls]="false" [showTargetControls]="false"
     sourceHeader="Danh sách action được chọn" targetHeader="Danh sách action chưa chọn" [responsive]="true" filterBy="actionName" (onTargetFilter)="onTargetFilter($event)"
    dragdrop="true" sourceFilterPlaceholder="Tìm theo tên actionCd" targetFilterPlaceholder="Tìm theo tên actionCd" [sourceStyle]="{'min-height':'450px'}" [targetStyle]="{'min-height':'450px'}">
<ng-template let-car pTemplate="item">
    <div class="image-container">
        <span style="font-weight: 600"></span>+ {{car.actionName}}
</div>
    <div class="ui-helper-clearfix">
        <p style="display:inline-block;margin:2px 0 2px 2px"></p>
        <p style="font-size:14px;float:right;margin:2px 0 2px 2px">[{{car.actionCd}}]</p>
    </div>
</ng-template>
</p-pickList>


</div>

</p-dialog>

<p-dialog [(visible)]="displayAddRole" [baseZIndex]="99" modal="true" [style]="{width: '1000px', height: 'auto'}">
    <p-header >
        {{titleForm.label}}
</p-header>
<app-common-form [dataForm]="detailRoleInfo" [titleForm]="titleForm" *ngIf="displayAddRole" (callback)="saveRoleInfo($event)"
(back)="backpage()"></app-common-form>
</p-dialog>


<p-sidebar [(visible)]="displayMenuRole" position="right" (onHide)="onHideMenuRole($event)" [baseZIndex]="10000">
  <h5 style="font-weight:normal"><i class="pi pi-cog"></i>Danh sách chức năng</h5>
  <ul class="checktree mt-2" style="margin-left: -36px">
        <li *ngFor="let menu of menus; let i=index" title="Menu Cha" >
          <!-- <input id="administration" type="checkbox" [checked]="menu?.isCheckAll" name="{{menu?.title}}_{{i}}_{{menu?.isCheckAll}}" (change)="changeMenuParentAll(menu, i)"/> -->
          <input id="administration_{{menu.menuId}}_{{i}}" type="checkbox" [checked]="menu?.isCheck" name="{{menu?.title}}_{{i}}" (change)="changeMenuParent(menu, i)" style="margin-right: 5px"/><input type="number" [ngModel]="menu.intPos" name="intPos_{{menu.intPos}}_{{menu.menuId}}" (change)="changeModelintPosParent($event, i)" style="width: 10%; height: 11px;width: 11%;height: 17px;"> <label  for="administration_{{menu.menuId}}_{{i}}" style="font-weight: 600; margin-left: 5px; font-size: 13px;"> {{menu?.title}}</label>
          <ul style="margin-left:5px">
            <li *ngFor="let menuChirlden of  menu.submenus; let idx=index" title="Menu con">
              <input id="president_{{menuChirlden.menuId}}_{{idx}}" type="checkbox" style="margin-right: 5px" name="{{menuChirlden?.title}}_{{idx}}" (change)="changeMenuChirlden(i, idx)" [checked]="menuChirlden?.isCheck" /><input type="number" [ngModel]="menuChirlden.intPos" name="intPos_{{menuChirlden.intPos}}_{{menu.menuId}}" (change)="changeModelintPosChilrden($event, i, idx)" style="width: 10%; height: 11px;width: 11%;height: 17px;"> <label for="president_{{menuChirlden.menuId}}_{{idx}}" style="font-weight: 600; margin-left: 5px;font-size: 13px;"> {{menuChirlden?.title}}</label>
              <ul style="margin-left:5px">
                  <li *ngFor="let action of  menuChirlden.actions; let actionIdx=index" title="Action con">
                    <input id="president_{{action.id}}_{{actionIdx}}" type="checkbox" style="margin-right: 5px" name="{{action?.actionName}}_{{actionIdx}}" (change)="changeActionChirlden(i, idx , actionIdx)" [checked]="action?.isCheck" /><label for="president_{{menuChirlden.menuId}}_{{idx}}"> {{action?.actionName}} <span _ngcontent-bpg-c6="" [ngClass]="{'bg-dropbox': action?.isCheck}" class="noti-number text-white pointer ng-star-inserted">Action</span></label>
                  </li>
                </ul>
            </li>
          </ul>
          <ul style="margin-left:35px" *ngIf="menu.actions.length > 0">
            <li *ngFor="let action of menu.actions; let actionIdx=index" title="Action cha">
              <input id="president_{{action.id}}_{{actionIdx}}" type="checkbox" style="margin-right: 5px" name="{{action?.actionName}}_{{actionIdx}}" (change)="changeActionAdministration(i, actionIdx)" [checked]="action?.isCheck" /><label for="president_{{menu.menuId}}_{{idx}}"> {{action?.actionName}} <span _ngcontent-bpg-c6="" [ngClass]="{'bg-dropbox': action?.isCheck}" class="noti-number  text-white pointer ng-star-inserted">Action</span></label>
            </li>
          </ul>
        </li>
        
      </ul>

</p-sidebar>


<p-dialog [(visible)]="displayAddAction" [baseZIndex]="99" modal="true" [style]="{width: '1000px', height: 'auto'}">
  <p-header >
  {{modelAction?.actionId ? 'Sửa chức năng action': 'Thêm mới chức năng action'}}
</p-header>
<div class="row">
  <div class="col-md-12" style="margin-top: 5px;">
      <label for="">Mã action<span style="color:red">*</span></label>
      <input type="text" [(ngModel)]="modelAction.actionCd" class="form-control">
  </div>
  <div class="col-md-12" style="margin-top: 5px;">
      <label for="">Tên action<span style="color:red">*</span></label>
      <input type="text" [(ngModel)]="modelAction.actionName" class="form-control">
  </div>
</div>
<div class="row mt-1">
  <div class="col-md-12 text-right">
      <button pButton type="button" class="p-button-info mr-1 p-button-sm" [disabled]="modelAction.actionCd === '' || modelAction.actionName === ''" label="Lưu lại" icon="pi pi-check" (click)="saveAction()" ></button>
      <button pButton  type="button" class="p-button-secondary p-button-sm" icon="pi pi-times" label="Đóng" (click)="displayAddAction =!displayAddAction"></button>
  </div>
</div>

</p-dialog>

<p-dialog [(visible)]="displayAddTab" [baseZIndex]="99" modal="true" [style]="{width: '1000px', height: 'auto'}">
  <p-header >
  {{modelAction?.id ? 'Sửa chức năng Tab': 'Thêm mới chức năng Tab'}}
</p-header>
<div class="row">
  <div class="col-md-12" style="margin-top: 5px;">
      <label for="">Mã tab<span style="color:red">*</span></label>
      <input type="text" [(ngModel)]="modelTab.tabCd" name="tabCd" class="form-control">
  </div>
  <div class="col-md-12" style="margin-top: 5px;">
      <label for="">Tên tab<span style="color:red">*</span></label>
      <input type="text" [(ngModel)]="modelTab.tabName" name="tabName" class="form-control">
  </div>
</div>
<div class="row mt-1">
  <div class="col-md-12 text-right">
      <button pButton type="button" class="p-button-info mr-1 p-button-sm" [disabled]="modelTab.tabCd === '' || modelTab.tabName === ''" label="Lưu lại" icon="pi pi-check" (click)="saveTab()" ></button>
      <button pButton  type="button" class="p-button-secondary p-button-sm" icon="pi pi-times" label="Đóng" (click)="displayAddTab =!displayAddTab"></button>
  </div>
</div>

</p-dialog>
