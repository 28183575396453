<div class="content-header row" style="margin: -10px;">
  <div class="content-header-left col-md-6 col-12">
    <div class="breadcrumb-wrapper col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/home"> 
          <a ><i class="fa fa-home"></i> Trang chủ</a>
        </li>
        <li class="breadcrumb-item active">Phân loại khách hàng
        </li>
      </ol>
    </div>
  </div>
</div>
<div class="content-body">
  <section id="configuration">
    <div class="row">
      <div class="col-12" style="padding: 0px;">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4 ">
                    <div class="form-group">
                      <label for="">Tìm kiếm: </label>
                      <input type="text" class="input-customize" (keydown.enter)="load()" id="filter"
                        class="form-control" [(ngModel)]="params.filter" placeholder="Tìm kiếm...">
                      <!-- <i class="ft-x" (click)="cancel()" style="cursor: pointer;"></i> -->
                    </div>
                  </div>
                  <div class="col-md-3 w-100">
                    <div class="form-group">
                      <label for="">Chọn kiểu</label>
                      <select class="form-control" id="base_type" [(ngModel)]="params.base_type" name="base_type">
                          <option value="" disabled>Chọn kiểu</option>
                          <option *ngFor="let item of baseTypeList; let i = index" value="{{item.base_type}}">
                            {{item.base_desc}} </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 ">
                    <div class="form-group">
                      <label for="" style="visibility: hidden;">Tìm kiếm: </label><br>
                      <p-button label="" icon="pi pi-search" styleClass="mr-1" iconPos="left" (click)="load()"></p-button>
                      <p-button label="" icon="pi pi-refresh" styleClass="ui-button-secondary" iconPos="left" (click)="cancelSearch()"></p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-right">
                <p-button label="Thêm mới" (click)="addCategory()" icon="fa fa-plus" iconPos="left"></p-button>
              </div>

            </div>
            <a class="heading-elements-toggle">
              <i class="fa fa-ellipsis-v font-medium-3"></i>
            </a>
          </div>
        </div>

        <div class="card">
          <div class="card-content collapse show">
            <div class=" card-dashboard" style="margin-top: 10px">
              <div id="DataTables_Table_0_wrapper1" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div class="">
                  <ag-grid-angular 
 [modules]="modules"[suppressCsvExport]="true" [suppressExcelExport]="true" #agGrid style="width: 100%; height: 650px" id="myGrid" class="ag-theme-balham"
                    [rowData]="items" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
                    [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
                    [detailRowHeight]="detailRowHeight" (gridReady)="onGridReady($event)"
                    [defaultColDef]="defaultColDef" floatingFilter [groupUseEntireRow]="true" enableCellTextSelection=true
                    [enableRangeSelection]="true" [animateRows]="true" [getRowHeight]="getRowHeight"
                    [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-md-4">
                    <form>
                      Hiển thị:
                      <select name="pageSize" style="height: calc(2rem + 2px) !important;"
                        [(ngModel)]="pagingComponent.pageSize" #pagingComponent.pageSize="ngModel"
                        (change)="changePageSize()" class="">
                        <option [value]="10">10</option>
                        <option [value]="25">25</option>
                        <option [value]="50">50</option>
                        <option [value]="100">100</option>
                        <option [value]="1000000000">All</option>
                      </select>
                      <span>Từ {{ countRecord.currentRecordStart }} đến {{ countRecord.currentRecordEnd
                        }} trên tổng số
                        {{ countRecord.totalRecord }} kết quả</span>
                    </form>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4 d-flex justify-content-end">
                    <app-base-paging (pageChange)="load()"></app-base-paging>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--card-->
        <p-dialog [(visible)]="visible" baseZIndex="1" modal="true" [style]="{width: '700px', height: 'auto'}">
          <p-header>
            {{isEdit ? 'Sửa phân loại khách hàng' :'Thêm phân loại khách hàng'}}
          </p-header>
          <form action="" #categoryForm="ngForm">
            <div class="col-md-12">
              <label for="">Mã phân loại</label>
              <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelCategory.categoryCd" name="categoryCd"
                #categoryCd="ngModel" required>
            </div>
            <div class="col-md-12">
              <label>Tên phân loại<span class="text-danger">(*)</span></label>
              <input type="text" class="form-control mr-sm-2" [(ngModel)]="modelCategory.categoryName"
                name="categoryName" #categoryName="ngModel" required>
            </div>
            <div class="col-md-12">
              <label>Chọn kiểu <span class="text-danger">(*)</span></label>
              <select class="form-control" name="base_type" [(ngModel)]="modelCategory.base_type"
                id="base_type"  #base_type="ngModel">
                <option disabled value=null>Chọn all kiểu</option>
                <option *ngFor="let item of baseTypeList; let i = index" value="{{item.base_type}}">
                  {{item.base_desc}} </option>
              </select>
            </div>
            <!-- <div class="col-md-12">
              <label>Trang web <span class="text-danger">(*)</span></label>
              <select class="form-control" id="webId" [(ngModel)]="modelCategory.webId" name="webId">
                <option value="" disabled>Chọn loại Website</option>
                <option value="{{item.webId}}" *ngFor="let item of listWebs; let i= index">{{item.webName}}</option>
            </select>
            </div> -->
            <!-- <div class="col-md-12">
              <div class="form-group">
                <label for="">Tất cả</label>
                <input type="checkbox" class="ml-1 mt-1 cus-checkbox" [(ngModel)]="modelCategory.isAll" name="isAll"
                  #isAll="ngModel">
              </div>
            </div> -->
            <p-footer>
              <div class="col-md-12 d-flex justify-content-end mt-1">
                <p-button label="Lưu lại" styleClass="mr-1" [disabled]='categoryForm.form.invalid'
                  (click)="categoryForm.form.invalid ?null: saveCategory()"></p-button>
                <p-button label="Đóng" styleClass="ui-button-secondary" (click)="visible = !visible">
                </p-button>
              </div>
            </p-footer>
          </form>
        </p-dialog>
      </div>
    </div>
  </section>
</div>
